import React from 'react';
import PropTypes from 'prop-types';
import { Stack } from 'react-bootstrap';
import { useForm } from 'react-hook-form';

import SVButton from 'components/elements/SVButton';

import DatePicker from './FormInput/date/ControlledDatePicker';

const DatePickerForm = ({ defaultValue = null, onSave, label = '' }) => {
  const [isEditting, setEditting] = React.useState(false);
  const {
    control,
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: { date: defaultValue },
  });

  const onSubmit = async (data) => {
    await onSave(data);
    setEditting(false);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div>{label}</div>
      <Stack direction="horizontal" gap={2}>
        <DatePicker
          name="date"
          control={control}
          disabled={!isEditting || isSubmitting}
        />
        {isEditting ? (
          <Stack direction="horizontal" gap={1}>
            <SVButton
              variant="outline-secondary"
              size="sm"
              disabled={isSubmitting}
              onClick={() => {
                setEditting(false);
                reset({ date: defaultValue });
              }}
            >
              Cancel
            </SVButton>
            <SVButton
              type="submit"
              variant="secondary"
              size="sm"
              disabled={isSubmitting}
            >
              Save
            </SVButton>
          </Stack>
        ) : (
          <SVButton
            onClick={() => {
              setEditting(true);
            }}
            variant="secondary"
            size="sm"
          >
            Edit
          </SVButton>
        )}
      </Stack>
    </form>
  );
};

DatePickerForm.propTypes = {
  defaultValue: PropTypes.string,
  onSave: PropTypes.func.isRequired,
  label: PropTypes.string,
};

export default DatePickerForm;
