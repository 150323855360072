import React from 'react';
import { useForm } from 'react-hook-form';
import axios from 'axios';

import {
  Button,
  Col,
  Container,
  Form,
  ProgressBar,
  Row,
  Stack,
} from 'react-bootstrap';

import api from 'api';
import useUser from 'hooks/useUser';
import useGoBack from 'hooks/useGoBack';
import useFeedback from 'hooks/useFeedback';
import { DataUploadLabels } from 'utils/enums';

import SectionWrapper from 'components/layout/SectionWrapper';

const REQUEST_INIT_PERCENT = 10;
const ALLOWED_EXT = ['.xml', '.xfr', '.csv'];

const TypeOptions = () => ['CallForData2024', 'CallForDataOther'].map((key) => (
  <option key={key} value={key}>
    {DataUploadLabels[key]}
  </option>
));

const UploadAddPage = () => {
  const { userBurnCenter } = useUser();
  const { goBack } = useGoBack();
  const [uploadPercent, setUploadPercent] = React.useState(0);

  const { handleSubmit, register, formState } = useForm();
  const { setSuccess, setError } = useFeedback();

  const onSubmit = React.useCallback(
    async ({ file, ...formInput }) => {
      const submitFile = file[0];
      if (!submitFile) return;

      if (!ALLOWED_EXT.includes(submitFile.name.slice(-4).toLowerCase())) {
        setError(`Please only upload valid files (${ALLOWED_EXT.join(', ')})`);
        return;
      }

      const contentType = submitFile.type || 'application/octet-stream';

      try {
        const {
          data: { uploadUrl },
        } = await api.postUpload({
          ...formInput,
          filename: submitFile.name,
          contentType,
        });

        // Sets a percentage for initial request
        setUploadPercent(REQUEST_INIT_PERCENT);

        await axios.put(uploadUrl, submitFile, {
          headers: { 'Content-Type': contentType },
          onUploadProgress: (progressEvent) => {
            setUploadPercent(
              REQUEST_INIT_PERCENT
                + Math.round((progressEvent.loaded * 100) / progressEvent.total),
            );
          },
        });
        setSuccess('File uploaded successfully');
        goBack();
      } catch (error) {
        setUploadPercent(0);
        setError('Unexpected error uploading file');
      }
    },
    [goBack, setSuccess, setError],
  );

  const totalUpload = React.useMemo(
    () => (uploadPercent / (100 + REQUEST_INIT_PERCENT)) * 100,
    [uploadPercent],
  );

  return (
    <SectionWrapper title="Upload File">
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Container className="p-5 bg-light position-relative" fluid>
          <Row className="mb-4">
            <Col xs={12} lg={2}>
              <Form.Label>
                <b>Burn Center:</b>
              </Form.Label>
            </Col>
            <Col xs={12} lg={10}>
              <span>{userBurnCenter?.name}</span>
            </Col>
          </Row>
          <Row className="mb-4">
            <Col xs={12} lg={2}>
              <Form.Label htmlFor="type">
                <b>File type:</b>
              </Form.Label>
            </Col>
            <Col xs={12} lg={10}>
              <Form.Select {...register('type')} id="type">
                <TypeOptions />
              </Form.Select>
            </Col>
          </Row>
          <Row className="mb-4">
            <Col xs={12} lg={2}>
              <Form.Label htmlFor="description">
                <b>Description:</b>
              </Form.Label>
            </Col>
            <Col xs={12} lg={10}>
              <Form.Control
                {...register('description', { required: true })}
                id="description"
                required
              />
            </Col>
          </Row>
          <Row className="mb-4">
            <Col xs={12} lg={2}>
              <Form.Label htmlFor="file">
                <b>File:</b>
              </Form.Label>
            </Col>
            <Col xs={12} lg={10}>
              <Form.Control
                {...register('file', { required: true })}
                id="file"
                type="file"
                required
                accept={ALLOWED_EXT.join(',')}
              />
            </Col>
          </Row>
          <Stack direction="horizontal" gap={2}>
            <Button
              variant="secondary"
              type="submit"
              disabled={formState.isSubmitting}
            >
              Submit
            </Button>
            <Button
              type="reset"
              variant="outline-secondary"
              onClick={() => goBack()}
            >
              Cancel
            </Button>
          </Stack>
          {formState.isSubmitting && (
            <div
              style={{
                backgroundColor: 'rgba(100, 100, 100, 0.3)',
              }}
              className="position-absolute top-0 start-0 m-0 w-100 h-100 px-5 pt-3"
            >
              <ProgressBar
                animated
                variant="secondary"
                now={totalUpload}
                label={`${Math.ceil(totalUpload)}%`}
              />
            </div>
          )}
        </Container>
      </Form>
    </SectionWrapper>
  );
};

export default UploadAddPage;
