import React from 'react';
import PropTypes from 'prop-types';

import api from 'api';
import useRequest from 'hooks/useRequest';
import useMountEffect from 'hooks/useMountEffect';
import { MAX_PAGE_SIZE } from 'utils/constants';

import Search from 'components/elements/Search';

import FilterEnum from './data';

const SearchFilter = ({ handleFilterChange }) => {
  const { doRequest: getBurncenters, data: burncenterData } = useRequest(
    api.getBurncenters,
  );

  const burnCenterOptions = React.useMemo(
    () => burncenterData?.items?.map(({ id, name, hospitalName }) => ({
      value: id,
      label: [name, hospitalName].join(' - '),
    })) ?? [],
    [burncenterData],
  );

  useMountEffect(() => {
    getBurncenters({ limit: MAX_PAGE_SIZE });
  });

  return (
    <Search
      onSearch={(value) => handleFilterChange(FilterEnum.burncenter, value)}
      options={burnCenterOptions}
    />
  );
};

SearchFilter.propTypes = {
  handleFilterChange: PropTypes.func.isRequired,
};

export default SearchFilter;
