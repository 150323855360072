import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import * as SurveyReact from 'survey-react';

import { debounce, findIndex } from 'lodash';

import api from 'api';
import useUser from 'hooks/useUser';
import useCurrentApplication from 'hooks/useCurrentApplication';
import useChat from 'hooks/useChat';
import useFeedback from 'hooks/useFeedback';
import { surveyJSTheme } from 'helpers/surveyJs';
import { formatDate } from 'utils/dateTime';

import Loader from 'components/elements/Loader';
import Modal from 'components/elements/BasicModal';
import SurveyProgress from 'components/forms/helpers/SurveyProgress';
import SurveyComments from 'components/forms/helpers/SurveyComments';
import SurveyPageOverrider from 'components/forms/helpers/overrideSurveyJS/SurveyPageOverrider';
import SurveyQuestionOverrider from 'components/forms/helpers/overrideSurveyJS/SurveyQuestionOverrider';
import surveyFileQuestionOverride from 'components/forms/helpers/overrideSurveyJS/surveyFileQuestionOverride';
import SurveyNavigation from 'components/forms/helpers/SurveyNavigation';
import FooterNavigation from 'components/forms/helpers/SurveyNavigation/FooterNavigation';
import SiteReviewerComments from 'components/forms/helpers/SiteReviewerComments';

import { SurveyPanel, SurveyContainer, SubmitInfo } from './index.styles';

SurveyReact.settings.panelMaximumPanelCount = 4500;

SurveyReact.StylesManager.applyTheme(surveyJSTheme);

const PartialResultForm = ({
  canEdit,
  onSubmit,
  surveyVersionId,
  formType,
  uploadFileFunc,
  showReviwerComments,
  readOnlyReviwerComments,
  submittedAt,
}) => {
  const feedback = useFeedback();
  const { appData, userTypeId } = useCurrentApplication();
  const { isAdmin } = useUser();
  const [model, setModel] = useState(null);
  const [changed, setChanged] = useState(false);
  const [overrideModal, setOverrideModal] = useState(null);
  const [, forceUpdate] = React.useReducer((x) => x + 1, 0);

  const { closeQuestionComments } = useChat();

  const isEditable = React.useMemo(
    () => isAdmin || canEdit,
    [isAdmin, canEdit],
  );

  useEffect(() => {
    api.getSurveyVersion(surveyVersionId).then(({ data }) => {
      api
        .getFormResults({
          appId: appData.id,
          formType,
        })
        .then(({ data: { data: formData } }) => {
          const newModel = new SurveyReact.Model(data.model);
          newModel.data = formData;
          newModel.mode = isEditable ? 'edit' : 'display';
          setModel(newModel);
        });
    });
  }, [appData, isEditable, surveyVersionId, formType]);

  const handleSave = React.useCallback(
    ({ overwrite, cb }) => {
      const { currentPage, pages, data } = model;
      const pageIndex = findIndex(pages, { id: currentPage.id });
      const savedAtId = `savedAt${pageIndex}`;
      const saveAtValue = data[savedAtId];

      api
        .saveFormResults({
          appId: appData.id,
          formType,
          pageIndex,
          data: {
            ...currentPage.getValue(),
            [savedAtId]: saveAtValue,
          },
          overwrite: overwrite || !saveAtValue,
        })
        .then(({ data: newSavedAt }) => {
          const { data: currentData } = model;
          model.data = { ...currentData, [savedAtId]: newSavedAt };
          feedback.setSuccess('Saved successfully');
          forceUpdate();
          if (cb) cb();
        })
        .catch(({ response }) => {
          if (
            response?.data?.detail
            === 'Results are changed since last loading, please refresh page'
          ) {
            setOverrideModal({ cb });
            return;
          }
          feedback.setError('Error saving form');
        });
    },
    [appData, feedback, model, formType],
  );

  const getPartialPageResults = React.useCallback(
    (page) => {
      const pageIndex = findIndex(model.pages, { id: page.id });
      api
        .getFormResults({
          appId: appData.id,
          formType,
          pageIndex,
        })
        .then(({ data: { data } }) => {
          const { data: currentData } = model;
          model.data = { ...currentData, ...data };
          forceUpdate();
        });
    },
    [appData, model, formType],
  );

  const onSave = debounce(handleSave, 300);

  const onPageChanged = React.useCallback(
    (newPage) => {
      if (!isEditable || !changed) {
        model.currentPage = newPage;
        forceUpdate();
        return;
      }
      onSave.cancel();
      handleSave({
        cb: () => {
          model.currentPage = newPage;
          setChanged(false);
          forceUpdate();
        },
      });
    },
    [model, isEditable, changed, onSave, handleSave],
  );

  const handleFileUpload = (opt) => {
    opt.files.forEach((file) => {
      uploadFileFunc({
        appId: appData.id,
        file,
      }).then(({ data }) => opt.callback('success', [
        {
          file,
          content: `${data.id}`,
        },
      ]));
    });
  };

  if (!model) return <Loader />;

  return (
    <div>
      <Modal
        title="A change to has been made to this page/question"
        body="Would you like to overwrite? This will discard their changes."
        isOpen={!!overrideModal}
        onClose={() => {
          getPartialPageResults(model.currentPage);
          setOverrideModal(null);
        }}
        onConfirm={() => {
          handleSave({
            overwrite: true,
            cb: () => {
              if (overrideModal?.cb) overrideModal.cb();
              setOverrideModal(null);
            },
          });
        }}
      />
      <SurveyProgress pages={model.pages} />
      <SurveyPanel>
        <SurveyNavigation
          model={model}
          onPageChanged={onPageChanged}
          onSubmit={onSubmit}
          surveyVersionId={surveyVersionId}
          formType={formType}
        />
        <SurveyContainer _hasbuttons={!isAdmin}>
          {showReviwerComments && (
            <SiteReviewerComments
              applicationId={appData.id}
              surveyVersionId={surveyVersionId}
              criteriaId={model.currentPage.name}
              readOnly={isAdmin || readOnlyReviwerComments}
            />
          )}
          <SubmitInfo>{`Submitted on: ${formatDate(submittedAt)}`}</SubmitInfo>
          <SurveyReact.Survey
            model={model}
            onValueChanged={onSave}
            onValueChanging={() => setChanged(true)}
            onCurrentPageChanged={({ currentPage }) => {
              closeQuestionComments();
              getPartialPageResults(currentPage);
            }}
            showNavigationButtons="none"
            onAfterRenderPage={(_, opt) => SurveyPageOverrider(opt)}
            onAfterRenderQuestion={(m, opt) => {
              surveyFileQuestionOverride(m, opt);
              return (
                !isAdmin
                && SurveyQuestionOverrider({
                  options: opt,
                  surveyVersionId,
                  userTypeId,
                })
              );
            }}
            onUploadFiles={(_, opt) => handleFileUpload(opt)}
          />
          <FooterNavigation model={model} onPageChanged={onPageChanged} />
        </SurveyContainer>
        <SurveyComments surveyVersionId={surveyVersionId} />
      </SurveyPanel>
    </div>
  );
};

PartialResultForm.propTypes = {
  canEdit: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  surveyVersionId: PropTypes.number.isRequired,
  formType: PropTypes.string.isRequired,
  uploadFileFunc: PropTypes.func,
  showReviwerComments: PropTypes.bool,
  readOnlyReviwerComments: PropTypes.bool,
  submittedAt: PropTypes.string,
};

export default PartialResultForm;
