import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';

import { formTitle } from 'helpers/forms';
import SVButton from 'components/elements/SVButton';

function ASSubmitConfirmation({ onSubmit, onCancel }) {
  const [satisfied, setSatisfied] = useState(false);

  return (
    <Modal show onHide={onCancel}>
      <Modal.Header closeButton>
        <Modal.Title>
          {`Are you sure you are ready to submit your ${formTitle.keyFindings}?`}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          <input
            type="checkbox"
            style={{ marginRight: '1rem' }}
            onChange={({ target }) => setSatisfied(target.checked)}
          />
          {'I have reviewed and completed each section and '}
          site reviewer findings are ready to include in the final site report.
        </p>
        <p>You will not be able to make changes after you submit.</p>
      </Modal.Body>
      <Modal.Footer>
        <SVButton variant="cancel" onClick={onCancel}>
          Cancel
        </SVButton>
        <SVButton
          size="sm"
          variant="secondary"
          onClick={onSubmit}
          disabled={!satisfied}
        >
          Submit
        </SVButton>
      </Modal.Footer>
    </Modal>
  );
}

export default ASSubmitConfirmation;

ASSubmitConfirmation.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};
