import React from 'react';
import PropTypes from 'prop-types';
import fileDownload from 'js-file-download';

import { FaCog } from 'react-icons/fa';

import api from 'api';
import useFeedback from 'hooks/useFeedback';

import SVButton from 'components/elements/SVButton';

const ExportListButton = ({ requestParams }) => {
  const { setError } = useFeedback();
  const [downloading, setDownloading] = React.useState(false);

  const onExport = React.useCallback(() => {
    setDownloading(true);
    api
      .getAdminOverviewDownload(requestParams)
      .then(({ data }) => {
        fileDownload(data, 'VerificationAdministrator.csv');
      })
      .catch(() => setError('Error exporting file'))
      .finally(() => setDownloading(false));
  }, [requestParams, setError]);

  return (
    <SVButton
      size="sm"
      variant="secondary"
      onClick={onExport}
      disabled={downloading}
    >
      {downloading ? <FaCog className="fa-spin" /> : 'Export'}
    </SVButton>
  );
};

ExportListButton.propTypes = {
  requestParams: PropTypes.shape({}),
};

export default ExportListButton;
