import React from 'react';
import PropTypes from 'prop-types';
import ReactDatePicker from 'react-datepicker';

import styled from '@emotion/styled';

import colors from 'resources/styles/colors';

import 'react-datepicker/dist/react-datepicker.css';

const StlyedPicker = styled(ReactDatePicker)`
  width: ${(props) => (props.fullWidth ? '100%' : 'auto')};
  padding: 6px 10px;
  border-radius: 4px;
  border: 1px solid ${colors.gray};
`;

const DatePicker = ({
  value = null,
  onChange,
  fullWidth = false,
  ...props
}) => (
  <StlyedPicker
    {...props}
    selected={value}
    onChange={onChange}
    fullWidth={fullWidth}
  />
);

DatePicker.propTypes = {
  value: PropTypes.instanceOf(Date),
  onChange: PropTypes.func.isRequired,
  fullWidth: PropTypes.bool,
};

export default DatePicker;
