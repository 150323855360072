import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';

import useUser from 'hooks/useUser';
import { MainRoutes } from 'helpers/routes';
import { appRoles } from 'utils/enums';

import {
  AdminInfo,
  MenuItem,
  MenuItemTitle,
  activeStyle,
} from 'components/templates/menus/styles';

const AdminVerificationMenu = () => {
  const { roleName } = useUser();
  const { pathname } = useLocation();
  return (
    <AdminInfo className="shadow p-4">
      <MenuItem>
        <MenuItemTitle>
          <NavLink
            to={MainRoutes.VERIFICATION}
            style={({ isActive }) => (isActive && !pathname.includes('surveys')
              ? activeStyle
              : undefined)}
          >
            In Verification
          </NavLink>
        </MenuItemTitle>
      </MenuItem>
      {roleName !== appRoles.abaADMIN && (
        <MenuItem>
          <MenuItemTitle>
            <NavLink
              to={`${MainRoutes.VERIFICATION}/surveys`}
              style={({ isActive }) => (isActive ? activeStyle : undefined)}
            >
              Survey Management
            </NavLink>
          </MenuItemTitle>
        </MenuItem>
      )}
    </AdminInfo>
  );
};

export default AdminVerificationMenu;
