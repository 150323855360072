import React from 'react';

import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import api from 'api';
import useMountEffect from 'hooks/useMountEffect';
import { formatDate } from 'utils/dateTime';
import useRequest from 'hooks/useRequest';

import { v4 as uuid } from 'uuid';

import { CalendarContainer, DayContainer } from './SiteVisitCalendar.styles';

const renderItemContent = ({
  burnCenterName,
  hospitalName,
  programTypeName,
  programSubtypeName,
}) => (
  <div className="mb-2" key={uuid()}>
    <b>{`${hospitalName} (${burnCenterName})`}</b>
    <div>{`${programTypeName} - ${programSubtypeName}`}</div>
  </div>
);

const SiteVisitCalendar = () => {
  const { doRequest: getCalendar, data: calendarData } = useRequest(
    api.getCalendar,
  );

  useMountEffect(getCalendar);

  const calendarItems = React.useMemo(
    () => calendarData?.items?.reduce((res, { siteVisitAt, ...item }) => {
      const dateStr = formatDate(siteVisitAt);
      const currentDateItems = res[dateStr] ?? [];
      return { ...res, [dateStr]: [...currentDateItems, item] };
    }, {}) ?? {},
    [calendarData],
  );

  const renderDayContents = React.useCallback(
    (day, date) => {
      const dayVisits = calendarItems[formatDate(date)];
      if (!dayVisits) return day;
      return (
        <OverlayTrigger
          rootClose
          trigger="click"
          placement="right"
          overlay={(
            <Popover>
              <Popover.Header as="h3">Site Vist</Popover.Header>
              <Popover.Body>{dayVisits?.map(renderItemContent)}</Popover.Body>
            </Popover>
          )}
        >
          <DayContainer>{day}</DayContainer>
        </OverlayTrigger>
      );
    },
    [calendarItems],
  );

  return (
    <CalendarContainer>
      <h4>Site Visit Calendar</h4>
      <ReactDatePicker inline renderDayContents={renderDayContents} />
    </CalendarContainer>
  );
};

export default SiteVisitCalendar;
