import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { find } from 'lodash';

import { Stack, ProgressBar } from 'react-bootstrap';
import {
  FaComment, FaRegComment, FaFlag, FaRegFlag,
} from 'react-icons/fa';
import styled from '@emotion/styled';

import { FlexDivSpaceBetweenCenter } from 'resources/styles/display';
import colors from 'resources/styles/colors';

import useUser from 'hooks/useUser';
import useChat from 'hooks/useChat';

const CriteriaProgress = styled.div`
  width: 60px;
  margin-left: 10px;
  .progress {
    border: 1px solid ${colors.green};
  }
  .progress-bar {
    background-color: ${colors.green};
  }
`;

const CriteriaSection = styled(FlexDivSpaceBetweenCenter)`
  padding: 1rem;
  border-top: 1px solid ${colors.gray};
  cursor: pointer;
  background-color: ${({ isCurrent }) => (isCurrent ? colors.lightGray : 'white')};
  color: ${({ isCurrent }) => (isCurrent ? colors.green : 'black')};
  &:first-of-type {
    border-top: none;
  }
`;

function CriteriaTab({ page, onTabClick, isCurrent }) {
  const { isAdmin } = useUser();
  const showFlags = !isAdmin;
  const { data: flagData } = useSelector((state) => state.applications.flags);
  const { commentCount } = useChat();

  const {
    total, answered, flagTotal, commentTotal,
  } = page.questions.reduce(
    (res, question) => {
      if (!question.visible) return res;
      res.total += 1;

      const questionType = question.getType();

      if (!question.isEmpty() || questionType === 'html') res.answered += 1;
      if (showFlags) {
        if (!res.commentTotal) {
          const comments = find(commentCount, { questionId: question.name });
          if (comments) res.commentTotal += comments.count;
        }
        if (!res.flagTotal) {
          const flag = find(flagData, { questionId: question.name });
          if (flag) res.flagTotal += 1;
        }
      }
      return res;
    },
    {
      total: 0,
      answered: 0,
      flagTotal: 0,
      commentTotal: 0,
    },
  );

  return (
    <CriteriaSection
      isCurrent={isCurrent}
      onClick={() => !isCurrent && onTabClick(page)}
    >
      <span>{page.title}</span>
      <FlexDivSpaceBetweenCenter>
        {showFlags && (
          <Stack direction="horizontal" gap={2} style={{ fontSize: '1.3rem' }}>
            {commentTotal ? <FaComment /> : <FaRegComment />}
            {flagTotal ? <FaFlag /> : <FaRegFlag />}
          </Stack>
        )}
        <CriteriaProgress>
          <ProgressBar now={answered} min={0} max={total} />
        </CriteriaProgress>
      </FlexDivSpaceBetweenCenter>
    </CriteriaSection>
  );
}

CriteriaTab.propTypes = {
  page: PropTypes.shape({
    name: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    questions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  }).isRequired,
  onTabClick: PropTypes.func.isRequired,
  isCurrent: PropTypes.bool.isRequired,
};

export default CriteriaTab;
