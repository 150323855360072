import api from 'api';

export const GET_SURVEYS = 'GET_SURVEYS';

export const fetchSurveys = (requestData) => (dispatch) => {
  dispatch({ type: GET_SURVEYS });
  api
    .getSurveys(requestData)
    .then(({ data }) => dispatch({ type: `${GET_SURVEYS}_SUCCESS`, data }))
    .catch((error) => dispatch({ type: `${GET_SURVEYS}_ERROR`, error }));
};
