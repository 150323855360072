/* eslint-disable consistent-return, no-param-reassign */
import { QuestionFactory } from 'survey-react';
import { CustomBrick, EmptyBrick, SurveyHelper } from 'survey-pdf';
import find from 'lodash/find';
import findIndex from 'lodash/findIndex';
import get from 'lodash/get';
import 'jspdf-autotable';

import { regularPdfFont, boldPdfFont } from 'helpers/surveyJs';

export const surveyPdfOptions = {
  commercial: true,
  matrixRenderAs: 'list',
  htmlRenderAs: 'standard',
  fontSize: 12,
  fontName: 'Segoe',
  base64Normal: regularPdfFont,
  base64Bold: boldPdfFont,
  useCustomFontInHtml: true,
  textFieldRenderAs: 'multiLine',
  margins: {
    left: 10,
    right: 10,
    top: 18,
    bot: 12,
  },
};

export const mapQuestions = (elements) => elements.reduce((res, el) => {
  if (el.type === 'panel') {
    return [...res, ...mapQuestions(el.elements)];
  }

  if (el.type === 'paneldynamic') {
    return [...res, ...mapQuestions(el.templateElements)];
  }

  return [...res, el];
}, []);

export const onRenderHeader = (bcqpBurnCenterName) => (_, canvas) => {
  const date = new Date();
  canvas.drawText({
    text: `${bcqpBurnCenterName} - ${date.toDateString()} ${date.toLocaleTimeString()}`,
    fontSize: 9,
  });
};

export const onRenderFooter = (survey, canvas) => {
  canvas.drawText({
    text: `Page ${canvas.pageNumber} / ${canvas.countPages}`,
    fontSize: 12,
    horizontalAlign: 'center',
  });
};

const renderTable = (options, everyCellSize, headers, body) => (doc, question, xLeft, yTop) => {
  const tableWidth = options.controller.paperWidth
      - options.controller.margins.left
      - options.controller.margins.right;

  const whereEnd = [];

  const columnStyles = headers.map((header, index) => ({
    cellWidth: tableWidth / headers.length,
    fillColor: index === 0 && !header ? '#CCC' : 'white',
  }));

  doc.autoTable({
    head: [headers],
    body,
    rowPageBreak: 'avoid',
    showHead: 'firstPage',
    startY: yTop,
    headStyles: {
      textColor: 'black',
      fillColor: '#CCC',
    },
    columnStyles,
    margin: {
      left: options.controller.margins.left,
      right: options.controller.margins.right,
      top: options.controller.margins.top,
      bottom: options.controller.margins.bot,
    },
    styles: {
      lineWidth: 1,
      lineColor: 'black',
    },
    tableWidth,
    didDrawCell: (data) => {
      everyCellSize.push({ isPageEnd: false, y: data.cursor.y });
    },
    didDrawPage: (data) => {
      whereEnd.push(data.cursor.y);
      everyCellSize.push({ isPageEnd: true, y: data.cursor.y });
    },
  });

  const tableHeight = whereEnd.reduce(
    (res, wE, index) => (index ? res + wE - options.controller.margins.top : wE),
    0,
  );

  const borderSizes = {
    xLeft: options.controller.margins.left,
    xRight: tableWidth + options.controller.margins.left,
    yTop: options.point.yTop,
    yBot: options.point.yTop + tableHeight,
  };

  return borderSizes;
};

export const renderTableBrick = (
  options,
  everyCellSize,
  tableHeaders,
  tableBody,
) => {
  const start = options.point.yTop;
  const tableBrick = new CustomBrick(
    options.question,
    options.controller,
    renderTable(options, everyCellSize, tableHeaders, tableBody),
  );
  tableBrick.yBot = tableBrick.yTop;
  options.bricks.push(tableBrick);

  const everyRowEnd = [{ isFirstOnPage: true, y: 0 }];
  let sz = 1;
  let lastInPage = false;
  for (let i = tableHeaders.length; i < everyCellSize.length; i += 1) {
    if (everyCellSize[i].y !== everyRowEnd[sz - 1].y) {
      if (lastInPage) {
        everyRowEnd.push({ isFirstOnPage: true, y: everyCellSize[i].y });
        lastInPage = false;
      } else if (everyCellSize[i].isPageEnd) {
        everyRowEnd.push({ isFirstOnPage: false, y: everyCellSize[i].y });
        lastInPage = true;
      } else {
        everyRowEnd.push({ isFirstOnPage: false, y: everyCellSize[i].y });
      }
      sz += 1;
    }
  }

  let last = start;
  for (let i = 0; i < everyRowEnd.length; i += 1) {
    if (everyRowEnd[i].isFirstOnPage) {
      // eslint-disable-next-line no-continue
      continue;
    }
    const rowSize = everyRowEnd[i].y - everyRowEnd[i - 1].y;
    const rect = {
      xLeft: options.controller.margins.left,
      xRight: options.controller.paperWidth - options.controller.margins.right,
      yTop: last,
      yBot: last + rowSize,
    };
    last += rowSize;
    const emptyBrick = new EmptyBrick(rect);
    options.bricks.push(emptyBrick);
  }
};

export const newHtmlQuestion = () => QuestionFactory.Instance.createQuestion('html', 'html_question');

const fixHTML = (html) => `<div style="font-family: Segoe;">${html.replace('’', "'")}</div>`;

export const onRenderCommentQuestion = ({ value, survey, options }) => {
  const htmlQuestion = newHtmlQuestion();
  const lines = value.split('\n');
  htmlQuestion.html = lines.reduce(
    (res, line) => `${res}<p style="font-family: Segoe;" >${line}</p>`,
    '',
  );

  const flatHtml = options.repository.create(
    survey,
    htmlQuestion,
    options.controller,
    'html',
  );

  const commentBricks = options.bricks[0].unfold();
  const commentBrick = commentBricks.pop();
  const point = SurveyHelper.createPoint(commentBrick, true, true);

  return new Promise((resolve) => {
    flatHtml.generateFlats(point).then((htmlBricks) => {
      options.bricks = commentBricks;
      options.bricks = options.bricks.concat(htmlBricks);
      resolve();
    });
  });
};

export const onRenderFile = ({ value, survey, options }) => {
  const htmlQuestion = newHtmlQuestion();
  if (value) {
    htmlQuestion.html = '';
    value.forEach((v) => {
      htmlQuestion.html += `<p style="font-family: Segoe;">${v.name}</p><br>`;
    });
  } else htmlQuestion.html = 'No files uploaded';

  const flatHtml = options.repository.create(
    survey,
    htmlQuestion,
    options.controller,
    'html',
  );
  const commentBricks = options.bricks[0].unfold();

  const commentBrick = commentBricks.length > 2 ? commentBricks[2] : commentBricks[1];

  const point = SurveyHelper.createPoint(commentBrick, true, true);
  return new Promise((resolve) => {
    flatHtml.generateFlats(point).then((htmlBricks) => {
      options.bricks = [commentBricks[0]];
      options.bricks = options.bricks.concat(htmlBricks);
      resolve();
    });
  });
};

export const renderMatrixTable = ({
  name, value, options, modelQuestions,
}) => {
  const questionItem = find(modelQuestions, { name });
  const { columns } = questionItem;

  const optionBricks = options.bricks[0].bricks;
  const rowLineIndex = findIndex(optionBricks, (brick) => !brick.bricks);
  const titleBricks = optionBricks.slice(0, rowLineIndex);

  options.bricks = [...titleBricks];

  const lastTitleBrick = titleBricks[titleBricks.length - 1];

  options.point.yTop = lastTitleBrick.yBot + 10;

  const tableHeaders = columns.map((c) => c.name);
  const tableBody = value?.map((rowData) => tableHeaders.map((th) => rowData[th] || '-'));
  const everyCellSize = [];

  renderTableBrick(options, everyCellSize, tableHeaders, tableBody);
};

export const renderDropTable = ({
  name, value, options, modelQuestions,
}) => {
  const questionItem = find(modelQuestions, { name });
  if (!questionItem) return;
  const { columns, rows } = questionItem;

  const optionBricks = options.bricks[0].bricks;
  const rowLineIndex = findIndex(optionBricks, (brick) => !brick.bricks);
  // initial title brick if there is one
  options.bricks = rowLineIndex < 3 ? optionBricks.slice(0, rowLineIndex) : [];

  if (options.bricks.length) {
    const lastTitleBrick = options.bricks[options.bricks.length - 1];
    options.point.yTop = lastTitleBrick.yBot + 10;
  }

  const everyCellSize = [];
  const tableHeaders = columns.map((c) => c.name);
  const tableBody = rows.map((r) => [
    r,
    ...tableHeaders.map((c) => get(value, `${r}.${c}`) || ''),
  ]);

  renderTableBrick(options, everyCellSize, ['', ...tableHeaders], tableBody);
};

const parent = '21.4.';
export const onRenderQuestion = (modelQuestions, { overrider = {} }) => (survey, options) => {
  const {
    value, html, name, getType,
  } = options.question;
  const questionType = getType();

  const isLastCaseTable = options?.question?.parentQuestionValue?.jsonObj?.name === parent;
  const isAppendix = overrider?.appendixQuestions?.includes(name) && !isLastCaseTable;

  if (isAppendix) {
    const htmlQuestion = newHtmlQuestion();
    htmlQuestion.html = '<i>See Table in Appendix</i>';

    const flatHtml = options.repository.create(
      survey,
      htmlQuestion,
      options.controller,
      'html',
    );
    return new Promise((resolve) => {
      flatHtml.generateFlats(options.point).then((htmlBricks) => {
        options.bricks = htmlBricks;
        resolve();
      });
    });
  }

  if (questionType === 'html' && html && html.length) {
    const htmlQuestion = newHtmlQuestion();
    htmlQuestion.html = fixHTML(html);

    const flatHtml = options.repository.create(
      survey,
      htmlQuestion,
      options.controller,
      'html',
    );

    const commentBricks = options.bricks[0].unfold();
    const commentBrick = commentBricks.pop();
    const point = SurveyHelper.createPoint(commentBrick, true, true);

    return new Promise((resolve) => {
      flatHtml.generateFlats(point).then((htmlBricks) => {
        options.bricks = htmlBricks;
        resolve();
      });
    });
  }

  if (questionType === 'comment' && value && value.length) {
    return onRenderCommentQuestion({ value, survey, options });
  }
  if (questionType === 'file') {
    return onRenderFile({ value, survey, options });
  }

  if (
    questionType === 'matrixdynamic'
      && overrider?.matrixDynamic2Table?.includes(name)
  ) {
    return renderMatrixTable({
      name,
      value,
      options,
      modelQuestions,
    });
  }

  if (
    questionType === 'matrixdropdown'
      && overrider?.matrixDropdown2Table?.includes(name)
  ) {
    return renderDropTable({
      name,
      value,
      options,
      modelQuestions,
    });
  }
};
