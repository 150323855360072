import React from 'react';
import uniq from 'lodash/uniq';
import { v4 as uuid } from 'uuid';

import {
  getStateLabel,
  getProgramTypeLabel,
  programSubTypeLabel,
} from 'helpers/forms';
import { formatDate } from 'utils/dateTime';

// eslint-disable-next-line import/prefer-default-export
export const tableColumns = [
  {
    id: 'userRole',
    label: 'Role',
    override: ({ roleOnVerification }) => roleOnVerification,
  },
  {
    id: 'siteName',
    label: 'Site Name',
    override: ({ siteName }) => siteName.split(', ').map((s) => <div key={uuid()}>{s}</div>),
  },
  {
    id: 'state',
    label: 'Review Status',
    override: ({ stateId }) => getStateLabel(stateId),
  },
  {
    id: 'programType',
    label: 'Program type',
    override: ({ programTypeId, programSubtypeId }) => `${getProgramTypeLabel(programTypeId)}/${programSubTypeLabel(
      programSubtypeId,
    )}`,
  },
  {
    id: 'siteReviewers',
    label: 'Site Reviewers',
    nonSortable: true,
    override: ({ siteReviewers }) => siteReviewers
      && uniq(siteReviewers.split(', ')).map((sr) => <div key={uuid()}>{sr}</div>),
  },
  {
    id: 'siteVisitAt',
    label: 'Site Visit',
    override: ({ siteVisitAt }) => formatDate(siteVisitAt),
  },
];
