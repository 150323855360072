import React from 'react';
import { useParams } from 'react-router-dom';

import { Alert, Container, Stack } from 'react-bootstrap';

import api from 'api';
import useMountEffect from 'hooks/useMountEffect';
import useRequest from 'hooks/useRequest';
import { DataUploadLabels } from 'utils/enums';
import { formatUTCDate } from 'utils/dateTime';

import SectionWrapper from 'components/layout/SectionWrapper';
import { HistoryBackButton } from 'components/elements/BackButton';
import Loader from 'components/elements/Loader';

const UploadInfoPage = () => {
  const { uploadId } = useParams();
  const {
    data, isLoading, doRequest, error,
  } = useRequest(api.getUpload);

  useMountEffect(() => {
    doRequest(uploadId);
  });

  const renderFeedback = React.useMemo(() => {
    if (!data?.metadata) return null;
    const hasFeedBack = Object.keys(data.metadata).some((key) => key.startsWith('x-amz-meta-feedback'));
    if (!hasFeedBack) return null;
    return (
      <>
        <h5 className="mt-3 mb-0">Feedback</h5>
        <Stack direction="horizontal" gap={2}>
          <b>Total records received:</b>
          <span>{data.metadata['x-amz-meta-feedback-total-records']}</span>
        </Stack>
        {(data.metadata['x-amz-meta-feedback-admit-min']
          || data.metadata['x-amz-meta-feedback-admit-max']) && (
          <Stack direction="horizontal" gap={2}>
            <b>Admission date range:</b>
            <span>{`${data.metadata['x-amz-meta-feedback-admit-min']} - ${data.metadata['x-amz-meta-feedback-admit-max']}`}</span>
          </Stack>
        )}

        <i className="mt-3">
          There are currently four fields used for inclusion in benchmark
          reports (more are used for risk adjusted models).
        </i>
        <i className="mb-3">A summary of the four fields is listed below:</i>

        <Stack direction="horizontal" gap={2}>
          <b>Cases missing patient age:</b>
          <span>{data.metadata['x-amz-meta-feedback-missing-pt-age']}</span>
        </Stack>
        <Stack direction="horizontal" gap={2}>
          <b>Cases missing patient sex:</b>
          <span>{data.metadata['x-amz-meta-feedback-missing-pt-sex']}</span>
        </Stack>
        <Stack direction="horizontal" gap={2}>
          <b>Cases missing admission date:</b>
          <span>{data.metadata['x-amz-meta-feedback-missing-dt']}</span>
        </Stack>
        <Stack direction="horizontal" gap={2}>
          <b>Cases missing discharge disposition:</b>
          <span>{data.metadata['x-amz-meta-feedback-missing-dish']}</span>
        </Stack>
      </>
    );
  }, [data?.metadata]);

  const renderContent = React.useMemo(() => {
    if (error) {
      return (
        <Alert className="mt-3 d-flex justify-content-between" variant="danger">
          Error loading report
        </Alert>
      );
    }
    if (isLoading) return <Loader />;

    return (
      <Stack gap={2}>
        <Stack direction="horizontal" gap={2}>
          <b>File Name:</b>
          <span>{data?.filename}</span>
        </Stack>
        <Stack direction="horizontal" gap={2}>
          <b>Description:</b>
          <span>{data?.description}</span>
        </Stack>
        <Stack direction="horizontal" gap={2}>
          <b>Submitted Date:</b>
          <span>{formatUTCDate(data?.createdAt, 'YYYY-MM-DD LTS UTC')}</span>
        </Stack>
        <Stack gap={1}>
          <b>Type of Data:</b>
          <span>{DataUploadLabels[data?.type]}</span>
        </Stack>
        {renderFeedback}
      </Stack>
    );
  }, [isLoading, error, data, renderFeedback]);

  return (
    <SectionWrapper title="Submitted File">
      <div className="mb-2">
        <HistoryBackButton />
      </div>
      <Container className="p-3 bg-light" fluid>
        {renderContent}
      </Container>
    </SectionWrapper>
  );
};

export default UploadInfoPage;
