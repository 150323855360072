import React from 'react';
import PropTypes from 'prop-types';

import api from 'api';
import useMountEffect from 'hooks/useMountEffect';
import useRequest from 'hooks/useRequest';
import useImpressionateUser from 'hooks/useImpressionateUser';
import { BurncenterAccessLevelEnum } from 'utils/enums';
import Labels from 'utils/labels';

import Loader from 'components/elements/Loader';
import { FlexDiv, FlexCenter } from 'resources/styles/display';
import colors from 'resources/styles/colors';
import SectionWrapper from 'components/layout/SectionWrapper';

const WorkspacePageMsg = ({ msg, style }) => (
  <FlexCenter style={{ color: colors.darkGray, height: '80%', ...style }}>
    {msg}
  </FlexCenter>
);

WorkspacePageMsg.propTypes = {
  msg: PropTypes.node.isRequired,
  style: PropTypes.shape({}),
};

const WorkspacePage = () => {
  const { impersonateUser, currentUser } = useImpressionateUser();

  const [burncenter, setBurncenter] = React.useState(null);
  const {
    doRequest: getDashboard,
    data: workspaceData,
    isLoading: loadingWorkspace,
  } = useRequest(api.getDashboard);

  useMountEffect(() => {
    if (!currentUser?.belongsTo) return;
    api.getBurncenter(currentUser.belongsTo).then(({ data }) => {
      setBurncenter(data);
      if (!data?.sigmaWorkspaceLandingLink) return;
      getDashboard('my-workspace', { impersonateUserId: impersonateUser });
    });
  });

  const renderContent = React.useMemo(() => {
    if (!burncenter || loadingWorkspace) return <Loader />;
    if (
      burncenter.burnCenterAccessLevel
      !== BurncenterAccessLevelEnum.FULL_PLATFORM
    ) {
      return (
        <WorkspacePageMsg
          style={{
            padding: '20%',
          }}
          msg={(
            <h4 style={{ textAlign: 'center' }}>
              <p>{`${Labels.workspace} is available to BCQP Full Platform burn centers.`}</p>
              <p>
                Please reach out to
                {' '}
                <a
                  href="mailto:support-bcqp@bdatainc.com"
                  rel="noopener noreferrer"
                  target="_blank"
                  style={{ color: colors.secondaryColor }}
                >
                  support-bcqp@bdatainc.com
                </a>
                {' '}
                to learn more.
              </p>
            </h4>
          )}
        />
      );
    }

    if (!burncenter.sigmaWorkspaceLandingLink) {
      return <WorkspacePageMsg msg={<h2>⚙ Coming Soon</h2>} />;
    }

    return (
      <FlexDiv style={{ height: '600px' }}>
        {!!workspaceData && (
          <iframe
            width="100%"
            height="100%"
            title="workspaceData"
            src={workspaceData.url}
          />
        )}
      </FlexDiv>
    );
  }, [burncenter, loadingWorkspace, workspaceData]);

  return <SectionWrapper>{renderContent}</SectionWrapper>;
};

export default WorkspacePage;
