export const blobToBase64Url = (blob) => {
  const reader = new FileReader();
  reader.readAsDataURL(blob);
  return new Promise((resolve) => {
    reader.onloadend = () => {
      resolve(reader.result);
    };
  });
};

// eslint-disable-next-line
export const getSearchParams = (search) => search
  ?.substring(1)
  .split('&')
  .reduce((res, param) => {
    const [searchId, searchValue] = param.split('=');
    return { ...res, [searchId]: searchValue };
  }, {}) ?? {};
