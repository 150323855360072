export const MainRoutes = {
  VERIFICATION: '/verifications',
  REPORTS: '/reports',
  UPLOAD: '/data_uploaders',
  TRAINING: '/training',
  RESTRICTED: '/restricted',
};

export const AppAdminRoutes = {
  HOME: 'dashboard',
  USERS: 'users',
  FORMS: 'forms',
  SITE_VISIT: 'site-visit',
  DOCS: 'documents',
  DETERMINATION: 'final-determination',
};

export const FormRoutes = {
  initApp: 'initial-application',
  prq: 'prq',
  meetingRounds: 'meeting-rounds',
  keyFindings: 'key-findings',
  finalReport: 'site-report',
};

export const ReportRoutes = {
  MANAGE: 'manage',
  VIEW: 'view',
};

export const verificationRoutes = {
  REVIWER: 'reviewer',
  VERIFICATION: 'verification',
};
