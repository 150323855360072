import React from 'react';
import { Link } from 'react-router-dom';
import debounce from 'lodash/debounce';

import { Button, Form, InputGroup } from 'react-bootstrap';

import useMountEffect from 'hooks/useMountEffect';
import useQueryParams from 'hooks/useQueryParams';
import useUser from 'hooks/useUser';
import { ContentCategoryLabels } from 'utils/enums';

import SectionWrapper from 'components/layout/SectionWrapper';
import { FlexDivSpaceBetweenCenter } from 'resources/styles/display';

import TrainingTabs from '../elements/TrainingTabs';
// import ChangeLogTable from '../elements/ChangeLogTable';

const TrainingHome = () => {
  const { queryParams, setQueryParams } = useQueryParams();
  const { isBdataAmin } = useUser();

  const currentTab = React.useMemo(
    () => queryParams.cat || Object.keys(ContentCategoryLabels)[0],
    [queryParams],
  );

  const onTabChange = (tab) => {
    setQueryParams({
      cat: tab,
      search: queryParams.search,
    });
  };

  const onSearch = debounce(({ target: { value } }) => {
    setQueryParams({
      search: value,
      cat: currentTab,
    });
  }, 500);

  useMountEffect(() => {
    setQueryParams({
      cat: currentTab,
      search: queryParams.search || '',
    });
  });

  return (
    <SectionWrapper title="Training">
      <FlexDivSpaceBetweenCenter className="mb-3">
        <div>
          <InputGroup>
            <Form.Control
              placeholder="Search"
              aria-label="search"
              aria-describedby="search"
              onChange={onSearch}
              defaultValue={queryParams.search}
            />
          </InputGroup>
        </div>
        <div>
          {isBdataAmin && (
            <Button variant="secondary" as={Link} to="add">
              Add Content
            </Button>
          )}
        </div>
      </FlexDivSpaceBetweenCenter>
      <TrainingTabs
        currentTab={currentTab}
        tabs={ContentCategoryLabels}
        onTabChange={onTabChange}
        search={queryParams.search}
      />

      {/*
      <ChangeLogTable />
      */}
    </SectionWrapper>
  );
};

export default TrainingHome;
