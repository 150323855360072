import React from 'react';

import {
  MenuItem,
  MenuItemTitle,
  MenuItemBody,
} from 'components/templates/menus/styles';

const VerificationListMenu = () => (
  <div className="shadow border p-3">
    <MenuItem>
      <a
        target="_blank"
        href="http://ameriburn.org/quality-care/verification/verification-process-burn-centers/"
        rel="noopener noreferrer"
      >
        <MenuItemTitle>Burn Center Site Visit Instructions</MenuItemTitle>
      </a>
    </MenuItem>
    <MenuItem>
      <MenuItemTitle>Questions?</MenuItemTitle>
      <a
        href="mailto:verification@ameriburn.org"
        rel="noopener noreferrer"
        target="_blank"
      >
        <MenuItemBody>Email verification@ameriburn.org</MenuItemBody>
      </a>
    </MenuItem>
  </div>
);

export default VerificationListMenu;
