import { SET_FEEDBACK, CLEAR_FEEDBACK } from './actions';

const initialState = null;

export default function feedback(state = initialState, action) {
  switch (action.type) {
    case CLEAR_FEEDBACK:
      return initialState;
    case SET_FEEDBACK:
      return { status: action.status, msg: action.msg };
    default:
      return state;
  }
}
