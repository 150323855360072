import React from 'react';
import {
  NavLink,
  // useLocation
} from 'react-router-dom';

import { FaBars } from 'react-icons/fa6';
import {
  Button,
  Navbar,
  Nav,
  // NavDropdown,
} from 'react-bootstrap';
import styled from '@emotion/styled';

import useUser from 'hooks/useUser';
import { appRoles, Roles } from 'utils/enums';
// import Labels from 'utils/labels';
import {
  MainRoutes,
  //  ReportRoutes
} from 'helpers/routes';

const StyledNavbar = styled(Navbar)`
  background-color: var(--bs-primary);
  padding: 10px;
  .nav-link {
    padding: 0.5rem 1.5rem !important;
    color: white !important;
    &:hover {
      background-color: var(--bs-secondary);
    }
  }
  .nav-pills {
    --bs-nav-pills-border-radius: var(--bs-border-radius);
    --bs-nav-pills-link-active-color: #fff;
    --bs-nav-pills-link-active-bg: var(--bs-secondary);
  }
  .dropdown-menu {
    .dropdown-item.active {
      background-color: var(--bs-secondary);
    }
  }
  .navbar-toggler {
    color: white;
    border: 1px solid white !important;
    outline: 0 !important;
  }
`;

const NavbarCollapse = styled(Navbar.Collapse)`
  justify-content: space-between;
`;

const { REACT_APP_BDATA_HOST_URL, REACT_APP_DATA_ENTRY_URL } = process.env;

const menuRoles = {
  burnCenters: [
    appRoles.abaADMIN,
    appRoles.bdataADMIN,
    appRoles.qualityAssurance,
    appRoles.qualityImprovement,
  ],
  users: [
    appRoles.abaADMIN,
    appRoles.bdataADMIN,
    appRoles.superUser,
    appRoles.manager,
  ],
  adminVerification: [appRoles.bdataADMIN],
};

const NavMenu = () => {
  // const { pathname } = useLocation();
  const {
    logout, roleName, appUser, hasReports, hasVerification,
  } = useUser();

  if (!appUser.id) return null;

  return (
    <StyledNavbar variant="dark" collapseOnSelect expand="lg">
      <Navbar.Toggle aria-controls="responsive-navbar-nav">
        <FaBars />
      </Navbar.Toggle>
      <NavbarCollapse id="responsive-navbar-nav">
        <Nav variant="pills" defaultActiveKey="link-1">
          <Nav.Item>
            <Nav.Link href={REACT_APP_BDATA_HOST_URL}>Home</Nav.Link>
          </Nav.Item>
          {!!appUser.clinicalStudioRole && (
            <Nav.Item>
              <Nav.Link href={REACT_APP_DATA_ENTRY_URL}>Data Entry</Nav.Link>
            </Nav.Item>
          )}
          {/*
            hasReports && (
            <NavDropdown
              title="Reports"
              active={pathname.startsWith(MainRoutes.REPORTS)}
            >
              <NavDropdown.Item
                as={NavLink}
                to={`${MainRoutes.REPORTS}/${ReportRoutes.VIEW}`}
              >
                View Dashboard
              </NavDropdown.Item>
              {isBdataAmin && (
                <NavDropdown.Item
                  as={NavLink}
                  to={`${MainRoutes.REPORTS}/${ReportRoutes.MANAGE}`}
                >
                  {Labels.reportManagment}
                </NavDropdown.Item>
              )}
            </NavDropdown>
          )
          */}
          {hasReports && (
            <Nav.Item>
              <Nav.Link as={NavLink} to={MainRoutes.REPORTS}>
                Reports
              </Nav.Link>
            </Nav.Item>
          )}
          {roleName !== appRoles.summaryReportViewer && (
            <Nav.Item>
              <Nav.Link as={NavLink} to={MainRoutes.TRAINING}>
                Training
              </Nav.Link>
            </Nav.Item>
          )}
          {menuRoles.burnCenters.includes(roleName) && (
            <Nav.Item>
              <Nav.Link href={`${REACT_APP_BDATA_HOST_URL}/burn_centers`}>
                Burn Centers
              </Nav.Link>
            </Nav.Item>
          )}
          {appUser.role === Roles.burnCenterUser && (
            <Nav.Item>
              <Nav.Link
                href={`${REACT_APP_BDATA_HOST_URL}/burn_centers/${appUser.belongsTo}`}
              >
                Profile
              </Nav.Link>
            </Nav.Item>
          )}
          {menuRoles.users.includes(roleName) && (
            <Nav.Item>
              <Nav.Link href={`${REACT_APP_BDATA_HOST_URL}/users`}>
                Users
              </Nav.Link>
            </Nav.Item>
          )}
          {/*
          hasVerification && (
            <NavDropdown
              title="Verification"
              active={pathname.startsWith(MainRoutes.VERIFICATION)}
            >
              <NavDropdown.Item
                as={Link}
                to={MainRoutes.VERIFICATION}
                active={!pathname.includes('surveys')}
              >
                Applications
              </NavDropdown.Item>
              {menuRoles.adminVerification.includes(roleName) && (
                <NavDropdown.Item
                  as={NavLink}
                  to={`${MainRoutes.VERIFICATION}/surveys`}
                >
                  {Labels.surveyManagment}
                </NavDropdown.Item>
              )}
            </NavDropdown>
          )
          */}
          {hasVerification && (
            <Nav.Item>
              <Nav.Link as={NavLink} to={MainRoutes.VERIFICATION}>
                Verification
              </Nav.Link>
            </Nav.Item>
          )}
        </Nav>
      </NavbarCollapse>
      <div>
        <Nav.Link
          as={Button}
          onClick={() => logout({
            logoutParams: {
              returnTo: `${REACT_APP_BDATA_HOST_URL}/sign_out`,
            },
          })}
        >
          Log Out
        </Nav.Link>
      </div>
    </StyledNavbar>
  );
};

export default NavMenu;
