import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { Button } from 'react-bootstrap';

import SVButton from 'components/elements/SVButton';
import FontAwesomeIcon from 'components/elements/FontAwesomeIcon';
import { FlexDivSpaceBetweenCenter } from 'resources/styles/display';
import useCurrentApplication from 'hooks/useCurrentApplication';
import {
  closeQuestionComments,
  addQuestionComment,
  loadMoreComments,
} from 'store/actions/comments';

import {
  SurveyCommentsContainer,
  SurveyCommentTxt,
  SurveyCommentUser,
  SurveyCommentsTitle,
} from './styles';

const SurveyComments = ({ surveyVersionId }) => {
  const { appData, userTypeId } = useCurrentApplication();
  const dispatch = useDispatch();
  const textInput = useRef(null);
  const { isLoading, question, data } = useSelector(
    (state) => state.comments.currentComments,
  );
  const { items, total, page } = data;

  const clearTextArea = () => {
    textInput.current.value = '';
  };

  const handleCommentAdd = () => {
    dispatch(
      addQuestionComment({
        applicationId: appData.id,
        surveyVersionId,
        userTypeId,
        questionId: question?.id,
        message: textInput.current.value.trim(),
        cb: clearTextArea,
      }),
    );
  };

  const handleMoreComments = () => {
    dispatch(
      loadMoreComments({
        page: page + 1,
        questionId: question?.id,
        applicationId: appData.id,
        userTypeId,
        surveyVersionId,
      }),
    );
  };

  if (!question?.id) return null;

  return (
    <SurveyCommentsContainer>
      <FlexDivSpaceBetweenCenter>
        <SurveyCommentsTitle>{question?.title}</SurveyCommentsTitle>
        <Button
          variant="link"
          size="lg"
          onClick={() => dispatch(closeQuestionComments())}
        >
          <FontAwesomeIcon className="fa-times-circle-o" />
        </Button>
      </FlexDivSpaceBetweenCenter>
      {isLoading ? (
        <FontAwesomeIcon className="fa-spinner fa-spin fa-2x" />
      ) : (
        <div>
          <div>
            <SurveyCommentTxt placeholder="Comment text" ref={textInput} />
            <SVButton size="sm" variant="secondary" onClick={handleCommentAdd}>
              Add
            </SVButton>
          </div>
          {items?.map((item) => (
            <div key={item.id} className="pt-3">
              <SurveyCommentUser>{item.userFullName}</SurveyCommentUser>
              <div>{item.message}</div>
            </div>
          ))}
          {items?.length < total && (
            <SVButton size="sm" onClick={handleMoreComments}>
              Load more coments
            </SVButton>
          )}
        </div>
      )}
    </SurveyCommentsContainer>
  );
};

SurveyComments.propTypes = {
  surveyVersionId: PropTypes.number.isRequired,
};

export default SurveyComments;
