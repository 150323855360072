import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { Alert, Button } from 'react-bootstrap';
import { BsFillTrash3Fill as DeleteIcon } from 'react-icons/bs';

import SVButton from 'components/elements/SVButton';
import Table from 'components/elements/Table';

const FileTable = ({
  onFileClick,
  onFileDelete,
  page,
  handlePageChange,
  sort,
  sortOrder,
  handleSortChange,
  files: { isLoading, error, data },
}) => {
  const fileClickButton = React.useCallback(
    (fields) => (
      <Button
        variant="link"
        onClick={() => {
          onFileClick(fields);
        }}
      >
        {fields.fileName}
      </Button>
    ),
    [onFileClick],
  );

  const fileDeleteButton = React.useCallback(
    (fields) => (
      <div style={{ textAlign: 'right ' }}>
        <SVButton
          size="sm"
          variant="danger"
          onClick={() => onFileDelete(fields.id)}
        >
          <DeleteIcon />
        </SVButton>
      </div>
    ),
    [onFileDelete],
  );
  const columns = useMemo(
    () => [
      {
        id: 'name',
        label: 'Document Name',
        override: fileClickButton,
      },
      {
        id: 'delete',
        label: '',
        override: fileDeleteButton,
      },
    ],
    [fileClickButton, fileDeleteButton],
  );

  if (error) return <Alert variant="danger">{error}</Alert>;

  const { total, limit } = data;

  return (
    <Table
      columns={columns}
      items={data?.items ?? []}
      order={sort}
      orderDirection={sortOrder}
      pagination={{ total, limit, page }}
      handlePageChange={handlePageChange}
      handleOrderChange={handleSortChange}
      isLoading={isLoading}
    />
  );
};

FileTable.propTypes = {
  onFileClick: PropTypes.func.isRequired,
  onFileDelete: PropTypes.func.isRequired,
  handleSortChange: PropTypes.func.isRequired,
  handlePageChange: PropTypes.func.isRequired,
  files: PropTypes.shape({
    isLoading: PropTypes.bool,
    error: PropTypes.string,
    data: PropTypes.shape({
      items: PropTypes.arrayOf(PropTypes.shape({})),
      total: PropTypes.number,
      limit: PropTypes.number,
    }),
  }).isRequired,
  page: PropTypes.number,
  sort: PropTypes.string,
  sortOrder: PropTypes.string,
};

export default FileTable;
