import React, {
  useEffect, useRef, useState, useCallback,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Stack } from 'react-bootstrap';

import Modal from 'components/elements/BasicModal';
import SVButton from 'components/elements/SVButton';
import { DEFAULT_PAGE_SIZE } from 'utils/constants';

import {
  getApplicationFiles,
  uploadApplicationFile,
  deleteApplicationFile,
  downloadFile,
} from 'store/actions/files';
import useTableState from 'hooks/useTableState';

import FileTable from './FileTable';

export default function DocumentUpload() {
  const dispatch = useDispatch();
  const { appId } = useParams();
  const [adding, setAdding] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const fileRef = useRef(null);
  const {
    page, handlePageChange, sortBy, sortOrder, handleSortChange,
  } = useTableState('name', 'asc', {});
  const {
    isUploading, isLoading, error, data,
  } = useSelector(
    ({ applications: { files } }) => files,
  );

  const handleGetFiles = useCallback(() => {
    dispatch(
      getApplicationFiles(appId, {
        Limit: DEFAULT_PAGE_SIZE,
        Page: page,
        Order: sortBy,
        OrderDirection: sortOrder,
      }),
    );
  }, [dispatch, appId, page, sortBy, sortOrder]);

  useEffect(handleGetFiles, [handleGetFiles]);

  return (
    <div>
      {deleteId && (
        <Modal
          title="Are you sure you want to delete this file?"
          isOpen
          onClose={() => setDeleteId(null)}
          onConfirm={() => {
            dispatch(deleteApplicationFile(deleteId));
            setDeleteId(null);
          }}
        />
      )}
      {adding ? (
        <Stack direction="horizontal" gap={2}>
          <input
            type="file"
            accept=".doc,.docx,.pdf,.ppt,.pptx,.pps,.ppsx,.xls,.xlsx,.odt,.txt,.csv,image/png,image/jpeg"
            ref={fileRef}
            disabled={isUploading}
          />
          <SVButton
            variant="secondary"
            size="sm"
            onClick={() => {
              dispatch(
                uploadApplicationFile(appId, fileRef.current.files[0], () => setAdding(false)),
              );
            }}
            disabled={isUploading}
          >
            Upload
          </SVButton>
          <SVButton
            variant="cancel"
            size="sm"
            onClick={() => setAdding(false)}
            disabled={isUploading}
          >
            Cancel
          </SVButton>
        </Stack>
      ) : (
        <SVButton variant="secondary" size="sm" onClick={() => setAdding(true)}>
          Add Document
        </SVButton>
      )}
      <div style={{ marginTop: '1rem' }}>
        <FileTable
          files={{
            isLoading,
            error,
            data,
          }}
          page={page}
          onFileClick={(f) => dispatch(downloadFile(f))}
          onFileDelete={(id) => setDeleteId(id)}
          handlePageChange={handlePageChange}
          sort={sortBy}
          sortOrder={sortOrder}
          handleSortChange={handleSortChange}
        />
      </div>
    </div>
  );
}
