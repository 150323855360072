import React from 'react';
import PropTypes from 'prop-types';
import { useAuth0 } from '@auth0/auth0-react';

import Button from 'react-bootstrap/Button';

import AppContainer from 'components/layout/AppContainer';
import AppHeader from 'components/layout/AppHeader';

const { REACT_APP_BDATA_HOST_URL } = process.env;

const ErrorPage = ({ errorTxt }) => {
  const { logout } = useAuth0();

  return (
    <AppContainer>
      <AppHeader />
      <div className="d-flex flex-column flex-grow-1">
        <div className="bg-secondary text-white p-3 text-center">
          {errorTxt}
        </div>
        <div className="d-flex flex-column flex-grow-1 justify-content-center align-items-center">
          <div className="text-center">
            <h4>Login failed</h4>
            <Button
              variant="danger"
              onClick={() => logout({
                logoutParams: {
                  returnTo: `${REACT_APP_BDATA_HOST_URL}/sign_out`,
                },
              })}
            >
              Log out and try again
            </Button>
          </div>
        </div>
      </div>
    </AppContainer>
  );
};

ErrorPage.propTypes = {
  errorTxt: PropTypes.string.isRequired,
};

export default ErrorPage;
