import styled from '@emotion/styled';

import colors from 'resources/styles/colors';

// eslint-disable-next-line import/prefer-default-export
export const FormButton = styled.button`
  background: transparent;
  border: none;
  width: 150px;
  text-align: center;
  color: ${(props) => (props.active && colors.orange) || colors.darkerGray};
  font-size: 14px;
  font-weight: bold;
  & > i {
    font-size: 75px;
    padding: 10px;
    border: 5px solid
      ${(props) => (props.active && colors.orange) || colors.darkerGray};
  }
`;
