import { RECEIVED_USERS } from './actions';

const initialState = {};

const users = (state = initialState, { type, data }) => {
  switch (type) {
    case RECEIVED_USERS:
      return data;
    default:
      return state;
  }
};

export default users;
