import {
  GET_REPORTS,
  GET_REPORTS_SUCCESS,
  GET_REPORTS_ERROR,
} from './actions';

const initialState = {
  isLoading: false,
  data: {
    data: [],
  },
  error: null,
};

export default function reports(state = initialState, action) {
  switch (action.type) {
    case GET_REPORTS:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case GET_REPORTS_SUCCESS:
      return {
        isLoading: false,
        data: action.data,
        error: null,
      };
    case GET_REPORTS_ERROR:
      return {
        isLoading: false,
        data: initialState.data,
        error: action.error.message,
      };
    default:
      return state;
  }
}
