import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';

import store from 'components/store';

import SurveyQuestionButtons from './SurveyQuestionButtons';

export default function SurveyQuestionOverrider({
  options,
  surveyVersionId,
  userTypeId,
}) {
  const header = options.htmlElement.querySelector('.sv-question__header');
  if (!header) return;
  const element = document.createElement('div');
  element.className = 'question-buttons';
  header.insertBefore(element, header.childNodes[0]);
  const { name, title } = options.question;
  const root = ReactDOM.createRoot(element);
  root.render(
    <Provider store={store}>
      <SurveyQuestionButtons
        questionId={name}
        title={title}
        surveyVersionId={surveyVersionId}
        userTypeId={userTypeId}
      />
    </Provider>,
  );
}
