import api from 'api';

export const RECEIVED_USERS = 'RECEIVED_USER';

export const getUsers = (params) => (dispatch) => {
  api.getUsers(params).then(({ data }) => {
    dispatch({
      type: RECEIVED_USERS,
      data,
    });
  });
};
