import { useRef, useEffect } from 'react';

const useIsFirstRender = () => {
  const isFirst = useRef(true);

  if (isFirst.current) {
    isFirst.current = false;
    return true;
  }
  return isFirst.current;
};

const useUpdateEffect = (effect, deps) => {
  const isFirst = useIsFirstRender();

  // eslint-disable-next-line
  useEffect(() => {
    if (!isFirst) {
      return effect();
    }
    // eslint-disable-next-line
  }, deps);
};

export default useUpdateEffect;
