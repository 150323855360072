import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { SurveyPDF } from 'survey-pdf';
import flatMap from 'lodash/flatMap';

import { FaDownload } from 'react-icons/fa';
import { Spinner, Stack } from 'react-bootstrap';

import api from 'api';
import useUser from 'hooks/useUser';
import useFeedback from 'hooks/useFeedback';
import useCurrentApplication from 'hooks/useCurrentApplication';

import SVButton from 'components/elements/SVButton';

import {
  surveyPdfOptions,
  mapQuestions,
  onRenderHeader,
  onRenderFooter,
  onRenderQuestion,
} from './utils';

function SurveyPDFExport({ surveyVersionId, overrider }) {
  const { setError } = useFeedback();
  const hasChat = useRef(null);
  const [loading, setLoading] = useState(false);
  const { isAdmin } = useUser();
  const {
    appData: { id: appId, bcqpBurnCenterName },
    userTypeId,
  } = useCurrentApplication();

  const handlePDFDownload = async () => {
    setLoading(true);

    try {
      const {
        data: { model, data },
      } = await api.getReport({
        applicationId: appId,
        surveyVersionId,
        userTypeId,
        includeChat: isAdmin ? null : hasChat.current.checked,
      });

      const pdf = new SurveyPDF(model, surveyPdfOptions);
      pdf.data = data;
      pdf.mode = 'display';

      pdf.onRenderHeader.add(onRenderHeader(bcqpBurnCenterName));

      pdf.onRenderFooter.add(onRenderFooter);

      const allQuestions = mapQuestions(
        flatMap(model.pages, ({ elements }) => elements),
      );

      pdf.onRenderQuestion.add(onRenderQuestion(allQuestions, { overrider }));

      await pdf.save(`Report - ${bcqpBurnCenterName}.pdf`);
    } catch (e) {
      setError('Error downloading Pdf');
    }

    setLoading(false);
  };

  return (
    <Stack direction="horizontal" gap={4}>
      {!isAdmin && (
        <Stack direction="horizontal" gap={2}>
          <span>Include Chat</span>
          <input type="checkbox" ref={hasChat} disabled={loading} />
        </Stack>
      )}
      <SVButton onClick={handlePDFDownload} disabled={loading}>
        <Stack direction="horizontal" gap={3}>
          {loading ? <Spinner size="sm" animation="border" /> : <FaDownload />}
          Download PDF
        </Stack>
      </SVButton>
    </Stack>
  );
}

SurveyPDFExport.propTypes = {
  surveyVersionId: PropTypes.number.isRequired,
  overrider: PropTypes.shape({}),
};

export default SurveyPDFExport;
