import React from 'react';

import useUser from 'hooks/useUser';

import AdminVerification from './admin';
import UserVerification from './user';

const VerificationPage = () => {
  const { isAdmin } = useUser();
  return isAdmin ? <AdminVerification /> : <UserVerification />;
};

export default VerificationPage;
