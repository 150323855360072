import { findIndex } from 'lodash';
import update from 'immutability-helper';

import {
  GET_COMMENT_COUNT,
  GET_COMMENT_COUNT_SUCCESS,
  GET_COMMENT_COUNT_ERROR,
  ADD_QUESTION_COMMENTS,
} from 'store/actions/comments';

const initialState = {
  isLoading: true,
  data: [],
};

export default function commentCount(state = initialState, action) {
  switch (action.type) {
    case GET_COMMENT_COUNT:
      return initialState;
    case GET_COMMENT_COUNT_SUCCESS:
      return { isLoading: false, data: action.response.data };
    case GET_COMMENT_COUNT_ERROR:
      return { isLoading: false, data: [], error: action.error };
    case ADD_QUESTION_COMMENTS: {
      const found = findIndex(state.data, {
        questionId: action.data.questionId,
      });
      if (found > -1) {
        const { count } = state.data[found];
        return update(state, {
          data: { [found]: { count: { $set: count + 1 } } },
        });
      }
      const {
        applicationId,
        questionId,
        surveyVersionId,
        userTypeId,
      } = action.data;
      const addObject = {
        applicationId,
        questionId,
        surveyVersionId,
        userTypeId,
        count: 1,
      };
      return update(state, { data: { $push: [addObject] } });
    }
    default:
      return state;
  }
}
