import React from 'react';

import api from 'api';
import useFeedback from 'hooks/useFeedback';
import useGoBack from 'hooks/useGoBack';

import SectionWrapper from 'components/layout/SectionWrapper';

import ReportForm from './ReportForm';

const ReportAdd = () => {
  const { setSuccess, setError } = useFeedback();
  const { goBack } = useGoBack();

  const onSubmit = React.useCallback(
    (values) => {
      api
        .addDashboard(values)
        .then(() => {
          setSuccess('Report added successfully');
          goBack();
        })
        .catch(() => setError('Error adding report'));
    },
    [goBack, setError, setSuccess],
  );

  return (
    <SectionWrapper title="New Report">
      <ReportForm onSubmit={onSubmit} />
    </SectionWrapper>
  );
};

export default ReportAdd;
