import React from 'react';
import { Route, Routes } from 'react-router-dom';

import ReportList from './ReportList';
import ReportEdit from './ReportEdit';
import ReportAdd from './ReportAdd';

const ReportManagePage = () => (
  <Routes>
    <Route path="/" exact element={<ReportList />} />
    <Route path="new" exact element={<ReportAdd />} />
    <Route path=":reportId" exact element={<ReportEdit />} />
  </Routes>
);

export default ReportManagePage;
