import styled from '@emotion/styled';

import colors from 'resources/styles/colors';

export const SurveyCommentsContainer = styled.div`
  font-size: 15px;
  min-width: 200px;
  width: 30%;
  max-width: 400px;
  border-left: 2px solid ${colors.lightGray};
  padding-left: 20px;
`;

export const SurveyCommentTxt = styled.textarea`
  width: 100%;
  border: 1px solid ${colors.gray};
  border-radius: 5px;
  min-height: 60px;
`;

export const SurveyCommentUser = styled.div`
  color: ${colors.orange};
  font-weight: 600;
`;

export const SurveyCommentsTitle = styled.div`
  font-size: 20px;
  font-weight: 700;
  color: ${colors.green};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
