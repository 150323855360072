import React from 'react';
import { useNavigate } from 'react-router-dom';

import api from 'api';

import SectionWrapper from 'components/layout/SectionWrapper';

import TrainingItemForm from '../elements/TrainingItemForm';

const TrainingItemAdd = () => {
  const navigate = useNavigate();

  const onSubmit = async (values) => {
    const { data } = await api.addContent(values);
    navigate(`../${data.id}`, { replace: true, state: { forceRefresh: true } });
  };

  return (
    <SectionWrapper title="Add Content">
      <TrainingItemForm onSubmit={onSubmit} />
    </SectionWrapper>
  );
};

export default TrainingItemAdd;
