import React from 'react';
import { Link } from 'react-router-dom';

import { FlexColumnCenter } from 'resources/styles/display';
import SVButton from 'components/elements/SVButton';

const { REACT_APP_BDATA_HOST_URL } = process.env;

const UnauthorizedPage = () => (
  <FlexColumnCenter style={{ flexGrow: 1 }}>
    <h4>You are not authorized to access this page</h4>
    <SVButton as={Link} to={REACT_APP_BDATA_HOST_URL}>
      Back to Home Page
    </SVButton>
  </FlexColumnCenter>
);

export default UnauthorizedPage;
