import update from 'immutability-helper';
import { cloneDeep } from 'lodash';

import {
  GET_QUESTION_COMMENTS,
  GET_QUESTION_COMMENTS_SUCCESS,
  GET_QUESTION_COMMENTS_ERROR,
  CLOSE_QUESTION_COMMENTS,
  ADD_QUESTION_COMMENTS,
  LOAD_MORE_COMMENTS,
} from 'store/actions/comments';

const initialState = {
  isLoading: true,
  question: {},
  data: {},
};

export default function currentComments(state = initialState, action) {
  switch (action.type) {
    case GET_QUESTION_COMMENTS:
      return {
        ...initialState,
        question: { id: action.questionId, title: action.title },
      };
    case GET_QUESTION_COMMENTS_SUCCESS:
      return { ...state, isLoading: false, data: action.data };
    case GET_QUESTION_COMMENTS_ERROR:
      return {
        ...state,
        isLoading: false,
        data: {},
        error: action.error,
      };
    case CLOSE_QUESTION_COMMENTS:
      return initialState;
    case ADD_QUESTION_COMMENTS: {
      const {
        data: {
          total, limit, page, items,
        },
      } = state;
      const newItems = cloneDeep(items);
      newItems.unshift(action.data);
      if (newItems.length > (page + 1) * limit) newItems.pop();
      return update(state, {
        data: { total: { $set: total + 1 }, items: { $set: newItems } },
      });
    }
    case LOAD_MORE_COMMENTS: {
      const { page, items } = action.data;
      return update(state, {
        data: { items: { $push: items }, page: { $set: page } },
      });
    }
    default:
      return state;
  }
}
