import React from 'react';
import PropTypes from 'prop-types';

import styled from '@emotion/styled';

const SectionTitle = styled.h1`
  margin: 0 0 2rem 0;
  padding: 0;
  font-size: 24px;
`;

const SectionWrapper = ({ children, title }) => (
  <div className="d-flex flex-grow-1 px-3 py-4 flex-column">
    {title && <SectionTitle>{title}</SectionTitle>}
    {children}
  </div>
);

SectionWrapper.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
};

export default SectionWrapper;
