import React from 'react';
import { Link, useLocation } from 'react-router-dom';

import useCurrentApplication from 'hooks/useCurrentApplication';
import { formTitle, programType, programSubType } from 'helpers/forms';
import { FormRoutes } from 'helpers/routes';
import FontAwesomeIcon from 'components/elements/FontAwesomeIcon';
import SectionWrapper from 'components/layout/SectionWrapper';
import { HistoryBackButton } from 'components/elements/BackButton';

import { FormButton } from './VerificationDetail.styles';
import VerificationMenu from './VerificationMenu';

const VerificationDetail = () => {
  const { appData, isReviewer } = useCurrentApplication();
  const { pathname } = useLocation();

  const formButtons = React.useMemo(
    () => [
      {
        id: FormRoutes.prq,
        icon: 'fa-tasks',
        label: formTitle.prq,
        show: appData.requestedAt,
        submited: isReviewer
          ? appData.prqSrSubmittedAt
          : appData.prqSubmittedAt,
      },
      {
        id: FormRoutes.meetingRounds,
        icon: 'fa-calendar-check-o',
        label: formTitle.meetingRounds,
        show:
          appData.prqSubmittedAt
          && appData.programTypeId !== programType.FELLOWSHIP
          && appData.programSubtypeId !== programSubType.FOCUS_REVIEW,
        submited: isReviewer
          ? appData.meetingsAndRoundsSrSubmittedAt
          : appData.meetingsAndRoundsSubmittedAt,
      },
      {
        id: FormRoutes.keyFindings,
        icon: 'fa-file-text',
        label: formTitle.keyFindings,
        show:
          isReviewer
          && appData.prqSubmittedAt
          && appData.programSubtypeId !== programSubType.FOCUS_REVIEW,
        submited: appData.keyFindingsSubmittedAt,
      },
      {
        id: FormRoutes.finalReport,
        icon: 'fa-check-square-o',
        label: formTitle.siteReport,
        show:
          isReviewer
          && appData.programSubtypeId !== programSubType.FOCUS_REVIEW
          && appData.prqSubmittedAt,
      },
    ],
    [appData, isReviewer],
  );

  return (
    <SectionWrapper title={appData.bcqpBurnCenterName}>
      <div className="d-flex justify-content-between">
        <div>
          <HistoryBackButton />
          <div className="mt-4">
            {formButtons.map(
              ({
                id, icon, label, show, submited,
              }) => !!show && (
              <Link to={`../${id}`} key={id} state={{ backTo: pathname }}>
                <FormButton active={!submited}>
                  <FontAwesomeIcon className={icon} />
                  <div>{label}</div>
                </FormButton>
              </Link>
              ),
            )}
          </div>
        </div>
        <VerificationMenu />
      </div>
    </SectionWrapper>
  );
};

export default VerificationDetail;
