import PropTypes from 'prop-types';

import parse from 'html-react-parser';

const RichTextDisplay = ({ value }) => value && parse(value);

RichTextDisplay.propTypes = {
  value: PropTypes.string,
};

export default RichTextDisplay;
