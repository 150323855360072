import React from 'react';
import { Stack } from 'react-bootstrap';

import useCurrentApplication from 'hooks/useCurrentApplication';

import { formatDate } from 'utils/dateTime';
import {
  getStateLabel,
  getProgramTypeLabel,
  programSubTypeLabel,
} from 'helpers/forms';

const AppDetails = () => {
  const {
    appData: {
      programTypeId,
      programSubtypeId,
      stateId,
      requestedAt,
      siteVisitAt,
    },
  } = useCurrentApplication();

  return (
    <Stack gap={1}>
      <span>
        {`Type: ${getProgramTypeLabel(programTypeId)}/${programSubTypeLabel(
          programSubtypeId,
        )}`}
      </span>
      <span>{`Review Status: ${getStateLabel(stateId)}`}</span>

      <span>{`Application Date: ${formatDate(requestedAt)}`}</span>
      <span>{`Site Visit Date: ${formatDate(siteVisitAt)}`}</span>
    </Stack>
  );
};

export default AppDetails;
