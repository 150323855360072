import React from 'react';
import { Route, Routes } from 'react-router-dom';

import InitialApplication from 'components/forms/InitialApplication';
import PRQ from 'components/forms/PRQ';
import ExecutiveSession from 'components/forms/ExecutiveSession';
import ASReport from 'components/forms/ASReport';
import FinalReport from 'components/forms/FinalReport';
import { FormRoutes } from 'helpers/routes';

import FormsPage from './FormsPage';
import Container from '../components/Container';

const Forms = () => (
  <Routes>
    <Route
      index
      element={(
        <Container>
          <FormsPage />
        </Container>
      )}
    />
    <Route path={FormRoutes.initApp} element={<InitialApplication />} />
    <Route path={FormRoutes.prq} element={<PRQ />} />
    <Route path={FormRoutes.meetingRounds} element={<ExecutiveSession />} />
    <Route path={FormRoutes.keyFindings} element={<ASReport />} />
    <Route path={FormRoutes.finalReport} element={<FinalReport />} />
  </Routes>
);

export default Forms;
