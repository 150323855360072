import update from 'immutability-helper';
import {
  GET_APPLICATION,
  GET_APPLICATION_SUCCESS,
  GET_APPLICATION_ERROR,
  UPDATE_APPLICATION,
} from '../actions/applications';

const initialState = {
  isLoading: true,
  data: {},
};

export default function currentApplication(state = initialState, action) {
  switch (action.type) {
    case GET_APPLICATION:
      return initialState;
    case GET_APPLICATION_SUCCESS:
      return { isLoading: false, data: action.data };
    case GET_APPLICATION_ERROR:
      return { isLoading: false, data: {} };
    case UPDATE_APPLICATION:
      return update(state, { data: { $merge: action.data } });
    default:
      return state;
  }
}
