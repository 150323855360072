import React from 'react';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { Route, Routes } from 'react-router-dom';

import { AppContextProvider } from 'context/AppContext';
import useUser from 'hooks/useUser';
import { MainRoutes } from 'helpers/routes';

import AppHeader from 'components/layout/AppHeader';
import AppContainer from 'components/layout/AppContainer';
import UnauthorizedPage from 'components/layout/UnauthorizedPage';
import Snackbar from 'components/elements/Snackbar';

import VerificationPage from 'pages/verification';
import ReportsPage from 'pages/reports';
import UploadPage from 'pages/uploads';
import TrainingPage from 'pages/training';
import HomePage from 'pages/HomePage';

const AppRoutes = () => {
  const { hasVerification, hasReports } = useUser();

  return (
    <AppContextProvider>
      <AppContainer>
        <AppHeader />
        <div style={{ display: 'flex', flexGrow: 1 }}>
          <Routes>
            <Route index element={<HomePage />} />
            {hasVerification && (
              <Route
                path={`${MainRoutes.VERIFICATION}/*`}
                element={<VerificationPage />}
              />
            )}
            {hasReports && (
              <Route
                path={`${MainRoutes.REPORTS}/*`}
                element={<ReportsPage />}
              />
            )}
            <Route path={`${MainRoutes.UPLOAD}/*`} element={<UploadPage />} />
            <Route
              path={`${MainRoutes.TRAINING}/*`}
              element={<TrainingPage />}
            />
            <Route path="*" element={<UnauthorizedPage />} />
          </Routes>
        </div>
        <Snackbar />
      </AppContainer>
    </AppContextProvider>
  );
};

export default withAuthenticationRequired(AppRoutes);
