import React from 'react';

import { StylesManager, Survey, Model } from 'survey-react';

import api from 'api';
import useUser from 'hooks/useUser';
import useFeedback from 'hooks/useFeedback';
import useGoBack from 'hooks/useGoBack';
import useCurrentApplication from 'hooks/useCurrentApplication';
import { formType, formTitle } from 'helpers/forms';
import { surveyJSTheme } from 'helpers/surveyJs';

import { GoBackButton } from 'components/elements/BackButton';
import SectionWrapper from 'components/layout/SectionWrapper';
import Modal from 'components/elements/BasicModal';
import Loader from 'components/elements/Loader';

StylesManager.applyTheme(surveyJSTheme);

const InitialApplication = () => {
  const { goBack } = useGoBack();
  const [model, setModel] = React.useState(null);
  const [verifyData, setVerifyData] = React.useState(null);
  const { setSuccess, setError } = useFeedback();

  const { appData } = useCurrentApplication();
  const { isAdmin } = useUser();

  React.useEffect(() => {
    api.getSurveyVersion(appData.applicationVersionId).then(({ data }) => {
      api
        .getFormResults({
          appId: appData.id,
          formType: formType.Application,
        })
        .then(({ data: { data: formData } }) => {
          const newModel = new Model(data.model);
          newModel.data = formData;
          newModel.mode = isAdmin ? 'edit' : 'display';
          setModel(newModel);
        });
    });
  }, [appData, isAdmin]);

  const handleComplete = (data) => {
    api
      .saveFormResults({
        appId: appData.id,
        formType: formType.Application,
        data,
      })
      .then(() => {
        setSuccess('Form updated successfully');
        goBack();
      })
      .catch(() => {
        setError('Error updating form');
      });
  };

  if (!model) return <Loader />;

  return (
    <SectionWrapper
      title={`${appData.bcqpBurnCenterName} - ${formTitle.initialApplication}`}
    >
      <div>
        <GoBackButton onClick={() => goBack()} />
      </div>
      {verifyData && (
        <Modal
          title="Are you sure you would like to create a new verification process for your site?"
          body="You currently have an in-progress verification for your site. That data will be lost."
          isOpen
          onClose={() => {
            setVerifyData(null);
          }}
          onConfirm={() => {
            handleComplete(verifyData);
            setVerifyData(null);
          }}
        />
      )}
      <div>
        <Survey
          model={model}
          showCompletedPage={false}
          onComplete={({ data }) => setVerifyData(data)}
          loadingHtml="<h2>Loading Application</h2>"
        />
      </div>
    </SectionWrapper>
  );
};

export default InitialApplication;
