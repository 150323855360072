import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';

import styled from '@emotion/styled';

import SVButton from 'components/elements/SVButton';
import { FlexDivSpaceBetween } from 'resources/styles/display';
import { AppAdminRoutes } from 'helpers/routes';

import AppDetails from './AppDetails';
import AppDeleteButton from './AppDeleteButton';

const menuItems = [
  { label: 'Forms', path: AppAdminRoutes.FORMS },
  { label: 'User Management', path: AppAdminRoutes.USERS },
  { label: 'Site Visit Date', path: AppAdminRoutes.SITE_VISIT },
  { label: 'Document Upload', path: AppAdminRoutes.DOCS },
  { label: 'Final Determination', path: AppAdminRoutes.DETERMINATION },
];

export const MenuButton = styled(SVButton)`
  background: var(--bs-gray-500);
  border-color: var(--bs-gray-500);
  color: black;
  &.active-tab {
    color: white;
    background: var(--bs-primary);
    border-color: var(--bs-primary);
  }
`;

const ApplicationPage = () => {
  const { pathname } = useLocation();
  const rootPath = pathname.substring(0, pathname.lastIndexOf('/'));

  return (
    <>
      <FlexDivSpaceBetween>
        <AppDetails />
        <AppDeleteButton />
      </FlexDivSpaceBetween>
      <div className="mt-4">
        {menuItems.map(({ label, path }) => (
          <MenuButton
            key={path}
            className={({ isActive }) => (isActive ? 'active-tab' : undefined)}
            as={NavLink}
            to={`${rootPath}/${path}`}
            replace
          >
            {label}
          </MenuButton>
        ))}
      </div>
    </>
  );
};

export default ApplicationPage;
