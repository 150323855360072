import { programType } from 'helpers/forms';

const burncenterAppendixQuestions = [
  '1.3.d.',
  '2.1.a.',
  '2.2.b.',
  '2.3.b.',
  '3.1.c.',
  '3.2.b.',
  '3.10.c.',
  '3.11.a.',
  '3.12.a.',
  '3.13.b.2.',
  '3.14.b.2.',
  '3.2.c.2.',
  '4.1.b.',
  '4.2.b.',
  '4.3.c.2.',
  '4.4.b.',
  '4.5.b.',
  '4.9.b.',
  '4.10.b.',
  '4.11.b.',
  '5.1.b.',
  '5.1.c.',
  '6.2.b.',
  '6.3.b.',
  '6.7.b.',
  '6.8.b.',
  '6.9.b.1.',
  '7.2.c.',
  '7.2.d.',
  '7.6.c.',
  '7.8.b.',
  '7.9.a.',
  '8.1.g.',
  '8.2.f.',
  '8.3.f.',
  '8.4.e.',
  '8.5.e.',
  '8.6.d.',
  '8.7.d.',
  '8.8.e.',
  '8.9.b.',
  '8.10.d.',
  '9.6.b.',
  '9.7.e.1.',
  '12.1.b.',
  '12.4.c.',
  '13.3.d.',
  '15.1.f.',
  '18.2.d.',
];

export const burncenterMatrixDropdown = [
  '21.1.',
  '21.11.',
  '21.13.',
  '21.2.',
  '3.2.c.2.',
];

export const burncenterMatrixDynamic = [
  '21.3.',
  '21.5.',
  '21.6.',
  '21.7.',
  '21.8.',
  '21.9.',
  '21.10.',
  '21.12.',
];

const fellowshipAppendixQuestions = [
  '1.3.b.2.',
  '1.4.b.',
  '1.5.b.',
  '1.7.f.',
  '7.1.a.2.',
];

const fellowshipMatrixDinamic = ['8.2.1.', '8.2.2.', '8.2.4.'];

const fellowshipMatrixDropdown = ['8.', '8.2.5.'];

export const getTableOverrider = (appData) => {
  if (appData.programTypeId === programType.BURNCENTER) {
    return {
      matrixDropdown2Table: burncenterMatrixDropdown,
      matrixDynamic2Table: burncenterMatrixDynamic,
      appendixQuestions: burncenterAppendixQuestions,
    };
  }
  return {
    matrixDropdown2Table: fellowshipMatrixDropdown,
    matrixDynamic2Table: fellowshipMatrixDinamic,
    appendixQuestions: fellowshipAppendixQuestions,
  };
};
