import styled from '@emotion/styled';

import colors from 'resources/styles/colors';

export const AdminInfo = styled.div`
  width: 250px;
  border: 1px solid ${colors.gray};
  a {
    color: ${colors.secondaryColor};
  }
`;

export const accordionHeaderStyle = {
  textDecoration: 'none',
  fontWeight: 800,
  color: colors.darkGray,
  background: 'transparent',
  padding: 0,
  borderRadius: 0,
};

export const accordionSelectedStyle = {
  ...accordionHeaderStyle,
  background: 'var(--bs-accordion-active-bg)',
};

export const MenuItem = styled.div`
  margin-bottom: 1.5rem;
`;

export const MenuItemTitle = styled.div`
  font-size: 1.2rem;
  color: ${colors.secondaryColor};
`;

export const MenuItemBody = styled.div`
  color: ${colors.darkGray};
`;

export const activeStyle = {
  color: colors.primaryColor,
};
