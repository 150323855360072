import React from 'react';
import PropTypes from 'prop-types';

import useCurrentApplication from 'hooks/useCurrentApplication';

import SectionWrapper from 'components/layout/SectionWrapper';
import ApplicationPage from './ApplicationPage';

const Container = ({ children }) => {
  const {
    appData: { bcqpBurnCenterName },
  } = useCurrentApplication();

  return (
    <SectionWrapper title={bcqpBurnCenterName}>
      <ApplicationPage />
      <div className="mt-4">{children}</div>
    </SectionWrapper>
  );
};

Container.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Container;
