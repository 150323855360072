export const ContentType = {
  VIDEO: 'video',
  PDF: 'pdf',
  TEXT: 'text',
};

export const getContentType = (url) => {
  if (url?.endsWith('mp4')) return ContentType.VIDEO;
  if (url?.endsWith('pdf')) return ContentType.PDF;
  return ContentType.TEXT;
};
