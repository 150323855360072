import React from 'react';

import { Survey, Model } from 'survey-react';
import Select from 'react-select';

import Loader from 'components/elements/Loader';
import Modal from 'components/elements/BasicModal';
import { HistoryBackButton } from 'components/elements/BackButton';
import SectionWrapper from 'components/layout/SectionWrapper';

import api from 'api';
import useRequest from 'hooks/useRequest';
import useUser from 'hooks/useUser';
import useFeedback from 'hooks/useFeedback';
import useGoBack from 'hooks/useGoBack';
import { burncenterLabel } from 'helpers/forms';
import { MAX_PAGE_SIZE } from 'utils/constants';

const ApplicationAdd = () => {
  const [model, setModel] = React.useState(null);
  const [burncenter, setBurncenter] = React.useState(null);
  const [verifyData, setVerifyData] = React.useState(null);
  const { appUser, isAdmin } = useUser();
  const { setError } = useFeedback();
  const { goBack } = useGoBack();

  const { doRequest: getBurncenters, data: burncenterData } = useRequest(
    api.getBurncenters,
  );

  React.useEffect(() => {
    if (!isAdmin) return;
    getBurncenters({ limit: MAX_PAGE_SIZE });
  }, [isAdmin, getBurncenters]);

  React.useEffect(() => {
    api
      .getSurveyVersions({
        surveyId: 1,
        order: 'version',
        orderDirection: 'desc',
        limit: 1,
      })
      .then(({ data: { items } }) => {
        const lastVersion = items[0];
        if (!lastVersion) return;
        const newModel = new Model(lastVersion.model);
        setModel(newModel);
      });
  }, []);

  const burncenterOptions = React.useMemo(
    () => burncenterData?.items?.map(({ id, ...rest }) => ({
      value: id,
      label: burncenterLabel(rest),
    })) ?? [],
    [burncenterData],
  );

  const handleComplete = (data) => {
    api
      .addApplication({
        bcqpBurnCenterId: isAdmin ? burncenter : appUser.belongsTo,
        data,
      })
      .then(() => goBack())
      .catch(({ response }) => setError(response.data.detail));
  };

  if (!model) return <Loader />;

  return (
    <SectionWrapper title="New Verification">
      <div>
        <HistoryBackButton />
      </div>
      {verifyData && (
        <Modal
          title="Would you like to start the verification process?"
          body="Changes to verification type will require you to re-enter this information."
          isOpen
          onClose={() => setVerifyData(null)}
          onConfirm={() => {
            handleComplete(verifyData);
            setVerifyData(null);
          }}
        />
      )}
      {isAdmin && (
        <div style={{ marginTop: '1rem' }}>
          <h4>Burn Center</h4>
          <Select
            placeholder="Please select a Burn Center"
            onChange={({ value }) => setBurncenter(value)}
            options={burncenterOptions}
          />
        </div>
      )}
      {(!isAdmin || burncenter) && (
        <Survey
          model={model}
          onComplete={({ data }) => setVerifyData(data)}
          showCompletedPage={false}
          loadingHtml="<h2>Loading Application</h2>"
          onAfterRenderQuestion={(_, { question }) => {
            if (
              question.getType() === 'radiogroup'
              && !question.value
              && model.data[question.name]
            ) {
              // eslint-disable-next-line no-param-reassign
              question.value = model.data[question.name];
            }
          }}
        />
      )}
    </SectionWrapper>
  );
};

export default ApplicationAdd;
