import React from 'react';
import { Auth0Provider } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';

import App from './App';

const {
  REACT_APP_AUTH0_DOMAIN,
  REACT_APP_AUTH0_CLIENT_ID,
  REACT_APP_AUTH0_AUDIENCE,
} = process.env;

const AuthProvider = () => {
  const navigate = useNavigate();

  const onRedirectCallback = React.useCallback(
    (appState) => {
      if (!appState?.returnTo) return;
      navigate(appState.returnTo);
    },
    [navigate],
  );

  return (
    <Auth0Provider
      domain={REACT_APP_AUTH0_DOMAIN}
      clientId={REACT_APP_AUTH0_CLIENT_ID}
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience: REACT_APP_AUTH0_AUDIENCE,
        scope: 'read:current_user',
      }}
      onRedirectCallback={onRedirectCallback}
    >
      <App />
    </Auth0Provider>
  );
};

export default AuthProvider;
