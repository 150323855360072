import React from 'react';
import { Routes, Route } from 'react-router-dom';

import SurveyEditor from './SurveyEditor';
import SurveyTable from './SurveyTable';

const SurveyManagement = () => (
  <Routes>
    <Route exact path="/" element={<SurveyTable />} />
    <Route path=":surveyId" element={<SurveyEditor />} />
  </Routes>
);

export default SurveyManagement;
