import React from 'react';
import { bindActionCreators } from 'redux';
import { useDispatch, useSelector } from 'react-redux';

import { fetchReports } from 'store/reports/actions';

const useReports = () => {
  const dispatch = useDispatch();
  const {
    isLoading,
    data,
    error,
  } = useSelector((state) => state.reports);

  const actions = React.useMemo(
    () => bindActionCreators(
      {
        fetchReports,
      },
      dispatch,
    ),
    [dispatch],
  );

  return {
    ...actions,
    isLoading,
    data,
    error,
  };
};

export default useReports;
