import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Accordion, Container } from 'react-bootstrap';
import uniq from 'lodash/uniq';

import SVButton from 'components/elements/SVButton';

import useMountEffect from 'hooks/useMountEffect';
import useCurrentApplication from 'hooks/useCurrentApplication';
import useChat from 'hooks/useChat';
import { getFlags } from 'store/actions/flags';
import {
  formType as FormTypes,
  prqNavigation,
  meetingsAndRoundsNavigation,
  keyFindingsNavigation,
} from 'helpers/forms';

import CriteriaSection from './components/CriteriaSection';

const getSurveySections = ({ formType, appData }) => {
  switch (formType) {
    case FormTypes.PRQ:
      return prqNavigation(appData);
    case FormTypes.MeetingsAndRounds:
      return meetingsAndRoundsNavigation;
    case FormTypes.KeyFindings:
      return keyFindingsNavigation(appData);
    default:
      return [];
  }
};

const SurveyNavigation = ({
  surveyVersionId,
  model,
  formType,
  onPageChanged,
  onSubmit,
}) => {
  const dispatch = useDispatch();
  const {
    appData, userTypeId, isReviewer, isVerification,
  } = useCurrentApplication();
  const { getCommentCount } = useChat();

  const [activeSectionKey, setActiveSectionKey] = React.useState([0]);
  const { pages, currentPage } = model;

  useMountEffect(() => {
    if (!userTypeId) return;
    getCommentCount(appData.id, surveyVersionId, userTypeId);
    dispatch(getFlags(appData.id, surveyVersionId, userTypeId));
  });

  React.useEffect(() => {
    setActiveSectionKey(Number(currentPage.name.split('_')[0]));
  }, [currentPage]);

  const canSubmit = React.useMemo(() => {
    if (appData.statusId != null) return false;
    switch (formType) {
      case FormTypes.KeyFindings:
        return isReviewer && !appData.keyFindingsSubmittedAt;
      case FormTypes.MeetingsAndRounds: {
        if (isVerification) return !appData.meetingsAndRoundsSubmittedAt;
        if (isReviewer) return !appData.meetingsAndRoundsSrSubmittedAt;
        return false;
      }
      case FormTypes.PRQ: {
        if (isReviewer) return !appData.prqSrSubmittedAt;
        if (isVerification) return !appData.prqSubmittedAt;
        return false;
      }
      default:
        return false;
    }
  }, [appData, formType, isReviewer, isVerification]);

  const surveySections = React.useMemo(() => {
    const sections = getSurveySections({ formType, appData }).map(
      (label, id) => ({ id, label }),
    );

    const pageIds = uniq(pages.map(({ name }) => Number(name.split('_')[0])));

    return sections.filter(({ id }) => pageIds.includes(id));
  }, [formType, appData, pages]);

  return (
    <div style={{ minWidth: '300px', maxWidth: '300px' }}>
      <Accordion activeKey={activeSectionKey}>
        {surveySections.map(({ id, label }) => (
          <Accordion.Item eventKey={id} key={id}>
            <Accordion.Header onClick={() => setActiveSectionKey(id)}>
              <div style={{ fontWeight: '700', color: 'black' }}>{label}</div>
            </Accordion.Header>
            <Accordion.Collapse eventKey={id}>
              <CriteriaSection
                sectionId={id}
                model={model}
                onPageChanged={onPageChanged}
              />
            </Accordion.Collapse>
          </Accordion.Item>
        ))}
      </Accordion>
      {canSubmit && (
        <Container className="pt-3" align="center">
          <SVButton size="sm" onClick={onSubmit}>
            Final Submit
          </SVButton>
        </Container>
      )}
    </div>
  );
};

SurveyNavigation.propTypes = {
  surveyVersionId: PropTypes.number.isRequired,
  model: PropTypes.shape({
    currentPage: PropTypes.shape({
      name: PropTypes.string,
    }),
    pages: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  formType: PropTypes.string.isRequired,
  onPageChanged: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
};

export default SurveyNavigation;
