import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import useUser from 'hooks/useUser';
import { ReportRoutes } from 'helpers/routes';

import UnauthorizedPage from 'components/layout/UnauthorizedPage';

import ReportManagePage from './manage/ReportManagePage';
import ReportViewPage from './view/ReportViewPage';

const ReportsPage = () => {
  const { isAdmin } = useUser();

  const defaultRoute = React.useMemo(
    () => (isAdmin ? ReportRoutes.MANAGE : ReportRoutes.VIEW),
    [isAdmin],
  );

  return (
    <Routes>
      {isAdmin && (
        <Route
          path={`${ReportRoutes.MANAGE}/*`}
          element={<ReportManagePage />}
        />
      )}
      <Route path={`${ReportRoutes.VIEW}/*`} element={<ReportViewPage />} />
      <Route index element={<Navigate replace to={defaultRoute} />} />
      <Route path="*" element={<UnauthorizedPage />} />
    </Routes>
  );
};

export default ReportsPage;
