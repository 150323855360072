import React from 'react';
import { Route, Routes } from 'react-router-dom';

import UnauthorizedPage from 'components/layout/UnauthorizedPage';

import VerificationList from './VerificationList';
import Verification from './Verification';

const Verifications = () => (
  <Routes>
    <Route index element={<VerificationList />} />
    <Route path=":role/:appId/*" exact element={<Verification />} />
    <Route path="*" exact element={<UnauthorizedPage />} />
  </Routes>
);

export default Verifications;
