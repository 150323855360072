import axios from 'axios';

import { getAppToken } from 'store/auth/actions';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_HOST_URL,
  timeout: 60000,
});

const Endpoints = {
  DASHBOARDS: 'dashboards',
  UPLOADS: 'data-uploads',
  CONTENTS: 'contents',
};

// Add access token to every API request
api.interceptors.request.use(async (config) => {
  try {
    return {
      ...config,
      headers: {
        ...config.headers,
        Authorization: `Bearer ${getAppToken()}`,
        Pragma: 'no-cache',
      },
    };
  } catch (_e) {
    // If the session is invalid, do not add an Authorization header
    return config;
  }
});

const apiConfig = {
  addApplication({ bcqpBurnCenterId, data }) {
    return api.post('applications', { bcqpBurnCenterId, data });
  },
  getApplication(appId) {
    return api.get(`applications/${appId}`);
  },
  deleteApplication(appId) {
    return api.delete(`applications/${appId}`);
  },
  setFinalDetermination({ appId, determination }) {
    return api.put(`applications/${appId}/final_determination`, {
      determination,
    });
  },
  setExpirationDate({ appId, expiredAt }) {
    return api.put(`applications/${appId}/expiration_date`, { expiredAt });
  },
  setSiteVisitDate({ appId, siteVisitAt }) {
    return api.put(`applications/${appId}/site_visit_date`, { siteVisitAt });
  },
  getSurveyVersions(params) {
    return api.get('surveyversions', { params });
  },
  getSurveyVersion(versionId) {
    return api.get(`surveyversions/${versionId}`);
  },
  addSurveyVersion({ surveyId, model }) {
    return api.post(`surveys/${surveyId}/versions`, { model });
  },
  updateSurveyVersion({ versionId, model }) {
    return api.put(`surveyversions/${versionId}`, { model });
  },
  getSurvey(surveyId) {
    return api.get(`surveys/${surveyId}`);
  },
  getSurveys(params) {
    return api.get('surveys', { params });
  },
  addSurvey(surveyObj) {
    return api.post('surveys', surveyObj);
  },
  deleteSurvey(surveyId) {
    return api.delete(`surveys/${surveyId}`);
  },
  updateSurveyName({ surveyId, name }) {
    return api.put(`surveys/${surveyId}`, { name });
  },
  getFormResults({ appId, formType, pageIndex }) {
    return api.get(`applications/${appId}/data/${formType}`, {
      params: {
        pageIndex: pageIndex ?? null,
      },
    });
  },
  saveFormResults({
    appId, formType, data, pageIndex, overwrite,
  }) {
    return api.put(`applications/${appId}/data/${formType}`, {
      data,
      pageIndex,
      overwrite,
    });
  },
  getFinalReport(appId) {
    return api.get(`reports/final_report?applicationId=${appId}`);
  },
  downloadFinalReport(applicationId) {
    return api.post(
      'reports/download',
      { applicationId },
      { responseType: 'blob' },
    );
  },
  getReport({
    applicationId, surveyVersionId, userTypeId, includeChat,
  }) {
    return api.get('reports', {
      params: {
        applicationId,
        surveyVersionId,
        userTypeId,
        includeChat,
      },
    });
  },
  submitPRQReview(appId) {
    return api.put(`applications/${appId}/prq/submit_sr`);
  },
  submitPRQ(appId) {
    return api.put(`prq_results/${appId}/submit`);
  },
  submitARS(appId) {
    return api.put(`applications/${appId}/asr/submit`);
  },
  submitES(appId) {
    return api.put(`applications/${appId}/es/submit_vu`);
  },
  submitESReview(appId) {
    return api.put(`applications/${appId}/es/submit_sr`);
  },
  getAssignments(params) {
    return api.get('applications/my_assignments', { params });
  },
  getAdminOverview(params) {
    return api.get('applications/admin_overview', {
      params,
      paramsSerializer: {
        indexes: null,
      },
    });
  },
  getAdminOverviewDownload(params) {
    const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
    return api.post('applications/admin_overview/download', {
      ...params,
      timezone: timeZone,
    });
  },
  getSiteReviewers(appId) {
    return api.get(`applications/${appId}/site_reviewers`);
  },
  addSiteReviewer({ appId, userId }) {
    return api.put(`applications/${appId}/site_reviewers`, { userId });
  },
  removeSiteReviewer({ appId, userId }) {
    return api.delete(`applications/${appId}/site_reviewers/${userId}`);
  },
  getUser(userId) {
    return api.get(`bcqpusers/${userId}`);
  },
  getUsers(params) {
    return api.get('bcqpusers', { params });
  },
  getBurncenter(id) {
    return api.get(`bcqpburncenters/${id}`);
  },
  getBurncenters(params) {
    return api.get('bcqpburncenters', { params });
  },
  getCommentCount(params) {
    return api.get('comments/count', { params });
  },
  getComments(params) {
    return api.get('comments', { params });
  },
  updateComment({ commentId, message }) {
    return api.put(`comments/${commentId}`, { message });
  },
  addComment({ appId, data }) {
    return api.post(`applications/${appId}/question_comments`, data);
  },
  addReviewerComment({ appId, data }) {
    return api.post(`applications/${appId}/criteria_comments`, data);
  },
  getFlags(params) {
    return api.get('flags', { params });
  },
  deleteFlag(flagId) {
    return api.delete(`flags/${flagId}`);
  },
  updateFlag({ appId, data }) {
    return api.post(`applications/${appId}/flags`, data);
  },
  uploadPRQFile({ appId, file }) {
    const formData = new FormData();
    formData.append('file', file);
    return api.post(`applications/${appId}/files_prq`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
  uploadESFile({ appId, file }) {
    const formData = new FormData();
    formData.append('file', file);
    return api.post(`applications/${appId}/files_es`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
  getFile(id) {
    return api.get(`files/${id}`, { responseType: 'blob' });
  },
  getAppFiles({ appId, params }) {
    return api.get(`applications/${appId}/files`, { params });
  },
  deleteFile(fileId) {
    return api.delete(`files/${fileId}`);
  },
  uploadAppFile({ appId, file }) {
    const formData = new FormData();
    formData.append('file', file);
    return api.post(`applications/${appId}/files`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
  getCalendar() {
    return api.get('calendar');
  },
  getDashboards(params) {
    return api.get(Endpoints.DASHBOARDS, { params });
  },
  getDashboard(id, params) {
    return api.get(`${Endpoints.DASHBOARDS}/${id}`, { params });
  },
  addDashboard(data) {
    return api.post(Endpoints.DASHBOARDS, data);
  },
  updateDashboard({ id, ...updateData }) {
    return api.put(`${Endpoints.DASHBOARDS}/${id}`, { ...updateData, id });
  },
  deleteDashboard(id) {
    return api.delete(`${Endpoints.DASHBOARDS}/${id}`);
  },

  /* UPLOADS */
  getUploads(params) {
    return api.get(Endpoints.UPLOADS, { params });
  },
  postUpload(params) {
    return api.post(Endpoints.UPLOADS, params);
  },
  getUpload(id) {
    return api.get(`${Endpoints.UPLOADS}/${id}`);
  },
  removeUpload(id) {
    return api.delete(`${Endpoints.UPLOADS}/${id}`);
  },

  /* CONTENT */
  getContents(params) {
    return api.get(Endpoints.CONTENTS, { params });
  },
  getContent(id) {
    return api.get(`${Endpoints.CONTENTS}/${id}`);
  },
  addContent(params) {
    return api.post(Endpoints.CONTENTS, params);
  },
  updateContent({ id, ...params }) {
    return api.put(`${Endpoints.CONTENTS}/${id}`, params);
  },
  deleteContent(id) {
    return api.delete(`${Endpoints.CONTENTS}/${id}`);
  },

  /* TinyMCE */
  getTinyMCEToken() {
    return api.get('tiny-mce/token');
  },
};

export default apiConfig;
