import React from 'react';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';

import { Stack, Table as BSTable } from 'react-bootstrap';
import { BsChevronDown, BsChevronUp } from 'react-icons/bs';

import Loader from 'components/elements/Loader';
import Pagination from 'components/elements/Pagination';
import { FlexCenter } from 'resources/styles/display';

const Table = ({
  columns,
  items,
  order,
  orderDirection,
  pagination,
  handlePageChange,
  handleOrderChange,
  onClickRow,
  isLoading,
}) => (
  <div style={{ position: 'relative', minHeight: '200px' }}>
    {isLoading && (
      <FlexCenter
        style={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          background: 'rgba(0, 0, 0, 0.4)',
        }}
      >
        <Loader />
      </FlexCenter>
    )}
    <BSTable striped hover={!!onClickRow}>
      <thead className="border-top">
        <tr>
          {columns.map(({
            id, label, nonSortable, headerStyle = {},
          }) => (
            <th
              key={uuid()}
              onClick={() => !nonSortable && handleOrderChange?.(id)}
              style={{ ...headerStyle, cursor: nonSortable ? '' : 'pointer' }}
            >
              <Stack direction="horizontal" gap={2}>
                {label}
                {order === id
                  && (orderDirection === 'asc' ? (
                    <BsChevronDown />
                  ) : (
                    <BsChevronUp />
                  ))}
              </Stack>
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {items?.map((item) => (
          <tr
            key={uuid()}
            onClick={() => onClickRow?.(item)}
            style={{ cursor: onClickRow ? 'pointer' : '' }}
          >
            {columns.map(({ id, override, columnStyle = {} }) => (
              <td style={columnStyle} key={uuid()}>
                {override ? override(item) : item[id]}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </BSTable>
    {pagination && (
      <Pagination
        total={pagination.total}
        limit={pagination.limit}
        page={pagination.page}
        onPageChange={handlePageChange}
      />
    )}
  </div>
);

Table.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  order: PropTypes.string,
  orderDirection: PropTypes.string,
  pagination: PropTypes.shape({
    page: PropTypes.number,
    total: PropTypes.number,
    limit: PropTypes.number,
  }),
  handlePageChange: PropTypes.func,
  handleOrderChange: PropTypes.func,
  onClickRow: PropTypes.func,
  isLoading: PropTypes.bool,
};

export default Table;
