import React from 'react';
import { Route, Routes } from 'react-router-dom';

import ApplicationAdd from 'components/forms/ApplicationAdd';

import VerificationList from './VerificationList';
import VerificationDetails from './details';
import SurveyManagment from './surveys';

const AdminHome = () => (
  <Routes>
    <Route exact path="/" element={<VerificationList />} />
    <Route exact path="new" element={<ApplicationAdd />} />
    <Route exact path="surveys/*" element={<SurveyManagment />} />
    <Route path=":appId/*" element={<VerificationDetails />} />
  </Routes>
);

export default AdminHome;
