import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { Stack } from 'react-bootstrap';
import { BsChevronLeft } from 'react-icons/bs';

import useGoBack from 'hooks/useGoBack';

import SVButton from 'components/elements/SVButton';

export const GoBackButton = ({ children = 'GO BACK', ...props }) => (
  <SVButton {...props}>
    <Stack direction="horizontal" gap={2}>
      <BsChevronLeft />
      <span>{children}</span>
    </Stack>
  </SVButton>
);

GoBackButton.propTypes = {
  children: PropTypes.node,
};

export const HistoryBackButton = (props) => {
  const { goBack } = useGoBack();
  return <GoBackButton {...props} onClick={() => goBack()} />;
};

export const LinkBackButton = ({ to = '..', replace = true, ...props }) => (
  <GoBackButton {...props} as={Link} to={to} replace={replace} />
);

LinkBackButton.propTypes = {
  to: PropTypes.string,
  replace: PropTypes.bool,
};
