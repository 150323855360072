import React from 'react';
import PropTypes from 'prop-types';

function FontAwesomeIcon({ className }) {
  return <i className={`fa ${className}`} aria-hidden="true" />;
}

FontAwesomeIcon.propTypes = {
  className: PropTypes.string.isRequired,
};

export default FontAwesomeIcon;
