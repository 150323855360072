import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Provider } from 'react-redux';

import store from './components/store';

import * as serviceWorkerRegistration from './serviceWorkerRegistration';

import AuthProvider from './AuthProvider';

const root = ReactDOM.createRoot(document.getElementById('root'));

// TODO: use React.StrictMode
root.render(
  <Provider store={store}>
    <BrowserRouter>
      <Routes>
        <Route path="*" element={<AuthProvider />} />
      </Routes>
    </BrowserRouter>
  </Provider>,
);

// https://bit.ly/CRA-PWA
serviceWorkerRegistration.unregister();
