import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import sortBy from 'lodash/sortBy';

import { Spinner, Tabs, Tab } from 'react-bootstrap';

import useMountEffect from 'hooks/useMountEffect';
import useUpdateEffect from 'hooks/useUpdateEffect';
import { fetchContents } from 'store/contents/actions';

import TrainingTab from './TrainingTab';

const TrainingTabs = ({
  tabs, currentTab, onTabChange, search,
}) => {
  const dispatch = useDispatch();
  const { state } = useLocation();
  const { isLoading, data } = useSelector((redux) => redux.contents);

  const fetchData = React.useCallback(() => {
    dispatch(fetchContents({ search }));
  }, [dispatch, search]);

  useMountEffect(() => {
    if (data.length && !state?.forceRefresh) return;
    fetchData();
  });

  useUpdateEffect(() => {
    fetchData();
  }, [fetchData]);

  const contents = React.useMemo(
    () => sortBy(data, [(o) => o.title.toLowerCase()]),
    [data],
  );

  return (
    <div className="position-relative">
      <Tabs id="training-tabs" activeKey={currentTab} onSelect={onTabChange}>
        {Object.entries(tabs).map(([catId, title]) => (
          <Tab key={catId} eventKey={catId} title={title} mountOnEnter>
            <TrainingTab catId={catId} contents={contents} />
          </Tab>
        ))}
      </Tabs>
      {isLoading && (
        <div
          className="position-absolute top-0 h-100 w-100 d-flex justify-content-center align-items-center rounded"
          style={{ background: 'rgba(0, 0, 0, 0.1)' }}
        >
          <Spinner animation="border" variant="primary" />
        </div>
      )}
    </div>
  );
};

TrainingTabs.propTypes = {
  tabs: PropTypes.shape({}),
  currentTab: PropTypes.string,
  onTabChange: PropTypes.func,
  search: PropTypes.string,
};

export default TrainingTabs;
