import React from 'react';
import PropTypes from 'prop-types';

import ReactSelect from 'react-select';

const Select = ({
  value, options, onChange, isMulti, ...props
}) => {
  const flatOptions = React.useMemo(
    () => options.reduce(
      (res, { value: key, label }) => ({ ...res, [key]: label }),
      {},
    ),
    [options],
  );

  const selectedValue = React.useMemo(() => {
    if (!value) return null;
    return isMulti
      ? value.map((key) => ({ value: key, label: flatOptions[key] }))
      : { value, label: flatOptions[value] };
  }, [isMulti, value, flatOptions]);

  const handleChange = React.useCallback(
    (newValue) => {
      if (isMulti) {
        onChange(newValue.map(({ value: optValue }) => optValue));
        return;
      }
      onChange(newValue?.value || null);
    },
    [isMulti, onChange],
  );

  return (
    <ReactSelect
      {...props}
      isMulti={isMulti}
      value={selectedValue}
      options={options}
      onChange={handleChange}
    />
  );
};

Select.propTypes = {
  value: PropTypes.node,
  isMulti: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onChange: PropTypes.func,
};

export default Select;
