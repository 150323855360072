import api from 'api';

export const GET_REPORTS = 'GET_REPORTS';
export const GET_REPORTS_SUCCESS = 'GET_REPORTS_SUCCESS';
export const GET_REPORTS_ERROR = 'GET_REPORTS_ERROR';

// eslint-disable-next-line
export const fetchReports =
  ({ impersonateUserId, userRequesting, ...requestData }) => (dispatch) => {
    dispatch({ type: GET_REPORTS });
    api
      .getDashboards({ impersonateUserId, ...requestData })
      .then(({ data }) => {
        dispatch({
          type: GET_REPORTS_SUCCESS,
          data: {
            ...data,
            userId: impersonateUserId || userRequesting,
          },
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_REPORTS_ERROR,
          error,
        });
      });
  };
