import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import useUser from 'hooks/useUser';

import UnauthorizedPage from 'components/layout/UnauthorizedPage';

import TrainingHome from './pages/TrainingHome';
import TrainingItemAdd from './pages/TrainingItemAdd';
import TrainingItemEdit from './pages/TrainingItemEdit';
import TrainingItemView from './pages/TrainingItemView';

const TrainingRoutes = () => {
  const { isBdataAmin } = useUser();

  return (
    <Routes>
      <Route index element={<TrainingHome />} />
      {isBdataAmin && (
        <>
          <Route path="add" element={<TrainingItemAdd />} />
          <Route path=":itemId" element={<TrainingItemEdit />} />
        </>
      )}
      <Route path="view">
        <Route index element={<Navigate to=".." replace />} />
        <Route path=":itemId" element={<TrainingItemView />} />
      </Route>
      <Route path="*" element={<UnauthorizedPage />} />
    </Routes>
  );
};

export default TrainingRoutes;
