import api from 'api';
import { setErrorFeedback } from 'store/feedback/actions';

export const GET_FLAGS = 'GET_FLAGS';
export const GET_FLAGS_SUCCESS = 'GET_FLAGS_SUCCESS';
export const GET_FLAGS_ERROR = 'GET_FLAGS_ERROR';
export const SET_FLAG = 'SET_FLAG';
export const UNSET_FLAG = 'UNSET_FLAG';

export function getFlags(applicationId, surveyVersionId, userTypeId) {
  return (dispatch) => {
    dispatch({ type: GET_FLAGS });
    api
      .getFlags({
        applicationId,
        surveyVersionId,
        userTypeId,
      })
      .then(({ data }) => dispatch({ type: GET_FLAGS_SUCCESS, data }))
      .catch((error) => dispatch({ type: GET_FLAGS_ERROR, error }));
  };
}

export function setFlag({
  applicationId,
  surveyVersionId,
  userTypeId,
  questionId,
}) {
  return (dispatch) => {
    api
      .updateFlag({
        appId: applicationId,
        data: {
          surveyVersionId,
          userTypeId,
          questionId,
        },
      })
      .then(({ data }) => dispatch({ type: SET_FLAG, data }))
      .catch(() => dispatch(setErrorFeedback('Error setting flag')));
  };
}

export function deleteFlag(flagId) {
  return (dispatch) => {
    api
      .deleteFlag(flagId)
      .then(() => dispatch({ type: UNSET_FLAG, flagId }))
      .catch(() => dispatch(setErrorFeedback('Error unsetting flag')));
  };
}
