import React from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';

import api from 'api';
import useRequest from 'hooks/useRequest';

import Loader from 'components/elements/Loader';
import SectionWrapper from 'components/layout/SectionWrapper';

import TrainingItemForm from '../elements/TrainingItemForm';

const TrainingItemEdit = () => {
  const { itemId } = useParams();
  const { state } = useLocation();
  const navigate = useNavigate();
  const { data, doRequest } = useRequest(api.getContent);

  const [forceRefresh, setForceRefresh] = React.useState(!!state?.forceRefresh);

  const onSubmit = async (values) => {
    const { data: updatedData } = await api.updateContent({
      ...values,
      id: itemId,
    });
    setForceRefresh(true);
    return updatedData;
  };

  const onDelete = async () => {
    await api.deleteContent(itemId);
    navigate('..', { replace: true, state: { forceRefresh: true } });
  };

  React.useEffect(() => {
    doRequest(itemId);
  }, [doRequest, itemId]);

  return (
    <SectionWrapper title="Edit Item">
      {data?.id ? (
        <TrainingItemForm
          onSubmit={onSubmit}
          defaultValues={data}
          onDelete={onDelete}
          forceRefresh={forceRefresh}
        />
      ) : (
        <Loader />
      )}
    </SectionWrapper>
  );
};

export default TrainingItemEdit;
