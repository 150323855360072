import React from 'react';
import PropTypes from 'prop-types';
import { Document, Page, pdfjs } from 'react-pdf';

import Loader from 'components/elements/Loader';
import { FlexDiv, FlexColumnCenterAlign } from 'resources/styles/display';

import PDFNavigation from './PDFNavigation';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const FinalReportScreen = ({ pdf }) => {
  const [currentPage, setCurrentPage] = React.useState(1);
  const [totalPages, setTotalPages] = React.useState(0);

  if (!pdf) return <Loader />;

  return (
    <FlexColumnCenterAlign className="mt-4">
      <PDFNavigation
        currentPage={currentPage}
        totalPages={totalPages}
        handlePageChange={setCurrentPage}
      />
      <FlexDiv style={{ minHeight: '850px' }}>
        <Document
          file={{ url: pdf }}
          onLoadSuccess={({ _pdfInfo }) => setTotalPages(_pdfInfo.numPages)}
        >
          <Page
            pageNumber={currentPage}
            renderTextLayer={false}
            renderAnnotationLayer={false}
          />
        </Document>
      </FlexDiv>
    </FlexColumnCenterAlign>
  );
};

FinalReportScreen.propTypes = {
  pdf: PropTypes.string,
};

export default FinalReportScreen;
