import { useMemo } from 'react';
import { bindActionCreators } from 'redux';
import { useDispatch, useSelector } from 'react-redux';

import { getCommentCount, closeQuestionComments } from 'store/actions/comments';

const useChat = () => {
  const dispatch = useDispatch();
  const { currentComments, commentCount } = useSelector((state) => state.comments);

  const actions = useMemo(
    () => bindActionCreators(
      {
        getCommentCount,
        closeQuestionComments,
      },
      dispatch,
    ),
    [dispatch],
  );

  const chatOpen = useMemo(
    () => Boolean(currentComments?.question?.id),
    [currentComments],
  );

  return {
    ...actions,
    chatOpen,
    commentCount: commentCount?.data || [],
  };
};

export default useChat;
