import React from 'react';
import { useDispatch } from 'react-redux';

import { Form, Stack } from 'react-bootstrap';

import Modal from 'components/elements/BasicModal';
import SVButton from 'components/elements/SVButton';

import useCurrentApplication from 'hooks/useCurrentApplication';
import { setFinalDetermination } from 'store/actions/applications';
import { programType, programSubType, getStatusLabel } from 'helpers/forms';
import { formStatus } from 'utils/enums';

const FinalDeterminationForm = () => {
  const dispatch = useDispatch();
  const { appData } = useCurrentApplication();
  const [selectValue, setSelectValue] = React.useState(appData.statusId || '');
  const [edit, setEdit] = React.useState(false);
  const [confirmation, setConfirmation] = React.useState(false);

  const statusOptions = React.useMemo(() => {
    if (appData.programSubtypeId === programSubType.CONSULTATION) {
      return [
        formStatus.RECOMMEND_VERIFICATION,
        formStatus.RECOMMEND_DEFER_VERIFICATION,
      ];
    }
    switch (appData.programTypeId) {
      case programType.FELLOWSHIP:
        return [formStatus.VERIFICATION, formStatus.NON_VERIFICATION];
      case programType.BURNCENTER:
        return [
          formStatus.VERIFICATION,
          formStatus.NON_VERIFICATION,
          formStatus.NON_VERIFICATION_PENDING,
        ];
      default:
        return [];
    }
  }, [appData]);

  return (
    <>
      {confirmation && (
        <Modal
          title="Final Determination"
          body={(
            <>
              <p>
                The Final Determination from a verification application should
                represent the outcome of the verification process.
              </p>
              <p>
                Are you sure you would like to set Final Determination for this
                verification process?
              </p>
            </>
          )}
          isOpen
          onClose={() => setConfirmation(false)}
          onConfirm={() => {
            setConfirmation(false);
            dispatch(
              setFinalDetermination(appData.id, Number(selectValue), () => {
                setEdit(false);
              }),
            );
          }}
        />
      )}
      <div>Final Determination</div>
      <Stack direction="horizontal" gap={2}>
        <Form.Select
          onChange={({ target }) => setSelectValue(target.value)}
          disabled={!edit}
          value={selectValue}
          size="sm"
          style={{ width: '13rem' }}
        >
          <option value="">---</option>
          {statusOptions.map((status) => (
            <option key={status} value={status}>
              {getStatusLabel(status)}
            </option>
          ))}
        </Form.Select>
        {edit ? (
          <Stack direction="horizontal" gap={2}>
            <SVButton
              variant="cancel"
              size="sm"
              onClick={() => {
                setSelectValue(appData.statusId || '');
                setEdit(false);
              }}
            >
              Cancel
            </SVButton>
            <SVButton
              variant="secondary"
              size="sm"
              onClick={() => setConfirmation(true)}
              disabled={!selectValue}
            >
              Save
            </SVButton>
          </Stack>
        ) : (
          <SVButton variant="secondary" size="sm" onClick={() => setEdit(true)}>
            Edit
          </SVButton>
        )}
      </Stack>
    </>
  );
};

export default FinalDeterminationForm;
