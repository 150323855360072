import React, { useState } from 'react';
import ReactDOM from 'react-dom/client';
import PropTypes from 'prop-types';
import { Provider, useDispatch } from 'react-redux';
import filter from 'lodash/filter';

import { Button, Stack } from 'react-bootstrap';
import { FaTimes } from 'react-icons/fa';

import api from 'api';
import { downloadFile, downloadDataFile } from 'store/actions/files';

import store from 'components/store';
import Modal from 'components/elements/BasicModal';

const isDataFile = (fileId) => fileId.startsWith('data');

function DisplayOptions({ values, onFileDelete, isEditMode }) {
  const dispatch = useDispatch();
  const [toDelete, setToDelete] = useState(null);

  const handleFileDownload = (id, fileName) => {
    if (isDataFile(id)) {
      downloadDataFile({ data: id, fileName });
    } else dispatch(downloadFile({ id, fileName }));
  };

  const handleFileDelete = (id) => {
    if (isDataFile(id)) onFileDelete(id);
    else {
      api.deleteFile(id).then(() => onFileDelete(id));
    }
  };

  const handleFileDeleteConfirm = (id, name) => {
    setToDelete({ id, name });
  };

  return (
    <div>
      {toDelete && (
        <Modal
          title={`Are you sure you want to remove "${toDelete.name}"?`}
          isOpen
          onClose={() => setToDelete(null)}
          onConfirm={() => {
            setToDelete(null);
            handleFileDelete(toDelete.id);
          }}
        />
      )}
      <Stack>
        {values.map(({ content, name }) => (
          <div key={name}>
            <Button
              variant="link"
              onClick={() => handleFileDownload(content, name)}
            >
              {name}
            </Button>
            {isEditMode && (
              <Button
                variant="danger"
                onClick={() => handleFileDeleteConfirm(content, name)}
                className="rounded-circle"
                size="sm"
              >
                <FaTimes />
              </Button>
            )}
          </div>
        ))}
      </Stack>
    </div>
  );
}

DisplayOptions.propTypes = {
  values: PropTypes.arrayOf(PropTypes.shape({})),
  onFileDelete: PropTypes.func.isRequired,
  isEditMode: PropTypes.bool.isRequired,
};

const updatePreview = ({
  container, onFileDelete, values, isEditMode,
}) => {
  if (!values) return;
  const root = ReactDOM.createRoot(container);
  root.render(
    <Provider store={store}>
      <DisplayOptions
        values={values}
        onFileDelete={onFileDelete}
        isEditMode={isEditMode}
      />
    </Provider>,
  );
};

export default function surveyFileQuestionOverride({ isEditMode }, options) {
  const onFileDelete = (id) => {
    // eslint-disable-next-line no-param-reassign
    options.question.value = filter(
      options.question.value,
      ({ content }) => content !== id,
    );
  };

  if (options.question.getType() === 'file') {
    const container = document.createElement('div');
    const fileElement = options.htmlElement.getElementsByClassName('sv-file__decorator')[0];
    fileElement.parentNode.insertBefore(container, fileElement.nextSibling);

    options.question.onPropertyChanged.add((question, opt) => {
      if (opt.name === 'value') {
        updatePreview({
          values: opt.newValue,
          container,
          onFileDelete,
          isEditMode,
        });
      }
    });
    updatePreview({
      values: options.question.value,
      container,
      onFileDelete,
      isEditMode,
    });
  }
}
