import React from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { Alert, Stack } from 'react-bootstrap';
import { BsFillTrash3Fill as DeleteIcon } from 'react-icons/bs';

import api from 'api';
import useReports from 'hooks/useReports';
import useTableState from 'hooks/useTableState';
import useFeedback from 'hooks/useFeedback';
import { DEFAULT_PAGE_SIZE } from 'utils/constants';
// import Labels from 'utils/labels';

import SectionWrapper from 'components/layout/SectionWrapper';
import SVButton from 'components/elements/SVButton';
import Table from 'components/elements/Table';
import Modal from 'components/elements/BasicModal';
import ReportManageMenu from 'components/templates/menus/ReportManageMenu';

const ReportList = () => {
  const navigate = useNavigate();
  const { setSuccess, setError } = useFeedback();
  const [toDelete, setToDelete] = React.useState(null);
  const {
    page, handlePageChange, sortBy, sortOrder, handleSortChange,
  } = useTableState('name', 'asc', {});

  const {
    fetchReports, isLoading, data, error,
  } = useReports();

  const refreshList = React.useCallback(() => {
    fetchReports({
      Page: page,
      PageSize: DEFAULT_PAGE_SIZE,
      Sort: sortBy,
      SortDirection: sortOrder,
    });
  }, [fetchReports, page, sortBy, sortOrder]);

  React.useEffect(() => {
    refreshList();
  }, [refreshList]);

  const handleDelete = React.useCallback(() => {
    api
      .deleteDashboard(toDelete.id)
      .then(() => {
        setToDelete(null);
        setSuccess('Deleted with success');
        refreshList();
      })
      .catch(() => setError('Error deleting report'));
  }, [toDelete, refreshList, setError, setSuccess]);

  const renderDeleteModal = React.useMemo(
    () => (
      <Modal
        isOpen={Boolean(toDelete)}
        onClose={() => setToDelete(null)}
        title={`Are you sure you want to delete "${toDelete?.name}"?`}
        onConfirm={handleDelete}
      />
    ),
    [toDelete, handleDelete],
  );

  const handleDeletePrompt = React.useCallback((event, item) => {
    event.stopPropagation();
    setToDelete(item);
  }, []);

  const deleteButton = React.useCallback(
    (item) => (
      <SVButton size="sm" onClick={(e) => handleDeletePrompt(e, item)}>
        <DeleteIcon />
      </SVButton>
    ),
    [handleDeletePrompt],
  );

  const columns = React.useMemo(
    () => [
      {
        id: 'name',
        label: 'Name',
      },
      {
        id: 'delete',
        nonSortable: true,
        override: deleteButton,
        columnStyle: { textAlign: 'right' },
      },
    ],
    [deleteButton],
  );

  return (
    <SectionWrapper>
      <Stack direction="horizontal" gap={3} className="align-items-start">
        <ReportManageMenu />
        <div className="d-flex flex-grow-1 flex-column">
          <div>
            <SVButton as={Link} variant="secondary" to="new">
              New Report
            </SVButton>
          </div>
          {renderDeleteModal}
          <div className="mt-2">
            {error ? (
              <Alert variant="danger">{error}</Alert>
            ) : (
              <Table
                columns={columns}
                items={data.data}
                handlePageChange={handlePageChange}
                handleOrderChange={handleSortChange}
                order={sortBy}
                orderDirection={sortOrder}
                pagination={{
                  page,
                  total: data.count || 0,
                  limit: data.pageSize || 0,
                }}
                onClickRow={({ id }) => navigate(id)}
                isLoading={isLoading}
              />
            )}
          </div>
        </div>
      </Stack>
    </SectionWrapper>
  );
};

export default ReportList;
