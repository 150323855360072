import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';

import SVButton from 'components/elements/SVButton';

export default function PRQSubmitReviewConfirmation({ onSubmit, onCancel }) {
  const [satisfied, setSatisfied] = useState(false);

  return (
    <Modal show onHide={onCancel}>
      <Modal.Header closeButton>
        <Modal.Title>Final Submit</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          Are you sure you are ready to submit your final comments. This cannot
          be undone.
        </p>
        <p>
          <input
            type="checkbox"
            style={{ marginRight: '1rem' }}
            onChange={({ target }) => setSatisfied(target.checked)}
          />
          I am satisfied with the comments added to this form and wish to
          submit.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <SVButton variant="cancel" onClick={onCancel}>
          Cancel
        </SVButton>
        <SVButton
          size="sm"
          variant="secondary"
          onClick={onSubmit}
          disabled={!satisfied}
        >
          Submit
        </SVButton>
      </Modal.Footer>
    </Modal>
  );
}

PRQSubmitReviewConfirmation.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};
