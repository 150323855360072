import React from 'react';

const { REACT_APP_BDATA_HOST_URL } = process.env;

const HomePage = () => {
  React.useEffect(() => {
    window.location.replace(REACT_APP_BDATA_HOST_URL);
  }, []);
  return null;
};

export default HomePage;
