import React from 'react';
import PropTypes from 'prop-types';

import { Stack } from 'react-bootstrap';

import DatePicker from 'components/elements/FormInput/date/DatePicker';
import Select from 'components/elements/FormInput/select/Select';
import SVButton from 'components/elements/SVButton';

import { ProgramTypeLabels, ReviewStatusLabels } from 'utils/enums';

import FilterEnum from './data';

const FilterElement = ({ label = '', children }) => (
  <Stack style={{ width: '100px' }}>
    <b>{label}</b>
    {children}
  </Stack>
);

FilterElement.propTypes = {
  children: PropTypes.node.isRequired,
  label: PropTypes.string.isRequired,
};

const ExtraFilters = ({ filters, onFilter, onClearFilter }) => (
  <Stack direction="horizontal" gap={3} className="mb-3">
    <FilterElement label="Program Type">
      <Select
        isClearable
        options={Object.entries(ProgramTypeLabels).map(([value, label]) => ({
          value,
          label,
        }))}
        value={filters[FilterEnum.type]}
        onChange={(value) => onFilter(FilterEnum.type, value)}
      />
    </FilterElement>
    <FilterElement label="Active Status">
      <Select
        isMulti
        isClearable
        options={Object.entries(ReviewStatusLabels).map(([value, label]) => ({
          value,
          label,
        }))}
        value={filters[FilterEnum.status]}
        onChange={(value) => onFilter(FilterEnum.status, value)}
      />
    </FilterElement>
    <FilterElement label="Expired From">
      <DatePicker
        fullWidth
        isClearable
        value={filters[FilterEnum.from]}
        onChange={(value) => {
          onFilter(FilterEnum.from, value);
        }}
      />
    </FilterElement>
    <FilterElement label="Expired To">
      <DatePicker
        fullWidth
        isClearable
        value={filters[FilterEnum.to]}
        onChange={(value) => {
          onFilter(FilterEnum.to, value);
        }}
      />
    </FilterElement>
    <SVButton size="sm" variant="secondary" onClick={onClearFilter}>
      Clear
    </SVButton>
  </Stack>
);

ExtraFilters.propTypes = {
  onFilter: PropTypes.func.isRequired,
  onClearFilter: PropTypes.func.isRequired,
  filters: PropTypes.shape({}),
};

export default ExtraFilters;
