import React from 'react';
import PropTypes from 'prop-types';

import { Alert } from 'react-bootstrap';

import api from 'api';
import useMountEffect from 'hooks/useMountEffect';
import useUpdateEffect from 'hooks/useUpdateEffect';
import useRequest from 'hooks/useRequest';
import useImpressionateUser from 'hooks/useImpressionateUser';
import Loader from 'components/elements/Loader';

const ReportDisplay = ({ reportId }) => {
  const { impersonateUser } = useImpressionateUser();

  const {
    doRequest, data, isLoading, error,
  } = useRequest(api.getDashboard);

  const fetchRequest = React.useCallback(
    () => doRequest(reportId, { impersonateUserId: impersonateUser }),
    [doRequest, impersonateUser, reportId],
  );

  useMountEffect(fetchRequest);

  useUpdateEffect(() => {
    fetchRequest();
  }, [fetchRequest]);

  if (isLoading) return <Loader />;

  if (error) return <Alert variant="danger">Error loading report</Alert>;

  if (!data?.url?.startsWith('http')) return <Alert variant="warning">Invalid url</Alert>;

  return (
    <div style={{ height: 'calc(100vh - 24rem)' }}>
      <iframe width="100%" height="100%" title={data?.name} src={data?.url} />
    </div>
  );
};

ReportDisplay.propTypes = {
  reportId: PropTypes.string.isRequired,
};

export default ReportDisplay;
