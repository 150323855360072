import { useMemo, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import useReports from 'hooks/useReports';
import useUser from 'hooks/useUser';
import { getUsers } from 'store/users/actions';
import { getSearchParams } from 'utils/helpers';
import { MAX_PAGE_SIZE } from 'utils/constants';
import { ReportingRoleEnum } from 'utils/enums';

const useImpressionateUser = () => {
  const dispatch = useDispatch();
  const { search } = useLocation();
  const { fetchReports: getReports, data: reportData } = useReports();
  const { appUser } = useUser();
  const userData = useSelector((state) => state.users);

  const { impersonateUser } = useMemo(() => getSearchParams(search), [search]);

  const fetchUsers = useCallback(() => {
    dispatch(
      getUsers({
        CanAccessReports: true,
        Limit: MAX_PAGE_SIZE,
      }),
    );
  }, [dispatch]);

  const users = useMemo(() => userData.items ?? [], [userData.items]);

  const userOptions = useMemo(
    () => users.map(({ id, name }) => ({ value: id, label: name })),
    [users],
  );

  const userImpressionated = useMemo(
    () => !!impersonateUser
      && users.find(({ id }) => id === Number(impersonateUser)),
    [users, impersonateUser],
  );

  const currentUser = useMemo(
    () => (impersonateUser ? userImpressionated : appUser),
    [impersonateUser, appUser, userImpressionated],
  );

  const selectedOption = useMemo(
    () => (userImpressionated
      ? { value: userImpressionated.id, label: userImpressionated.name }
      : null),
    [userImpressionated],
  );

  const fetchReports = useCallback(() => {
    getReports({
      userRequesting: appUser.id,
      impersonateUserId: impersonateUser ? Number(impersonateUser) : null,
    });
  }, [appUser.id, getReports, impersonateUser]);

  const reports = useMemo(
    () => (reportData.userId === currentUser?.id ? reportData.data : []),
    [currentUser?.id, reportData],
  );

  const hasWorkSpace = useMemo(
    () => currentUser?.belongsTo
      && currentUser?.reportingRole !== ReportingRoleEnum.DASHBOARD,
    [currentUser],
  );

  return {
    fetchUsers,
    selectedOption,
    currentUser,
    userOptions,
    fetchReports,
    reports,
    hasWorkSpace,
    impersonateUser,
  };
};

export default useImpressionateUser;
