import React from 'react';
import { Routes, Route } from 'react-router-dom';

import useUser from 'hooks/useUser';
import useImpressionateUser from 'hooks/useImpressionateUser';
import useMountEffect from 'hooks/useMountEffect';

import { FlexColumn } from 'resources/styles/display';

import ReportImpersonateUser from './ReportImpersonateUser';
import HomePage from './HomePage';
import WorkspacePage from './WorkspacePage';
import ReportDisplayPage from './ReportDisplayPage';

const ReportViewPage = () => {
  const { isBdataAmin } = useUser();
  const { fetchUsers, fetchReports, hasWorkSpace } = useImpressionateUser();

  useMountEffect(() => {
    fetchReports();
    if (isBdataAmin) fetchUsers();
  });

  return (
    <FlexColumn className="flex-grow-1">
      {isBdataAmin && <ReportImpersonateUser />}
      <Routes>
        <Route exact path="/" element={<HomePage />} />
        {hasWorkSpace && <Route path="workspace" element={<WorkspacePage />} />}
        <Route path=":reportId" element={<ReportDisplayPage />} />
      </Routes>
    </FlexColumn>
  );
};

export default ReportViewPage;
