import { formStateLabels, statusLabels, ProgramTypeLabels } from 'utils/enums';

export const getStateLabel = (id) => formStateLabels[id] || '-';

export const getStatusLabel = (id) => statusLabels[id] || '-';

export const programType = {
  FELLOWSHIP: 1,
  BURNCENTER: 2,
};

const programTypes = {
  [programType.FELLOWSHIP]: ProgramTypeLabels.Fellowship,
  [programType.BURNCENTER]: ProgramTypeLabels.BurnCenter,
};

export const getProgramTypeLabel = (id) => programTypes[id] || '-';

export const formTitle = {
  initialApplication: 'Initial Application',
  prq: 'PRQ',
  meetingRounds: 'Meetings & Rounds',
  keyFindings: 'Key Findings',
  siteReport: 'Site Report',
};

export const programSubType = {
  VERIFICATION: 1,
  RE_VERIFICATION: 2,
  PROVISIONAL_VERIFICATION: 4,
  CONSULTATION: 8,
  FOCUS_REVIEW: 16,
};

const programSubTypeLabels = {
  [programSubType.VERIFICATION]: 'Verification',
  [programSubType.RE_VERIFICATION]: 'Re-Verification',
  [programSubType.PROVISIONAL_VERIFICATION]: 'Provisional Verification',
  [programSubType.CONSULTATION]: 'Consultation Only',
  [programSubType.FOCUS_REVIEW]: 'Focused Review',
};

export const programSubTypeLabel = (id) => programSubTypeLabels[id] || '-';

const formTypeLabels = {
  1: formTitle.prq,
  2: formTitle.meetingRounds,
  3: formTitle.keyFindings,
  4: formTitle.siteReport,
};

export const getFormLabel = (typeId) => formTypeLabels[typeId] ?? '';

const SharedLabels = {
  initQuestions: 'Initial Questions',
  appendix: 'Appendix',
};

const prqBurnNavigation = [
  SharedLabels.initQuestions,
  'Section 1: Burn Center Administration',
  'Section 2: Burn Center Volume',
  'Section 3: Burn Center Director',
  'Section 4: Burn Surgeons',
  'Section 5: Advanced Practice Providers',
  'Section 6: Nursing',
  'Section 7: Therapy',
  'Section 8: Burn Team Members & Consultants',
  'Section 9: Quality Improvement',
  'Section 10: Pre-Hospital Care',
  'Section 11: Emergency Department',
  'Section 12: Intensive Care Unit (ICU)',
  'Section 13: Operative Services (Including Anesthesiology)',
  'Section 14: Rehabilitation',
  'Section 15: Ambulatory Care',
  'Section 16: Community Reintegration',
  'Section 17: Prevention & Outreach',
  'Section 18: Advocacy & Research',
  'Section 19: Disaster Planning',
  'Case Summaries',
  SharedLabels.appendix,
];

const prqFellowNavigation = [
  SharedLabels.initQuestions,
  'Section 1: Organization',
  'Section 2: Duration and Scope of Training',
  'Section 3: The Educational Program',
  'Section 4: Core Curriculum',
  'Section 5: Didactic Experience',
  'Section 6: Evaluation',
  'Section 7: Case Requirements',
  SharedLabels.appendix,
];

const prqFocusNavigation = [SharedLabels.initQuestions, 'Focus Review'];

export const prqNavigation = ({ programTypeId, programSubtypeId }) => {
  if (programSubtypeId === programSubType.FOCUS_REVIEW) return prqFocusNavigation;
  if (programTypeId === programType.BURNCENTER) return prqBurnNavigation;
  if (programTypeId === programType.FELLOWSHIP) return prqFellowNavigation;
  return [];
};

export const meetingsAndRoundsNavigation = [
  'EMPTY',
  'Meetings',
  'Clinical Rounds',
  'Additional Documents',
];

export const keyFindingsNavigation = ({ programTypeId }) => [
  'EMPTY',
  'Disclaimer',
  'Description of Key Findings',
  programTypeId === programType.FELLOWSHIP ? 'Recommendation' : 'Case Review',
];

export const formType = {
  Application: 'Application',
  PRQ: 'PRQ',
  MeetingsAndRounds: 'MeetingsAndRounds',
  KeyFindings: 'KeyFindings',
};

export const burncenterLabel = ({
  name, hospitalName, city, location,
}) => `${name} (${hospitalName}), ${city}, ${location}`;
