import api from 'api';

export const ActionTypes = {
  FETCHING: 'FETCH-CONTENTS',
  FETCHED: 'FETCHED-CONTENTS',
};

export const fetchContents = ({ search }) => (dispatch) => {
  dispatch({ type: ActionTypes.FETCHING });
  api.getContents({ search, render: true }).then(({ data }) => dispatch({
    type: ActionTypes.FETCHED,
    data,
  }));
};
