import React from 'react';
import { Controller } from 'react-hook-form';
import PropTypes from 'prop-types';

import { date2UTCFormat, utcString2Date } from 'utils/dateTime';

import DatePicker from './DatePicker';

const ControlledDatePicker = ({ name, control, ...props }) => (
  <Controller
    control={control}
    name={name}
    render={({ field: { onChange, value } }) => (
      <DatePicker
        {...props}
        value={utcString2Date(value)}
        onChange={(date) => {
          onChange(date2UTCFormat(date));
        }}
      />
    )}
  />
);

ControlledDatePicker.propTypes = {
  name: PropTypes.string.isRequired,
  control: PropTypes.shape({}).isRequired,
};

export default ControlledDatePicker;
