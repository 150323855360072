import update from 'immutability-helper';
import {
  GET_REVIWER_COMMENTS,
  GET_REVIWER_COMMENTS_SUCCESS,
  GET_REVIWER_COMMENTS_ERROR,
  ADD_REVIEWER_COMMENTS,
} from 'store/actions/comments';

const initialState = {
  isLoading: true,
  data: {},
};

export default function reviewerComments(state = initialState, action) {
  switch (action.type) {
    case GET_REVIWER_COMMENTS:
      return initialState;
    case GET_REVIWER_COMMENTS_SUCCESS:
      return { isLoading: false, data: action.data };
    case GET_REVIWER_COMMENTS_ERROR:
      return { isLoading: false, data: {}, error: action.error };
    case ADD_REVIEWER_COMMENTS:
      return update(state, { data: { items: { $set: [action.data] } } });
    default:
      return state;
  }
}
