import React from 'react';
import { useNavigate } from 'react-router-dom';
import { lowerCase } from 'lodash';

import { Alert, Stack } from 'react-bootstrap';

import api from 'api';
import useMountEffect from 'hooks/useMountEffect';
import useRequest from 'hooks/useRequest';
import useTableState from 'hooks/useTableState';

import SectionWrapper from 'components/layout/SectionWrapper';
import Table from 'components/elements/Table';
import SiteVisitCalendar from 'components/forms/SiteVisitCalendar';

import VerificationListMenu from './VerificationListMenu';
import { tableColumns } from './utils';

const VerificationList = () => {
  const navigate = useNavigate();
  const {
    page, handlePageChange, sortBy, sortOrder, handleSortChange,
  } = useTableState('userRole', 'asc', {});
  const {
    doRequest: getListData,
    data: listData,
    isLoading,
    error,
  } = useRequest(api.getAssignments);

  const handleDataRetreive = React.useCallback(() => {
    getListData({
      page,
      Order: sortBy,
      OrderDirection: sortOrder,
    });
  }, [getListData, page, sortBy, sortOrder]);

  useMountEffect(handleDataRetreive);

  return (
    <SectionWrapper title="Verification Dashboard">
      <Stack direction="horizontal" gap={3} className="align-items-start">
        <div className="d-flex flex-grow-1 flex-column">
          {error ? (
            <Alert variant="danger">Error loading list</Alert>
          ) : (
            <Table
              columns={tableColumns}
              items={listData?.items ?? []}
              order={sortBy}
              orderDirection={sortOrder}
              pagination={{
                page,
                total: listData?.total ?? 0,
                limit: listData?.limit ?? 0,
              }}
              handlePageChange={handlePageChange}
              handleOrderChange={handleSortChange}
              onClickRow={({ applicationId, roleOnVerification }) => navigate(`${lowerCase(roleOnVerification)}/${applicationId}`)}
              isLoading={isLoading}
            />
          )}
        </div>
        <Stack gap={4} style={{ maxWidth: '300px' }}>
          <VerificationListMenu />
          <SiteVisitCalendar />
        </Stack>
      </Stack>
    </SectionWrapper>
  );
};

export default VerificationList;
