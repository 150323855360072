import React from 'react';
import { useParams } from 'react-router-dom';

import { Button, Stack } from 'react-bootstrap';

import api from 'api';
import useRequest from 'hooks/useRequest';
import useFeedback from 'hooks/useFeedback';

import Loader from 'components/elements/Loader';
import FontAwesomeIcon from 'components/elements/FontAwesomeIcon';
import SVButton from 'components/elements/SVButton';
import Modal from 'components/elements/BasicModal';
import { FlexDiv, FlexWrap } from 'resources/styles/display';

import { UserSelect, UserListItem } from './UserManagement.styles';

const item2Option = (item) => ({
  value: item.id,
  label: item.name,
});

const UserManagement = () => {
  const { appId } = useParams();
  const {
    doRequest: getUsers,
    data: userData,
    isLoading,
  } = useRequest(api.getSiteReviewers);
  const { setError } = useFeedback();

  const [user2Add, setUser2Add] = React.useState(null);
  const [user2Delete, setUser2Delete] = React.useState(null);

  const fetchData = React.useCallback(() => {
    getUsers(appId);
  }, [getUsers, appId]);

  React.useEffect(() => {
    fetchData();
  }, [fetchData]);

  const assignedUsers = React.useMemo(
    () => userData?.usersAssigned?.sort((a, b) => a.name.localeCompare(b.name))
      ?? [],
    [userData?.usersAssigned],
  );

  const availabelUsers = React.useMemo(() => {
    const assignedUserIds = assignedUsers.map(({ id }) => id);
    return (
      userData?.usersUnassigned
        ?.filter((user) => !assignedUserIds.includes(user.id))
        .sort((a, b) => a.name.localeCompare(b.name))
        .map(item2Option) ?? []
    );
  }, [assignedUsers, userData?.usersUnassigned]);

  const onUserAdd = async () => {
    if (!user2Add) return;
    try {
      await api.addSiteReviewer({ appId, userId: user2Add.value });
      setUser2Add(null);
      fetchData();
    } catch {
      setError('Error adding user');
    }
  };

  const onUserDelete = async () => {
    if (!user2Delete) return;
    try {
      await api.removeSiteReviewer({ appId, userId: user2Delete.id });
      setUser2Delete(null);
      fetchData();
    } catch {
      setError('Error removing user');
    }
  };

  if (isLoading) return <Loader />;

  return (
    <Stack gap={2}>
      {!!user2Delete && (
        <Modal
          isOpen
          title={`Are you sure you want to remove "${user2Delete.name}"?`}
          body="They will lose all access to this verification application."
          onConfirm={onUserDelete}
          onClose={() => setUser2Delete(null)}
        />
      )}
      <Stack>
        <span>Site Reviewers</span>
        <FlexDiv>
          <UserSelect
            value={user2Add}
            options={availabelUsers}
            onChange={setUser2Add}
          />
          <SVButton onClick={onUserAdd} disabled={!user2Add} size="sm">
            <b>Add</b>
          </SVButton>
        </FlexDiv>
      </Stack>

      <FlexWrap style={{ gap: '0.5rem' }}>
        {assignedUsers.map((user) => (
          <UserListItem key={user.id}>
            <div>{user.name}</div>
            <Button onClick={() => setUser2Delete(user)}>
              <FontAwesomeIcon className="fa-times" />
            </Button>
          </UserListItem>
        ))}
      </FlexWrap>
    </Stack>
  );
};

export default UserManagement;
