import React from 'react';
import PropTypes from 'prop-types';
import findIndex from 'lodash/findIndex';

import { FlexDivSpaceBetweenCenter } from 'resources/styles/display';
import SVButton from 'components/elements/SVButton';

export default function FooterNavigation({ model, onPageChanged }) {
  const {
    isFirstPage,
    isLastPage,
    visiblePages,
    currentPage,
  } = model;

  const currentIndex = findIndex(visiblePages, { name: currentPage.name });

  return (
    <FlexDivSpaceBetweenCenter className="p-5">
      <SVButton
        variant="secondary"
        disabled={isFirstPage}
        onClick={() => onPageChanged(visiblePages[currentIndex - 1])}
      >
        Previous
      </SVButton>
      <SVButton
        variant="secondary"
        disabled={isLastPage}
        onClick={() => onPageChanged(visiblePages[currentIndex + 1])}
      >
        Next
      </SVButton>
    </FlexDivSpaceBetweenCenter>
  );
}

FooterNavigation.propTypes = {
  onPageChanged: PropTypes.func.isRequired,
  model: PropTypes.shape({
    isFirstPage: PropTypes.bool,
    isLastPage: PropTypes.bool,
    currentPage: PropTypes.shape({
      name: PropTypes.string,
    }),
    visiblePages: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
};
