import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';

import SVButton from './SVButton';

const BasicModal = ({
  title,
  body,
  isOpen,
  onClose,
  onCloseTxt = 'No',
  onConfirm,
  onConfirmTxt = 'Yes',
  size = 'lg',
  disableConfirm = false,
}) => (
  <Modal show={isOpen} onHide={onClose} size={size} centered>
    <Modal.Header closeButton>
      <Modal.Title>{title}</Modal.Title>
    </Modal.Header>
    {body && <Modal.Body>{body}</Modal.Body>}
    <Modal.Footer>
      <SVButton variant="cancel" onClick={onClose}>
        {onCloseTxt}
      </SVButton>
      {onConfirm && (
        <SVButton onClick={onConfirm} disabled={disableConfirm}>
          {onConfirmTxt}
        </SVButton>
      )}
    </Modal.Footer>
  </Modal>
);

BasicModal.propTypes = {
  title: PropTypes.string,
  body: PropTypes.node,
  isOpen: PropTypes.bool,
  size: PropTypes.string,
  onClose: PropTypes.func,
  onCloseTxt: PropTypes.string,
  onConfirm: PropTypes.func,
  onConfirmTxt: PropTypes.string,
  disableConfirm: PropTypes.bool,
};

export default BasicModal;
