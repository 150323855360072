import React from 'react';
import { useNavigate } from 'react-router-dom';

import Select from 'react-select';

import useImpressionateUser from 'hooks/useImpressionateUser';
import { MainRoutes, ReportRoutes } from 'helpers/routes';

import { HistoryBackButton } from 'components/elements/BackButton';
import { FlexDivSpaceBetweenCenter } from 'resources/styles/display';

const ReportImpersonateUser = () => {
  const navigate = useNavigate();
  const { userOptions, selectedOption } = useImpressionateUser();

  const onImpersonateUser = React.useCallback(
    (id) => {
      navigate(
        `${MainRoutes.REPORTS}/${ReportRoutes.VIEW}?impersonateUser=${id}`,
        { replace: true },
      );
    },
    [navigate],
  );

  return (
    <FlexDivSpaceBetweenCenter className="p-2 border-bottom border-2">
      <HistoryBackButton />
      <div style={{ width: '20rem' }}>
        <Select
          placeholder="Select a user to view report as"
          options={userOptions}
          onChange={({ value }) => onImpersonateUser(value)}
          value={selectedOption}
        />
      </div>
    </FlexDivSpaceBetweenCenter>
  );
};

export default ReportImpersonateUser;
