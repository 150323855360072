import React from 'react';
import { Editor } from '@tinymce/tinymce-react';

import { useAppContext } from 'context/AppContext';

import Loader from 'components/elements/Loader';

const RichTextComponent = (props) => {
  const { tinyMCE } = useAppContext();

  return tinyMCE ? <Editor {...props} apiKey={tinyMCE} /> : <Loader />;
};

export default RichTextComponent;
