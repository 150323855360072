import React from 'react';
import PropTypes from 'prop-types';

import api from 'api';
import useRequest from 'hooks/useRequest';

const AppContext = React.createContext({});

export const AppContextProvider = ({ children }) => {
  const { doRequest: getToken, data: tinyData } = useRequest(
    api.getTinyMCEToken,
  );

  React.useEffect(() => {
    getToken();
  }, [getToken]);

  const value = React.useMemo(
    () => ({
      tinyMCE: tinyData?.tinyMCE,
    }),
    [tinyData],
  );

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};

AppContextProvider.propTypes = {
  children: PropTypes.node,
};

export const useAppContext = () => React.useContext(AppContext);
