export const SET_FEEDBACK = 'SET_FEEDBACK';
export const CLEAR_FEEDBACK = 'CLEAR_FEEDBACK';

export const FEEDBACK_SUCCESS = 'success';
export const FEEDBACK_ERROR = 'error';

export function cleanFeedBack() {
  return (dispatch) => dispatch({ type: CLEAR_FEEDBACK });
}

export function setSuccessFeedback(msg) {
  return (dispatch) => {
    dispatch({ type: SET_FEEDBACK, status: FEEDBACK_SUCCESS, msg });
  };
}

export function setErrorFeedback(msg) {
  return (dispatch) => {
    dispatch({ type: SET_FEEDBACK, status: FEEDBACK_ERROR, msg });
  };
}
