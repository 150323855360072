import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Typeahead } from 'react-bootstrap-typeahead';

import 'react-bootstrap-typeahead/css/Typeahead.css';

function Search({
  onSearch,
  id = 'search',
  options = [],
  labelKey = 'label',
  placeholder = 'Search',
}) {
  const [selected, setSelected] = useState([]);

  useEffect(
    () => {
      onSearch(selected.length ? selected[0].value : null);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selected],
  );

  return (
    <Typeahead
      id={id}
      clearButton
      options={options}
      labelKey={labelKey}
      selected={selected}
      onChange={setSelected}
      placeholder={placeholder}
    />
  );
}

Search.propTypes = {
  onSearch: PropTypes.func.isRequired,
  id: PropTypes.string,
  labelKey: PropTypes.string,
  placeholder: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({})),
};

export default Search;
