import React from 'react';
import { useParams } from 'react-router-dom';

import { Alert } from 'react-bootstrap';

import api from 'api';
import useRequest from 'hooks/useRequest';
import useFeedback from 'hooks/useFeedback';
import useGoBack from 'hooks/useGoBack';

import SectionWrapper from 'components/layout/SectionWrapper';
import Loader from 'components/elements/Loader';

import ReportForm from './ReportForm';

const ReportEdit = () => {
  const { goBack } = useGoBack();
  const { setSuccess, setError } = useFeedback();
  const { reportId } = useParams();
  const {
    doRequest, isLoading, data, error,
  } = useRequest(api.getDashboard);

  React.useEffect(() => {
    doRequest(reportId);
  }, [doRequest, reportId]);

  const handleSubmit = React.useCallback(
    (values) => {
      api
        .updateDashboard(values)
        .then(() => {
          setSuccess('Report updated successfully');
          goBack();
        })
        .catch(() => setError('Error updating report'));
    },
    [goBack, setSuccess, setError],
  );

  const renderContent = React.useMemo(() => {
    if (error) return <Alert variant="danger">Error loading report</Alert>;
    if (isLoading) return <Loader />;
    return <ReportForm defaultValues={data} onSubmit={handleSubmit} />;
  }, [data, error, isLoading, handleSubmit]);

  return <SectionWrapper title="Edit Report">{renderContent}</SectionWrapper>;
};

export default ReportEdit;
