import React from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import pick from 'lodash/pick';

import { Button, Form, Stack } from 'react-bootstrap';

import useGoBack from 'hooks/useGoBack';
import useFeedback from 'hooks/useFeedback';
import { ContentCategoryLabels } from 'utils/enums';

import {
  LinkBackButton,
  HistoryBackButton,
} from 'components/elements/BackButton';
import RichTextInput from 'components/elements/FormInput/richText/RichTextControlledInput';
import Modal from 'components/elements/BasicModal';

const FormFields = {
  catId: 'category',
  title: 'title',
  desc: 'description',
  fileUrl: 's3FileUrl',
  thumbnailUrl: 's3FileThumbnailUrl',
  richText: 'content',
};

const validationSchema = Yup.object().shape({});

const TrainingItemForm = ({
  defaultValues,
  onSubmit,
  onDelete,
  forceRefresh,
}) => {
  const { setError } = useFeedback();
  const [isEditing, setEditing] = React.useState(!defaultValues);
  const [deleteModal, setDeleteModal] = React.useState(false);
  const { goBack } = useGoBack();

  const {
    control,
    register,
    handleSubmit,
    reset,
    formState: { isDirty },
  } = useForm({
    defaultValues: pick(defaultValues, Object.values(FormFields)),
    resolver: yupResolver(validationSchema),
  });

  const handleFormSubmit = async (values) => {
    try {
      const updatedData = await onSubmit(values);
      if (defaultValues && updatedData) {
        reset(updatedData);
        setEditing(false);
      }
    } catch (err) {
      const errorMessage = err?.response?.data?.detail || 'Error submitting form';
      setError(errorMessage);
    }
  };

  const handleDelete = async () => {
    try {
      await onDelete();
    } catch {
      setError('Error deleting item');
    }
  };

  const cancelEdit = () => {
    setEditing(false);
    reset(defaultValues);
  };

  return (
    <Form onSubmit={handleSubmit(handleFormSubmit)}>
      <Modal
        title="Are you sure you want to delete this item?"
        body="This cannot be undone"
        isOpen={deleteModal}
        onClose={() => setDeleteModal(false)}
        onConfirm={handleDelete}
      />
      <div className="d-flex justify-content-between mb-3">
        <div>
          {defaultValues
            && (forceRefresh ? (
              <LinkBackButton state={{ forceRefresh }} />
            ) : (
              <HistoryBackButton />
            ))}
        </div>
        <Stack direction="horizontal" gap={2}>
          {isEditing ? (
            <>
              <Button
                variant="outline-secondary"
                onClick={() => (defaultValues ? cancelEdit() : goBack())}
              >
                Cancel
              </Button>
              <Button variant="secondary" type="submit" disabled={!isDirty}>
                Save
              </Button>
            </>
          ) : (
            <Button variant="primary" onClick={() => setEditing(true)}>
              Edit
            </Button>
          )}
          {defaultValues && (
            <Button variant="danger" onClick={() => setDeleteModal(true)}>
              Delete
            </Button>
          )}
        </Stack>
      </div>
      <Form.Group className="mb-3" controlId={FormFields.catId}>
        <Form.Label>Category</Form.Label>
        <Form.Select
          aria-label="Select category"
          required
          {...register(FormFields.catId, {
            required: true,
            disabled: !isEditing,
          })}
        >
          {Object.entries(ContentCategoryLabels).map(([value, label]) => (
            <option key={value} value={value}>
              {label}
            </option>
          ))}
        </Form.Select>
      </Form.Group>
      <Form.Group className="mb-3" controlId={FormFields.title}>
        <Form.Label>Title</Form.Label>
        <Form.Control
          required
          {...register(FormFields.title, {
            required: true,
            disabled: !isEditing,
          })}
          placeholder="Title"
        />
      </Form.Group>
      <Form.Group className="mb-3" controlId={FormFields.desc}>
        <Form.Label>Description</Form.Label>
        <Form.Control
          required
          {...register(FormFields.desc, {
            required: true,
            disabled: !isEditing,
          })}
          placeholder="Description"
        />
      </Form.Group>
      <Form.Group className="mb-3" controlId={FormFields.fileUrl}>
        <Form.Label>File URL</Form.Label>
        <Form.Control
          {...register(FormFields.fileUrl, { disabled: !isEditing })}
          placeholder="File URL"
        />
      </Form.Group>
      <Form.Group className="mb-3" controlId={FormFields.thumbnailUrl}>
        <Form.Label>File Thumbnail URL</Form.Label>
        <Form.Control
          {...register(FormFields.thumbnailUrl, { disabled: !isEditing })}
          placeholder="File Thumbnail URL"
        />
      </Form.Group>
      <Form.Group className="mb-3" controlId={FormFields.richText}>
        <Form.Label>Content</Form.Label>
        <RichTextInput
          control={control}
          name={FormFields.richText}
          disabled={!isEditing}
        />
      </Form.Group>
    </Form>
  );
};

TrainingItemForm.propTypes = {
  defaultValues: PropTypes.shape({}),
  onSubmit: PropTypes.func.isRequired,
  onDelete: PropTypes.func,
  forceRefresh: PropTypes.bool,
};

export default TrainingItemForm;
