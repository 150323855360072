import React from 'react';
import PropTypes from 'prop-types';

import { Stack } from 'react-bootstrap';
import {
  BsChevronDoubleLeft,
  BsChevronDoubleRight,
  BsChevronLeft,
  BsChevronRight,
} from 'react-icons/bs';

import SVButton from 'components/elements/SVButton';

const PDFNavigation = ({ currentPage, totalPages, handlePageChange }) => (
  <Stack className="justify-content-center" direction="horizontal" gap={3}>
    <SVButton disabled={currentPage <= 1} onClick={() => handlePageChange(1)}>
      <BsChevronDoubleLeft />
    </SVButton>
    <SVButton
      disabled={currentPage <= 1}
      onClick={() => handlePageChange(currentPage - 1)}
    >
      <BsChevronLeft />
    </SVButton>
    <span style={{ margin: '0px .5rem' }}>
      {`${currentPage} / ${totalPages}`}
    </span>
    <SVButton
      disabled={currentPage >= totalPages}
      onClick={() => handlePageChange(currentPage + 1)}
    >
      <BsChevronRight />
    </SVButton>
    <SVButton
      disabled={currentPage >= totalPages}
      onClick={() => handlePageChange(totalPages)}
    >
      <BsChevronDoubleRight />
    </SVButton>
  </Stack>
);

PDFNavigation.propTypes = {
  currentPage: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  handlePageChange: PropTypes.func.isRequired,
};

export default PDFNavigation;
