import React from 'react';
import PropTypes from 'prop-types';

import * as SurveyKo from 'survey-knockout';

import { StylesManager, SurveyCreator } from 'survey-creator';
import 'survey-creator/survey-creator.css';

import * as widgets from 'surveyjs-widgets';

import $ from 'jquery';
import 'jquery-ui/ui/widgets/datepicker';
import 'jquery-ui/themes/base/all.css';
import 'jquery-bar-rating';
import 'jquery-bar-rating/dist/themes/css-stars.css';
import 'jquery-bar-rating/dist/themes/fontawesome-stars.css';
import 'select2/dist/js/select2';
import 'select2/dist/css/select2.css';
import 'pretty-checkbox/dist/pretty-checkbox.css';

SurveyKo.settings.panelMaximumPanelCount = 4500;

StylesManager.applyTheme('modern');

// widgets.icheck(SurveyKo, $);
widgets.prettycheckbox(SurveyKo);
widgets.select2(SurveyKo, $);
widgets.inputmask(SurveyKo);
widgets.jquerybarrating(SurveyKo, $);
widgets.jqueryuidatepicker(SurveyKo, $);
widgets.nouislider(SurveyKo);
widgets.select2tagbox(SurveyKo, $);
// widgets.signaturepad(SurveyKo);
widgets.sortablejs(SurveyKo);
widgets.ckeditor(SurveyKo);
widgets.autocomplete(SurveyKo, $);
widgets.bootstrapslider(SurveyKo);

const options = {
  haveCommercialLicense: true,
};

const containerId = 'surveyCreatorContainer';

function SurveyCreatorRenderComponent({ model, onSave }) {
  React.useEffect(() => {
    const surveyCreator = new SurveyCreator(containerId, options);
    surveyCreator.JSON = model;
    surveyCreator.saveSurveyFunc = () => {
      onSave(surveyCreator.JSON, () => {});
    };
  }, [model, onSave]);

  return <div id={containerId} />;
}

SurveyCreatorRenderComponent.propTypes = {
  onSave: PropTypes.func.isRequired,
  model: PropTypes.shape({}),
};

export default SurveyCreatorRenderComponent;
