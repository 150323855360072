import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';

import 'survey-react/modern.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'resources/stylesheets/index.scss';

import AppRoutes from 'pages/AppRoutes';
import ErrorPage from 'pages/ErrorPage';
import Loader from 'components/elements/Loader';

import useUser from 'hooks/useUser';
import { setAppToken } from 'store/auth/actions';

const { REACT_APP_AUTH0_AUDIENCE } = process.env;

const App = () => {
  const {
    isLoading,
    isAuthenticated,
    error,
    loginWithRedirect,
    getAccessTokenSilently,
  } = useAuth0();
  const { appUser, fetchAppUser, error: userError } = useUser();

  React.useEffect(() => {
    if (!isAuthenticated) return;
    getAccessTokenSilently({
      authorizationParams: {
        audience: REACT_APP_AUTH0_AUDIENCE,
        scope: 'read:current_user',
      },
    }).then((token) => {
      setAppToken(token);
      fetchAppUser();
    });
  }, [isAuthenticated, getAccessTokenSilently, fetchAppUser]);

  React.useEffect(() => {
    if (isLoading || isAuthenticated || error) return;
    loginWithRedirect({ appState: { returnTo: window.location.pathname } });
  }, [isLoading, isAuthenticated, error, loginWithRedirect]);

  const errorMsg = React.useMemo(
    () => error?.message || userError,
    [error, userError],
  );

  if (errorMsg) return <ErrorPage errorTxt={errorMsg} />;

  if (!appUser.id) return <Loader isModal />;

  return <AppRoutes />;
};

export default App;
