import React from 'react';
import { bindActionCreators } from 'redux';
import { useDispatch } from 'react-redux';

import { setErrorFeedback, setSuccessFeedback } from 'store/feedback/actions';

const useFeedback = () => {
  const dispatch = useDispatch();

  const actions = React.useMemo(
    () => bindActionCreators(
      {
        setError: setErrorFeedback,
        setSuccess: setSuccessFeedback,
      },
      dispatch,
    ),
    [dispatch],
  );

  return {
    ...actions,
  };
};

export default useFeedback;
