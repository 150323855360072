import {
  APP_USER_REQUEST,
  APP_USER_REQUEST_SUCCESS,
  APP_USER_REQUEST_ERROR,
  APP_USER_BURNCENTER,
} from './actions';

const initialState = {
  isLoading: true,
  appUser: {},
  userBurnCenter: null,
};

export default function auth(state = initialState, action) {
  switch (action.type) {
    case APP_USER_REQUEST:
      return initialState;
    case APP_USER_REQUEST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        appUser: action.user,
      };
    case APP_USER_REQUEST_ERROR:
      return { ...state, isLoading: false, error: action.error };
    case APP_USER_BURNCENTER:
      return { ...state, userBurnCenter: action.data };
    default:
      return state;
  }
}
