import React from 'react';
import { useParams } from 'react-router-dom';

import { Spinner } from 'react-bootstrap';

import api from 'api';
import useFeedback from 'hooks/useFeedback';
import useRequest from 'hooks/useRequest';
import Modal from 'components/elements/BasicModal';
import Loader from 'components/elements/Loader';
import SectionWrapper from 'components/layout/SectionWrapper';
import { HistoryBackButton } from 'components/elements/BackButton';

import SurveyCreator from './SurveyCreator';

const SurveyEditor = () => {
  const [createJson, setCreateJson] = React.useState(null);
  const { surveyId } = useParams();
  const { setSuccess, setError } = useFeedback();

  const { doRequest: fetchSurvey, data: surveyData } = useRequest(
    api.getSurvey,
  );
  const { doRequest: getSurveyVersions, data: surveyVersionData } = useRequest(
    api.getSurveyVersions,
  );

  const fetchSurveyVersions = React.useCallback(() => {
    getSurveyVersions({
      surveyId,
      limit: 1,
      order: 'version',
      orderDirection: 'desc',
    });
  }, [surveyId, getSurveyVersions]);

  React.useEffect(() => {
    fetchSurvey(surveyId);
    fetchSurveyVersions();
  }, [surveyId, fetchSurvey, fetchSurveyVersions]);

  const surveyVersion = React.useMemo(
    () => surveyVersionData?.items?.[0],
    [surveyVersionData],
  );

  const handleVersionUpdate = React.useCallback(
    (json, cb) => {
      api
        .updateSurveyVersion({
          versionId: surveyVersion.id,
          model: json,
        })
        .then(() => {
          setSuccess('Survey successfully updated');
          cb();
        })
        .catch(({ response }) => {
          const detail = response?.data?.detail;
          if (detail && detail.includes('is already used')) {
            setCreateJson(json);
            return;
          }
          setError('Error updating survey');
        });
    },
    [surveyVersion, setSuccess, setError],
  );

  const renderCreateModal = React.useMemo(() => {
    if (!createJson) return null;
    return (
      <Modal
        title="Survey version already in use"
        body="Do you want to create a new version for this survey?"
        isOpen
        onClose={() => setCreateJson(null)}
        onConfirm={() => {
          api
            .addSurveyVersion({
              surveyId,
              model: createJson,
            })
            .then(() => {
              setSuccess('Survey created updated');
              fetchSurveyVersions();
              setCreateJson(null);
            })
            .catch(() => {
              setError('Error creating form');
              setCreateJson(null);
            });
        }}
      />
    );
  }, [surveyId, createJson, fetchSurveyVersions, setSuccess, setError]);

  return (
    <SectionWrapper title={surveyData?.name || <Spinner animation="border" />}>
      {renderCreateModal}
      <div className="mb-2">
        <HistoryBackButton />
      </div>
      {surveyVersion ? (
        <SurveyCreator
          model={surveyVersion.model}
          onSave={handleVersionUpdate}
        />
      ) : (
        <Loader isModal={false} />
      )}
    </SectionWrapper>
  );
};

export default SurveyEditor;
