import api from 'api';
import { setSuccessFeedback, setErrorFeedback } from 'store/feedback/actions';

import { MAX_CHAT_COMMENTS } from 'utils/constants';

export const GET_COMMENT_COUNT = 'GET_COMMENT_COUNT';
export const GET_COMMENT_COUNT_SUCCESS = 'GET_COMMENT_COUNT_SUCCESS';
export const GET_COMMENT_COUNT_ERROR = 'GET_COMMENT_COUNT_ERROR';
export const GET_QUESTION_COMMENTS = 'GET_QUESTION_COMMENTS';
export const GET_QUESTION_COMMENTS_SUCCESS = 'GET_QUESTION_COMMENTS_SUCCESS';
export const GET_QUESTION_COMMENTS_ERROR = 'GET_QUESTION_COMMENTS_ERROR';
export const CLOSE_QUESTION_COMMENTS = 'CLOSE_QUESTION_COMMENTS';
export const ADD_QUESTION_COMMENTS = 'ADD_QUESTION_COMMENTS';
export const LOAD_MORE_COMMENTS = 'LOAD_MORE_COMMENTS';

export const GET_REVIWER_COMMENTS = 'GET_REVIWER_COMMENTS';
export const GET_REVIWER_COMMENTS_SUCCESS = 'GET_REVIWER_COMMENTS_SUCCESS';
export const GET_REVIWER_COMMENTS_ERROR = 'GET_REVIWER_COMMENTS_ERROR';
export const ADD_REVIEWER_COMMENTS = 'ADD_REVIEWER_COMMENTS';

export function getCommentCount(applicationId, surveyVersionId, userTypeId) {
  return (dispatch) => {
    dispatch({ type: GET_COMMENT_COUNT });
    api
      .getCommentCount({
        applicationId,
        surveyVersionId,
        userTypeId,
      })
      .then((response) => dispatch({ type: GET_COMMENT_COUNT_SUCCESS, response }))
      .catch((error) => dispatch({ type: GET_COMMENT_COUNT_ERROR, error }));
  };
}

export function getQuestionComments({
  applicationId,
  surveyVersionId,
  userTypeId,
  questionId,
  title,
}) {
  return (dispatch) => {
    dispatch({ type: GET_QUESTION_COMMENTS, questionId, title });
    api
      .getComments({
        applicationId,
        surveyVersionId,
        userTypeId,
        questionId,
        limit: MAX_CHAT_COMMENTS,
      })
      .then(({ data }) => dispatch({ type: GET_QUESTION_COMMENTS_SUCCESS, data }))
      .catch(() => dispatch({
        type: GET_QUESTION_COMMENTS_ERROR,
        error: 'Could not load comments',
      }));
  };
}

export function loadMoreComments({
  applicationId,
  surveyVersionId,
  userTypeId,
  questionId,
  page,
}) {
  return (dispatch) => {
    api
      .getComments({
        applicationId,
        surveyVersionId,
        userTypeId,
        questionId,
        limit: MAX_CHAT_COMMENTS,
        page,
      })
      .then(({ data }) => dispatch({ type: LOAD_MORE_COMMENTS, data }))
      .catch(() => dispatch(setErrorFeedback('Error loading comments')));
  };
}

export function addQuestionComment({
  applicationId,
  surveyVersionId,
  userTypeId,
  questionId,
  message,
  cb,
}) {
  return (dispatch) => {
    if (!message) {
      dispatch(setErrorFeedback('Message is required'));
      return;
    }
    api
      .addComment({
        appId: applicationId,
        data: {
          surveyVersionId,
          userTypeId,
          questionId,
          message,
        },
      })
      .then(({ data }) => {
        dispatch({ type: ADD_QUESTION_COMMENTS, data });
        if (cb) cb();
      })
      .catch(() => dispatch(setErrorFeedback('Error adding comment')));
  };
}

export function closeQuestionComments() {
  return (dispatch) => dispatch({ type: CLOSE_QUESTION_COMMENTS });
}

/* SITE REVIEWER COMMENTS */

export function getReviwerComments({
  applicationId,
  surveyVersionId,
  criteriaId,
}) {
  return (dispatch) => {
    dispatch({ type: GET_REVIWER_COMMENTS });
    api
      .getComments({
        applicationId,
        surveyVersionId,
        criteriaId,
      })
      .then(({ data }) => dispatch({ type: GET_REVIWER_COMMENTS_SUCCESS, data }))
      .catch(() => dispatch({
        type: GET_REVIWER_COMMENTS_ERROR,
        error: 'Error loading comments',
      }));
  };
}

export function addReviewerComment({
  applicationId,
  surveyVersionId,
  criteriaId,
  message,
}) {
  return (dispatch) => {
    if (!message) {
      dispatch(setErrorFeedback('Message is required'));
      return;
    }
    api
      .addReviewerComment({
        appId: applicationId,
        data: {
          surveyVersionId,
          criteriaId,
          message,
        },
      })
      .then(({ data }) => {
        dispatch({ type: ADD_REVIEWER_COMMENTS, data });
        dispatch(setSuccessFeedback('Comment added'));
      })
      .catch(() => dispatch(setErrorFeedback('Error adding comment')));
  };
}

export function updateReviewerComment({ commentId, message }) {
  return (dispatch) => {
    if (!message) {
      dispatch(setErrorFeedback('Message is required'));
      return;
    }
    api
      .updateComment({ commentId, message })
      .then(() => dispatch(setSuccessFeedback('Comment updated')))
      .catch(() => dispatch(setErrorFeedback('Error updating comment')));
  };
}
