import React from 'react';
import PropTypes from 'prop-types';
import { Link, useNavigate } from 'react-router-dom';
import {
  Button, Card, Col, Row, Stack,
} from 'react-bootstrap';

import { BsPlayFill, BsFilePdf } from 'react-icons/bs';

import useUser from 'hooks/useUser';

import { ContentType, getContentType } from './utils';

const FileContentList = ({ title, contentList, previewIcon }) => {
  const { isBdataAmin } = useUser();
  const navigate = useNavigate();

  return (
    !!contentList.length && (
      <div className="mb-4">
        <h4>{title}</h4>
        <Row xs={1} sm={2} md={3} xl={4} xxl={5} className="g-4">
          {contentList.map((content) => (
            <Col key={content.id}>
              <Card
                className="overflow-hidden text-decoration-none"
                as={Link}
                to={`view/${content.id}`}
              >
                <div
                  className="bg-primary position-relative"
                  style={{
                    paddingTop: '60%',
                    backgroundImage: `url("${content.s3FileThumbnailDownloadUrl || '/assets/No-Preview-Available.jpg'}")`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                  }}
                >
                  <div className="position-absolute top-0 start-0 w-100 h-100 d-flex justify-content-center align-items-center text-white bg-dark bg-opacity-50">
                    {previewIcon}
                  </div>
                  {isBdataAmin && (
                    <div className="position-absolute top-0 end-0">
                      <Button
                        variant="secondary"
                        onClick={(e) => {
                          e.preventDefault();
                          navigate(content.id);
                        }}
                      >
                        Edit
                      </Button>
                    </div>
                  )}
                </div>
                <Card.Body className="border-top py-0 px-2">
                  <Card.Title className="text-decoration-underline">
                    {content.title}
                  </Card.Title>
                  <Card.Subtitle className="mb-2 text-muted">
                    {content.description}
                  </Card.Subtitle>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </div>
    )
  );
};

FileContentList.propTypes = {
  title: PropTypes.string.isRequired,
  contentList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  previewIcon: PropTypes.node,
};

const TrainingTab = ({ catId, contents }) => {
  const { isBdataAmin } = useUser();
  const navigate = useNavigate();

  const tabData = React.useMemo(
    () => (catId === 'All'
      ? contents
      : contents.filter(({ category }) => category === catId)),
    [catId, contents],
  );

  const contentData = React.useMemo(
    () => tabData.reduce(
      (res, content) => {
        const fileType = getContentType(content.s3FileUrl);
        return { ...res, [fileType]: [...res[fileType], content] };
      },
      {
        [ContentType.VIDEO]: [],
        [ContentType.PDF]: [],
        [ContentType.TEXT]: [],
      },
    ),
    [tabData],
  );

  return (
    <div className="border border-top-0 p-3">
      <FileContentList
        title="Video"
        contentList={contentData[ContentType.VIDEO]}
        previewIcon={<BsPlayFill className="h-50 w-50" />}
      />
      <FileContentList
        title="PDF"
        contentList={contentData[ContentType.PDF]}
        previewIcon={<BsFilePdf className="h-50 w-50" />}
      />
      <Stack gap={4} className="pt-4">
        {contentData[ContentType.TEXT].map((content) => (
          <Card
            key={content.id}
            as={Link}
            to={`view/${content.id}`}
            className="text-decoration-none"
          >
            <Card.Body>
              <Card.Title className="text-decoration-underline">
                {content.title}
              </Card.Title>
              <Card.Text>{content.description}</Card.Text>
              {isBdataAmin && (
                <Button
                  variant="secondary"
                  onClick={(e) => {
                    e.preventDefault();
                    navigate(content.id);
                  }}
                >
                  Edit
                </Button>
              )}
            </Card.Body>
          </Card>
        ))}
      </Stack>
    </div>
  );
};

TrainingTab.propTypes = {
  catId: PropTypes.string,
  contents: PropTypes.arrayOf(PropTypes.shape({})),
};

export default TrainingTab;
