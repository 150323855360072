import React from 'react';
import { useParams } from 'react-router-dom';

import { Stack } from 'react-bootstrap';

import useReports from 'hooks/useReports';

import SectionWrapper from 'components/layout/SectionWrapper';
import ReportViewMenu from 'components/templates/menus/ReportViewMenu';

import ReportDisplay from './ReportDisplay';

const ReportDisplayPage = () => {
  const { reportId } = useParams();
  const { data } = useReports();

  const displayItem = React.useMemo(
    () => data?.data?.find(({ id }) => id === reportId),
    [data, reportId],
  );

  return (
    <SectionWrapper
      // title={displayItem?.name}
      subTitle={displayItem?.dataInclusionNote}
    >
      <Stack direction="horizontal" gap={3} className="align-items-start">
        <ReportViewMenu />
        <div className="d-flex flex-grow-1 flex-column">
          <ReportDisplay reportId={reportId} />
        </div>
      </Stack>
    </SectionWrapper>
  );
};

export default ReportDisplayPage;
