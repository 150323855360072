import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';

import {
  BsChevronLeft,
  BsChevronRight,
  BsChevronDoubleLeft,
  BsChevronDoubleRight,
} from 'react-icons/bs';

import styled from '@emotion/styled';

const PaginationContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
  button {
    border-radius: 0;
    &:first-of-type {
      border-radius: 8px 0 0 8px;
    }
    &:last-child {
      border-radius: 0 8px 8px 0;
    }
  }
`;

function Pagination({
  page, total, limit, onPageChange,
}) {
  if (!total || total < limit) return null;
  const totalPages = Math.ceil(total / limit);
  const isFirst = page === 0;
  const isLast = page + 1 === totalPages;
  return (
    <PaginationContainer>
      <Button
        variant="outline-primary"
        disabled={isFirst}
        onClick={() => onPageChange(0)}
      >
        <BsChevronDoubleLeft />
      </Button>
      <Button
        variant="outline-primary"
        disabled={isFirst}
        onClick={() => onPageChange(page - 1)}
      >
        <BsChevronLeft />
      </Button>
      {[...Array(totalPages).keys()].map((pageIndex) => (
        <Button
          key={pageIndex}
          onClick={() => onPageChange(pageIndex)}
          variant={pageIndex === page ? 'primary' : 'outline-primary'}
          disabled={pageIndex === page}
        >
          {pageIndex + 1}
        </Button>
      ))}
      <Button
        variant="outline-primary"
        disabled={isLast}
        onClick={() => onPageChange(page + 1)}
      >
        <BsChevronRight />
      </Button>
      <Button
        variant="outline-primary"
        disabled={isLast}
        onClick={() => onPageChange(totalPages - 1)}
      >
        <BsChevronDoubleRight />
      </Button>
    </PaginationContainer>
  );
}

Pagination.propTypes = {
  page: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  limit: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
};

export default Pagination;
