import React from 'react';

import { Stack } from 'react-bootstrap';

import useReports from 'hooks/useReports';

import SectionWrapper from 'components/layout/SectionWrapper';
import ReportViewMenu from 'components/templates/menus/ReportViewMenu';

import ReportDisplay from './ReportDisplay';

const HomePage = () => {
  const { data } = useReports();

  const reportId = React.useMemo(
    () => data?.data?.filter((x) => x.group === 'Home')?.[0]?.id,
    [data],
  );

  return (
    <SectionWrapper>
      <Stack direction="horizontal" gap={3} className="align-items-start">
        <ReportViewMenu />
        <div className="d-flex flex-grow-1 flex-column">
          {!!reportId && <ReportDisplay reportId={reportId} />}
        </div>
      </Stack>
    </SectionWrapper>
  );
};

export default HomePage;
