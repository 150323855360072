import React from 'react';
import PropTypes from 'prop-types';

import {
  ProgressWrapper,
  ProgressContainer,
  ProgressPercentage,
  ProgressBar,
} from './SurveyProgress.styles';

const getProgress = (pages) => pages.reduce(
  (res, page) => {
    if (!page.visible) return res;

    const questionProgress = page.questions.reduce(
      (questionRes, question) => {
        if (!question.visible) return questionRes;
        const questionType = question.getType();
        return {
          total: questionRes.total + 1,
          answered:
              questionType !== 'html' && question.isEmpty()
                ? questionRes.answered
                : questionRes.answered + 1,
        };
      },
      res,
    );

    return questionProgress;
  },
  { total: 0, answered: 0 },
);

const SurveyProgress = ({ pages }) => {
  const progress = getProgress(pages);

  const percentage = React.useMemo(
    () => progress.total && Math.floor((progress.answered / progress.total) * 100),
    [progress],
  );

  return (
    <ProgressWrapper>
      <ProgressContainer>
        <ProgressBar now={progress.answered} min={0} max={progress.total} />
      </ProgressContainer>
      <ProgressPercentage>{`${percentage}%`}</ProgressPercentage>
    </ProgressWrapper>
  );
};

SurveyProgress.propTypes = {
  pages: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default SurveyProgress;
