import React from 'react';

const useTableState = (
  initialSortBy,
  initialSortOrder = 'asc',
  defaultFilters = {},
) => {
  const [page, setPage] = React.useState(0);
  const [sortOrder, setSortOrder] = React.useState(initialSortOrder);
  const [sortBy, setSortBy] = React.useState(initialSortBy);
  const [filters, setFilters] = React.useState(defaultFilters);

  const handleFilterChange = React.useCallback(
    (name, value) => {
      setPage(0);
      setFilters({ ...filters, [name]: value });
    },
    [filters],
  );

  const handleFiltersReplace = React.useCallback((newFilters) => {
    setPage(0);
    setFilters(newFilters);
  }, []);

  const handlePageChange = React.useCallback((value) => setPage(value), []);

  const handleSortChange = React.useCallback(
    (sortField) => {
      if (sortField === sortBy) {
        setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
      } else {
        setSortBy(sortField);
        setSortOrder(initialSortOrder);
      }
    },
    [initialSortOrder, sortBy, sortOrder],
  );

  const clearTableState = React.useCallback(() => {
    setPage(0);
    setFilters(defaultFilters);
  }, [defaultFilters]);

  return {
    page,
    sortBy,
    sortOrder,
    filters,
    handleFilterChange,
    handleFiltersReplace,
    handlePageChange,
    handleSortChange,
    clearTableState,
  };
};

export default useTableState;
