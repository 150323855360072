import styled from '@emotion/styled';

export const CalendarContainer = styled.div`
  .react-datepicker__day {
    cursor: default;
    &:hover {
      background: none;
    }
  }
  .react-datepicker__day--keyboard-selected {
    background: white;
    color: black;
  }
`;

export const DayContainer = styled.div`
  background: var(--bs-secondary);
  color: white;
  cursor: pointer;
  border-radius: 50%;
`;
