import React from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';

import RichTextComponent from './RichTextComponent';

const RichTextControlledInput = ({ name, control, disabled }) => (
  <Controller
    control={control}
    name={name}
    render={({ field: { onChange, value } }) => (
      <RichTextComponent
        disabled={disabled}
        value={value}
        init={{
          height: 500,
          menubar: true,
          plugins: [
            'anchor',
            'autolink',
            'charmap',
            'code',
            'help',
            'image',
            'insertdatetime',
            'link',
            'lists',
            'searchreplace',
            'table',
            'visualblocks',
          ],
          toolbar:
            'undo redo | image link pageembed  | blocks | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist checklist outdent indent | removeformat | table help',
        }}
        onEditorChange={onChange}
      />
    )}
  />
);

RichTextControlledInput.propTypes = {
  name: PropTypes.string.isRequired,
  control: PropTypes.shape({}).isRequired,
  disabled: PropTypes.bool,
};

export default RichTextControlledInput;
