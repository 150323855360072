import React from 'react';
import { Link, useLocation } from 'react-router-dom';

import styled from '@emotion/styled';
import { Button, Stack } from 'react-bootstrap';

import colors from 'resources/styles/colors';
import FontAwesomeIcon from 'components/elements/FontAwesomeIcon';

import useCurrentApplication from 'hooks/useCurrentApplication';
import { formTitle, programType, programSubType } from 'helpers/forms';
import { FormRoutes } from 'helpers/routes';
import { formatDate } from 'utils/dateTime';

const FormTabs = styled.div`
  display: flex;
  .fa {
    font-size: 4rem;
  }
`;

const InfoTag = styled.div`
  background-color: ${colors.primaryColor};
  color: ${colors.white};
  padding: 1px 0.2rem;
  text-decoration: none;
`;

const ButtonLabel = styled.div`
  text-decoration: none !important;
  font-weight: bold;
  margin-bottom: 10px;
`;

const getTag = (date) => (date ? `Submitted on ${formatDate(date)}` : 'Not Yet Submitted');

const FormsPage = () => {
  const { appData } = useCurrentApplication();
  const { pathname } = useLocation();

  const sections = React.useMemo(
    () => [
      {
        route: FormRoutes.initApp,
        icon: 'pencil-square-o',
        label: formTitle.initialApplication,
        show: true,
        info: <InfoTag>{`BCVU ${getTag(appData.requestedAt)}`}</InfoTag>,
      },
      {
        route: FormRoutes.prq,
        icon: 'tasks',
        label: formTitle.prq,
        show: appData.requestedAt,
        info: (
          <Stack gap={1}>
            <InfoTag>{`BCVU ${getTag(appData.prqSubmittedAt)}`}</InfoTag>
            <InfoTag>{`SR ${getTag(appData.prqSrSubmittedAt)}`}</InfoTag>
          </Stack>
        ),
      },
      {
        route: FormRoutes.meetingRounds,
        icon: 'calendar-check-o',
        label: formTitle.meetingRounds,
        show:
          Boolean(appData.prqSubmittedAt)
          && appData.programTypeId !== programType.FELLOWSHIP
          && appData.programSubtypeId !== programSubType.FOCUS_REVIEW,
        info: (
          <Stack gap={1}>
            <InfoTag>
              {`BCVU ${getTag(appData.meetingsAndRoundsSubmittedAt)}`}
            </InfoTag>
            <InfoTag>
              {`SR ${getTag(appData.meetingsAndRoundsSrSubmittedAt)}`}
            </InfoTag>
          </Stack>
        ),
      },
      {
        route: FormRoutes.keyFindings,
        icon: 'file-text',
        label: formTitle.keyFindings,
        show:
          Boolean(appData.prqSubmittedAt)
          && appData.programSubtypeId !== programSubType.FOCUS_REVIEW,
        info: (
          <InfoTag>{`SR ${getTag(appData.keyFindingsSubmittedAt)}`}</InfoTag>
        ),
      },
      {
        route: FormRoutes.finalReport,
        icon: 'check-square-o',
        label: formTitle.siteReport,
        show:
          Boolean(appData.prqSubmittedAt)
          && appData.programSubtypeId !== programSubType.FOCUS_REVIEW,
      },
    ],
    [appData],
  );

  return (
    <FormTabs>
      {sections.map(
        ({
          route, label, icon, show, info,
        }) => show && (
        <Link
          key={route}
          to={route}
          as={Button}
          state={{ backTo: pathname }}
        >
          <Button style={{ width: 180 }} variant="link">
            <FontAwesomeIcon className={`fa-${icon}`} />
            <ButtonLabel>{label}</ButtonLabel>
            {info}
          </Button>
        </Link>
        ),
      )}
    </FormTabs>
  );
};

export default FormsPage;
