import { combineReducers } from 'redux';

import commentCount from './commentCount';
import currentComments from './currentComments';
import reviewerComments from './reviewerComments';

const reducers = combineReducers({
  commentCount,
  currentComments,
  reviewerComments,
});

export default reducers;
