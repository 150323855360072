import React from 'react';

import DatePickerForm from 'components/elements/DatePickerForm';

import useCurrentApplication from 'hooks/useCurrentApplication';

const SiteVisitDateForm = () => {
  const { appData, updateSiteVisitDate } = useCurrentApplication();

  return (
    <DatePickerForm
      label="Site Visit Date"
      defaultValue={appData.siteVisitAt}
      onSave={updateSiteVisitDate}
    />
  );
};

export default SiteVisitDateForm;
