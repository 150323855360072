import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';

const SVButton = ({ children, ...props }) => (
  <Button {...props}>{children}</Button>
);

SVButton.propTypes = {
  children: PropTypes.node.isRequired,
};

export default SVButton;
