import { verificationRoutes } from 'helpers/routes';
import { appRoles } from 'utils/enums';

const userTypeIDs = {
  VERIFICATION: 1,
  SITE_REVIEWER: 2,
};

export const USER_TYPES = {
  [verificationRoutes.VERIFICATION]: userTypeIDs.VERIFICATION,
  [verificationRoutes.REVIWER]: userTypeIDs.SITE_REVIEWER,
};

export const ADMIN_ROLES = [
  appRoles.abaADMIN,
  appRoles.bdataADMIN,
  appRoles.qualityAssurance,
  appRoles.qualityImprovement,
];
