import React from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import api from 'api';
import useRequest from 'hooks/useRequest';
import useMountEffect from 'hooks/useMountEffect';

import { HistoryBackButton } from 'components/elements/BackButton';
import Loader from 'components/elements/Loader';
import SectionWrapper from 'components/layout/SectionWrapper';
import RichTextDisplay from 'components/elements/FormInput/richText/RichTextDisplay';

import { ContentType, getContentType } from '../elements/utils';

const ItemFileDisplay = ({ displayItem }) => {
  const fileType = getContentType(displayItem?.s3FileUrl);
  const downloadURL = displayItem?.s3FileDownloadUrl;
  switch (fileType) {
    case ContentType.VIDEO:
      return <video src={downloadURL} controls autoPlay width="100%" />;
    case ContentType.PDF:
      return (
        <object
          data={downloadURL}
          type="application/pdf"
          style={{ width: '100%', height: '100vh' }}
        >
          <div>
            <p>Unable to display PDF file.</p>
            <a target="_blank" rel="noreferrer" href={downloadURL}>
              Download File
            </a>
          </div>
          {' '}
        </object>
      );
    default:
      return null;
  }
};

ItemFileDisplay.propTypes = {
  displayItem: PropTypes.shape({
    s3FileUrl: PropTypes.string,
    s3FileDownloadUrl: PropTypes.string,
  }),
};

const TrainingItemView = () => {
  const { itemId } = useParams();
  const { data: fetchedContent, doRequest: fetchContent } = useRequest(
    api.getContent,
  );
  const contentData = useSelector((state) => state.contents.data);

  const contentFromList = React.useMemo(
    () => contentData.find(({ id }) => id === itemId),
    [contentData, itemId],
  );

  useMountEffect(() => {
    window.scrollTo(0, 0);
    if (contentFromList) return;
    fetchContent(itemId);
  });

  const displayItem = React.useMemo(
    () => contentFromList || fetchedContent,
    [contentFromList, fetchedContent],
  );

  return (
    <SectionWrapper>
      <div className="mb-4">
        <HistoryBackButton />
      </div>
      {displayItem ? (
        <div>
          <h3 className="text-body">{displayItem.title}</h3>
          <h4 className="text-muted mb-4">{displayItem.description}</h4>
          {displayItem?.s3FileDownloadUrl && (
            <div className="mb-4">
              <ItemFileDisplay displayItem={displayItem} />
            </div>
          )}
          <RichTextDisplay value={displayItem?.content} />
        </div>
      ) : (
        <Loader />
      )}
    </SectionWrapper>
  );
};

export default TrainingItemView;
