import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import api from 'api';
import useFeedback from 'hooks/useFeedback';
import { verificationRoutes } from 'helpers/routes';
import { USER_TYPES } from 'helpers/roles';

import {
  getApplication,
  updateApplicationData,
} from 'store/actions/applications';

const useCurrentApplication = () => {
  const dispatch = useDispatch();
  const { appId, role } = useParams();
  const { setSuccess, setError } = useFeedback();

  const appGet = React.useCallback(() => {
    dispatch(getApplication(appId));
  }, [dispatch, appId]);

  const appDelete = React.useCallback(
    (cb) => {
      api.deleteApplication(appId).then(cb);
    },
    [appId],
  );

  const updateSiteVisitDate = React.useCallback(
    async ({ date }) => {
      const updateData = { siteVisitAt: date };
      try {
        await api.setSiteVisitDate({
          appId,
          ...updateData,
        });
        dispatch(updateApplicationData(updateData));
        setSuccess('Site Visit Date saved');
      } catch ({ response }) {
        setError('Error saving Site Visit Date');
      }
    },
    [appId, dispatch, setSuccess, setError],
  );

  const updateExpirationDate = React.useCallback(
    async ({ date }) => {
      const updateData = { expiredAt: date };
      try {
        await api.setExpirationDate({
          appId,
          ...updateData,
        });
        dispatch(updateApplicationData(updateData));
        setSuccess('Expiration Date saved');
      } catch ({ response }) {
        setError('Error saving Expiration Date');
      }
    },
    [appId, dispatch, setSuccess, setError],
  );

  const { data, isLoading } = useSelector(
    ({ applications }) => applications.currentApplication,
  );

  const isReviewer = React.useMemo(
    () => role === verificationRoutes.REVIWER,
    [role],
  );

  const isVerification = React.useMemo(
    () => role === verificationRoutes.VERIFICATION,
    [role],
  );

  const userTypeId = React.useMemo(() => USER_TYPES[role], [role]);

  return {
    appGet,
    appDelete,
    updateSiteVisitDate,
    updateExpirationDate,
    isLoading,
    role,
    isReviewer,
    isVerification,
    userTypeId,
    appData: data,
  };
};

export default useCurrentApplication;
