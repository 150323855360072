import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { ToastContainer, Toast } from 'react-bootstrap';

import colors from 'resources/styles/colors';
import { cleanFeedBack } from 'store/feedback/actions';

const SnackBar = () => {
  const feedback = useSelector((state) => state.feedback);
  const dispatch = useDispatch();

  const feedbackColor = React.useMemo(() => {
    switch (feedback?.status) {
      case 'error':
        return {
          backgroundColor: colors.red,
          color: 'white',
        };
      case 'success':
        return {
          backgroundColor: colors.green,
          color: 'white',
        };
      default:
        return {};
    }
  }, [feedback?.status]);

  return (
    <ToastContainer position="top-center" className="p-3" style={{ zIndex: 1 }}>
      <Toast
        show={!!feedback}
        onClose={() => dispatch(cleanFeedBack())}
        delay={7000}
        autohide
      >
        <Toast.Header style={feedbackColor} closeVariant="white">
          <strong className="me-auto">{feedback?.msg}</strong>
        </Toast.Header>
      </Toast>
    </ToastContainer>
  );
};

export default SnackBar;
