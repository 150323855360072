import React from 'react';
import { Link, useNavigate } from 'react-router-dom';

import api from 'api';
import useTableState from 'hooks/useTableState';
import useRequest from 'hooks/useRequest';
import useMountEffect from 'hooks/useMountEffect';
import useUpdateEffect from 'hooks/useUpdateEffect';
import useUser from 'hooks/useUser';
import { formatDate } from 'utils/dateTime';

import SectionWrapper from 'components/layout/SectionWrapper';
import Table from 'components/elements/Table';
import SVButton from 'components/elements/SVButton';
import { FlexDiv } from 'resources/styles/display';

const columns = [
  {
    id: 'type',
    label: 'Type of Data',
    headerStyle: { width: '15%' },
  },
  {
    id: 'filename',
    label: 'File Name',
    headerStyle: { width: '15%' },
  },
  {
    id: 'description',
    label: 'Description',
  },
  {
    id: 'burnCenter',
    label: 'Burn Center',
    override: ({ burnCenterName }) => burnCenterName,
  },
  {
    id: 'user',
    label: 'User',
    override: ({ userName }) => userName,
    headerStyle: { width: '15%' },
  },
  {
    id: 'createdAt',
    label: 'Created At',
    override: ({ createdAt }) => formatDate(createdAt, 'LLL'),
    headerStyle: { width: '8rem' },
  },
];

const UploadListPage = () => {
  const navigate = useNavigate();
  const { appUser } = useUser();
  const { doRequest, data, isLoading } = useRequest(api.getUploads);
  const {
    page, handlePageChange, sortBy, sortOrder, handleSortChange,
  } = useTableState('createdAt', 'desc', {});

  const fetchList = React.useCallback(() => {
    doRequest({
      page,
      sortBy,
      sortOrder,
    });
  }, [page, sortBy, sortOrder, doRequest]);

  useMountEffect(fetchList);

  useUpdateEffect(() => {
    fetchList();
  }, [fetchList]);

  return (
    <SectionWrapper title="Uploaded Files">
      {appUser?.belongsTo && (
        <FlexDiv className="justify-content-end mb-2">
          <SVButton variant="secondary" size="sm" as={Link} to="new">
            Upload Data Files
          </SVButton>
        </FlexDiv>
      )}
      <Table
        columns={columns}
        items={data?.data || []}
        handlePageChange={handlePageChange}
        handleOrderChange={handleSortChange}
        order={sortBy}
        orderDirection={sortOrder}
        pagination={{
          page,
          total: data?.count || 0,
          limit: data?.pageSize || 0,
        }}
        onClickRow={({ id }) => navigate(id)}
        isLoading={isLoading}
      />
    </SectionWrapper>
  );
};

export default UploadListPage;
