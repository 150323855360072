import React from 'react';
import { Link } from 'react-router-dom';

import Image from 'react-bootstrap/Image';

import useUser from 'hooks/useUser';
import { MainRoutes } from 'helpers/routes';

import NavMenu from './NavMenu';

const { REACT_APP_BDATA_HOST_URL } = process.env;

const AppHeader = () => {
  const { appUser } = useUser();
  return (
    <div>
      <div className="d-flex justify-content-between p-2">
        <a rel="noopener noreferrer" href={REACT_APP_BDATA_HOST_URL}>
          <Image src="/assets/aba_burn_care_logo.svg" height="86" />
        </a>
        <div className="d-flex flex-column align-items-end">
          <Link to={`${MainRoutes.TRAINING}?cat=DataDictionary`}>
            <Image src="/assets/data_dictionary.png" height="59" />
          </Link>
          {appUser?.name && <span>{`Hello, ${appUser.name}`}</span>}
        </div>
      </div>
      <NavMenu />
    </div>
  );
};

export default AppHeader;
