import { combineReducers } from 'redux';

import appAuth from './auth/reducer';
import feedback from './feedback/reducer';
import applications from './applications';
import comments from './comments';
import reports from './reports/reducer';
import surveys from './surveys/reducer';
import users from './users/reducer';
import contents from './contents/reducer';

const reducers = combineReducers({
  appAuth,
  feedback,
  applications,
  comments,
  reports,
  surveys,
  users,
  contents,
});

export default reducers;
