import React from 'react';

import useCurrentApplication from 'hooks/useCurrentApplication';
import { formatDate } from 'utils/dateTime';
import { getStateLabel, getStatusLabel } from 'helpers/forms';

import {
  MenuItem,
  MenuItemTitle,
  MenuItemBody,
} from 'components/templates/menus/styles';

const VerificationMenu = () => {
  const { appData } = useCurrentApplication();

  const menuItems = React.useMemo(
    () => [
      { title: 'Application Date', body: formatDate(appData.requestedAt) },
      { title: 'Review Status', body: getStateLabel(appData.stateId) },
      { title: 'Site Review Date', body: formatDate(appData.siteVisitAt) },
      { title: 'Final Determination', body: getStatusLabel(appData.statusId) },
      { title: 'Expiration Date', body: formatDate(appData.expiredAt) },
    ],
    [appData],
  );

  return (
    <div className="p-5 shadow border">
      {menuItems.map(({ title: itemTile, body }) => (
        <MenuItem key={itemTile}>
          {itemTile && <MenuItemTitle>{itemTile}</MenuItemTitle>}
          {body && <MenuItemBody>{body}</MenuItemBody>}
        </MenuItem>
      ))}
    </div>
  );
};

export default VerificationMenu;
