import styled from '@emotion/styled';

export default styled.div`
  display: flex;
  min-height: 100vh;
  font-family: "Inter", sans-serif;
  flex-direction: column;
  font-weight: 400;
  line-height: 1.5;
`;
