import Select from 'react-select';
import styled from '@emotion/styled';

import colors from 'resources/styles/colors';

export const UserSelect = styled(Select)`
  width: 300px;
  margin-right: 0.6rem;
`;

export const UserListItem = styled.div`
  display: flex;
  padding: 0 0 0 1rem;
  gap: 0.5rem;
  justify-content: center;
  align-items: center;
  background: ${colors.primaryColor};
  color: white;
`;
