import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';

import { programType } from 'helpers/forms';
import SVButton from 'components/elements/SVButton';

export default function PRQSubmitConfirmation({
  onSubmit,
  onCancel,
  programTypeId,
}) {
  const [medical, setMedical] = useState(false);
  const [satisfied, setSatisfied] = useState(null);

  const canSubmit = medical && satisfied;

  return (
    <Modal show onHide={onCancel}>
      <Modal.Header closeButton>
        <Modal.Title>Final Submit</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          Are you sure your form is complete and you are ready to submit the
          form for review? This cannot be undone.
        </p>
        <p>
          <input
            type="checkbox"
            style={{ marginRight: '1rem' }}
            onChange={({ target }) => setMedical(target.checked)}
          />
          {'A '}
          {programTypeId === programType.FELLOWSHIP ? 'Fellowship' : 'Medical'}
          {' Director has signed-off on the answers given in this form'}
        </p>
        <p>
          <input
            type="checkbox"
            style={{ marginRight: '1rem' }}
            onChange={({ target }) => setSatisfied(target.checked)}
          />
          I am satisfied with the answers given in this form and wish to submit
          for review
        </p>
      </Modal.Body>
      <Modal.Footer>
        <SVButton size="sm" variant="cancel" onClick={onCancel}>
          Cancel
        </SVButton>
        <SVButton
          size="sm"
          variant="secondary"
          onClick={onSubmit}
          disabled={!canSubmit}
        >
          Submit
        </SVButton>
      </Modal.Footer>
    </Modal>
  );
}

PRQSubmitConfirmation.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  programTypeId: PropTypes.number.isRequired,
};
