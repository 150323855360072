import fileDownload from 'js-file-download';

import api from 'api';
import { setSuccessFeedback, setErrorFeedback } from 'store/feedback/actions';

export const GET_FILES = 'GET_FILES';
export const GET_FILES_SUCCESS = 'GET_FILES_SUCCESS';
export const GET_FILES_ERROR = 'GET_FILES_ERROR';

export const UPLOAD_FILES = 'UPLOAD_FILES';
export const UPLOAD_FILES_SUCCESS = 'UPLOAD_FILES_SUCCESS';
export const UPLOAD_FILES_ERROR = 'UPLOAD_FILES_ERROR';

export const DELETED_FILE_SUCCESS = 'DELETED_FILE_SUCCESS';

export function getApplicationFiles(appId, params) {
  return (dispatch) => {
    dispatch({ type: GET_FILES });
    api
      .getAppFiles({ appId, params })
      .then(({ data }) => dispatch({ type: GET_FILES_SUCCESS, data }))
      .catch(() => dispatch({ type: GET_FILES_ERROR, error: 'Could not retrive files' }));
  };
}

export function uploadApplicationFile(appId, file, cb) {
  return (dispatch) => {
    if (!file) {
      dispatch(setErrorFeedback('Please select a file'));
      return;
    }
    dispatch({ type: UPLOAD_FILES });
    api
      .uploadAppFile({ appId, file })
      .then(({ data }) => {
        dispatch({ type: UPLOAD_FILES_SUCCESS, data });
        dispatch(setSuccessFeedback('File uploaded successfully'));
        if (cb) cb();
      })
      .catch(() => {
        dispatch({ type: UPLOAD_FILES_ERROR });
        dispatch(setErrorFeedback('Error uploading file'));
      });
  };
}

export function deleteApplicationFile(fileId) {
  return (dispatch) => {
    api
      .deleteFile(fileId)
      .then(() => {
        dispatch({ type: DELETED_FILE_SUCCESS, fileId });
        dispatch(setSuccessFeedback('File deleted successfully'));
      })
      .catch(() => dispatch(setErrorFeedback('Error deleting file')));
  };
}

export function downloadFile({ id, fileName }) {
  return (dispatch) => {
    api
      .getFile(id)
      .then(({ data }) => fileDownload(data, fileName))
      .catch(() => dispatch(setErrorFeedback('Error downloading file')));
  };
}

export function downloadDataFile({ data, fileName }) {
  const tempLink = document.createElement('a');
  tempLink.style.display = 'none';
  tempLink.href = data;
  tempLink.setAttribute('download', fileName);
  document.body.appendChild(tempLink);
  tempLink.click();
  setTimeout(() => {
    document.body.removeChild(tempLink);
    window.URL.revokeObjectURL(data);
  }, 200);
}
