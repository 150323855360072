import React from 'react';
import PropTypes from 'prop-types';

import CriteriaTab from './CriteriaTab';

const CriteriaSection = ({
  model,
  sectionId,
  onPageChanged,
}) => model.visiblePages.map((page) => page.name.startsWith(`${sectionId}_`) && (
  <CriteriaTab
    key={page.name}
    isCurrent={model.currentPage.name === page.name}
    page={page}
    onTabClick={onPageChanged}
  />
));

CriteriaSection.propTypes = {
  model: PropTypes.shape({
    currentPage: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }).isRequired,
    visiblePages: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  }).isRequired,
  sectionId: PropTypes.number.isRequired,
  onPageChanged: PropTypes.func.isRequired,
};

export default CriteriaSection;
