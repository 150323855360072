import update from 'immutability-helper';
import { findIndex } from 'lodash';
import {
  GET_FILES,
  GET_FILES_SUCCESS,
  GET_FILES_ERROR,
  UPLOAD_FILES,
  UPLOAD_FILES_SUCCESS,
  UPLOAD_FILES_ERROR,
  DELETED_FILE_SUCCESS,
} from '../actions/files';

const initialState = {
  isLoading: true,
  isUploading: false,
  data: {},
};

export default function files(state = initialState, action) {
  switch (action.type) {
    case GET_FILES:
      return initialState;
    case GET_FILES_SUCCESS:
      return { isLoading: false, data: action.data };
    case GET_FILES_ERROR:
      return { isLoading: false, error: action.error };
    case UPLOAD_FILES:
      return { ...state, isUploading: true };
    case UPLOAD_FILES_SUCCESS:
      return update(state, {
        isUploading: { $set: false },
        data: { items: { $unshift: [action.data] } },
      });
    case UPLOAD_FILES_ERROR:
      return { ...state, isUploading: false };
    case DELETED_FILE_SUCCESS: {
      const deleteIndex = findIndex(state.data.items, { id: action.fileId });
      return update(state, {
        data: { items: { $splice: [[deleteIndex, 1]] } },
      });
    }
    default:
      return state;
  }
}
