import React from 'react';
import PropTypes from 'prop-types';

import { BsCheckLg, BsXLg } from 'react-icons/bs';
import { Stack } from 'react-bootstrap';

import Button from 'components/elements/SVButton';

const SurveyNameEditor = ({
  survey,
  editingId,
  setEditingId,
  onUpdateSurveyName,
}) => {
  const inputRef = React.useRef(null);

  return editingId === survey.id ? (
    <Stack direction="horizontal" gap={2}>
      <Button
        variant="danger"
        size="sm"
        onClick={(e) => {
          e.stopPropagation();
          setEditingId(null);
        }}
      >
        <BsXLg />
      </Button>
      <Button
        variant="success"
        size="sm"
        onClick={(e) => {
          e.stopPropagation();
          onUpdateSurveyName({
            surveyId: survey.id,
            name: inputRef.current.value,
          });
        }}
      >
        <BsCheckLg />
      </Button>
      <input
        ref={inputRef}
        defaultValue={survey.name}
        style={{ width: '100%' }}
        type="text"
        onClick={(e) => e.stopPropagation()}
      />
    </Stack>
  ) : (
    <Stack direction="horizontal" gap={2}>
      <Button
        size="sm"
        onClick={(e) => {
          e.stopPropagation();
          setEditingId(survey.id);
        }}
      >
        edit
      </Button>
      <span>{survey.name}</span>
    </Stack>
  );
};

SurveyNameEditor.propTypes = {
  survey: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }).isRequired,
  editingId: PropTypes.number,
  setEditingId: PropTypes.func.isRequired,
  onUpdateSurveyName: PropTypes.func.isRequired,
};

export default SurveyNameEditor;
