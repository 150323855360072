import React from 'react';

import api from 'api';
import useUser from 'hooks/useUser';
import useFeedback from 'hooks/useFeedback';
import useCurrentApplication from 'hooks/useCurrentApplication';
import useGoBack from 'hooks/useGoBack';
import { formType, formTitle } from 'helpers/forms';

import SectionWrapper from 'components/layout/SectionWrapper';
import { HistoryBackButton } from 'components/elements/BackButton';
import SurveyPDFExport from 'components/forms/helpers/SurveyPDFExport';
import BasicModal from 'components/elements/BasicModal';
import ESSubmitConfirmation from 'components/forms/ExecutiveSession/ESSubmitConfirmation';

import PartialResultForm from '../helpers/PartialResultForm';

const ExecutiveSession = () => {
  const { appData, isReviewer, isVerification } = useCurrentApplication();
  const { isAdmin } = useUser();
  const [submitModal, setSubmitModal] = React.useState(false);
  const { setError } = useFeedback();
  const { goBack } = useGoBack();

  const canEdit = React.useMemo(
    () => isVerification && !appData.meetingsAndRoundsSubmittedAt,
    [isVerification, appData],
  );

  const handleSubmit = () => {
    const submitFunc = isReviewer ? api.submitESReview : api.submitES;
    submitFunc(appData.id)
      .then(() => goBack())
      .catch(() => {
        setError('Error submitting PRQ');
        setSubmitModal(null);
      });
  };

  return (
    <SectionWrapper
      title={`${appData.bcqpBurnCenterName} - ${formTitle.meetingRounds}`}
    >
      {submitModal
        && (isReviewer ? (
          <BasicModal
            title={`Is your review of the '${formTitle.meetingRounds}' form complete?`}
            body="You will not be able to make changes after you submit."
            isOpen
            onClose={() => setSubmitModal(null)}
            onConfirm={() => handleSubmit()}
          />
        ) : (
          <ESSubmitConfirmation
            onCancel={() => setSubmitModal(null)}
            onSubmit={() => handleSubmit()}
          />
        ))}
      <div className="d-flex justify-content-between">
        <HistoryBackButton />
        <SurveyPDFExport surveyVersionId={appData.meetingsAndRoundsVersionId} />
      </div>
      <PartialResultForm
        canEdit={canEdit}
        onSubmit={() => setSubmitModal(true)}
        surveyVersionId={appData.meetingsAndRoundsVersionId}
        formType={formType.MeetingsAndRounds}
        uploadFileFunc={api.uploadESFile}
        showReviwerComments={isAdmin || isReviewer}
        readOnlyReviwerComments={!!appData.meetingsAndRoundsSrSubmittedAt}
        submittedAt={appData.meetingsAndRoundsSubmittedAt}
      />
    </SectionWrapper>
  );
};

export default ExecutiveSession;
