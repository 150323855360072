import React from 'react';
import ReactDOM from 'react-dom/client';

import PageDescClamp from './PageDescClamp';

export default function SurveyPageOverrider(options) {
  const descItem = options.htmlElement.querySelector('.sv-description');
  if (!descItem) return;
  const element = document.createElement('div');
  descItem.replaceChild(element, descItem.childNodes[0]);
  const root = ReactDOM.createRoot(element);
  root.render(<PageDescClamp text={options.page.description} />);
}
