import { useEffect, useRef } from 'react';

const useMountEffect = (effectFunc) => {
  const canEffect = useRef(true);
  useEffect(() => {
    if (!canEffect.current) return;
    canEffect.current = false;
    effectFunc();
  });
};

export default useMountEffect;
