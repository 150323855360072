import React from 'react';

import Stack from 'react-bootstrap/Stack';

import api from 'api';
import useMountEffect from 'hooks/useMountEffect';
import useCurrentApplication from 'hooks/useCurrentApplication';
import { formTitle, getProgramTypeLabel } from 'helpers/forms';

import SectionWrapper from 'components/layout/SectionWrapper';
import SVButton from 'components/elements/SVButton';
import { HistoryBackButton } from 'components/elements/BackButton';

import FinalReportScreen from './FinalReportScreen';

const FinalReport = () => {
  const { appData } = useCurrentApplication();
  const [pdf, setPdf] = React.useState(null);

  useMountEffect(() => {
    api.downloadFinalReport(appData.id).then(({ data }) => {
      const url = window.URL.createObjectURL(new Blob([data]));
      setPdf(url);
    });
  });

  return (
    <SectionWrapper
      title={`${appData.bcqpBurnCenterName} - ${getProgramTypeLabel(
        appData.programTypeId,
      )} ${formTitle.siteReport}`}
    >
      <div className="d-flex justify-content-between">
        <div>
          <HistoryBackButton />
        </div>
        <Stack direction="horizontal" gap={2}>
          <SVButton
            disabled={!pdf}
            download={`Verification Site Report - ${appData.bcqpBurnCenterName}.pdf`}
            href={pdf}
          >
            Download Site Report
          </SVButton>
        </Stack>
      </div>
      <FinalReportScreen pdf={pdf} />
    </SectionWrapper>
  );
};

export default FinalReport;
