import { useSearchParams } from 'react-router-dom';

// eslint-disable-next-line max-len
const params2Object = (searchParams) => Array.from(searchParams.entries()).reduce((res, [key, value]) => {
  const resValue = res[key];
  if (!resValue) return { ...res, [key]: value };
  return {
    ...res,
    [key]: Array.isArray(resValue) ? [...resValue, value] : [resValue, value],
  };
}, {});

const useQueryParams = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  return {
    queryParams: params2Object(searchParams),
    setQueryParams: (params) => setSearchParams(params, { replace: true }),
  };
};

export default useQueryParams;
