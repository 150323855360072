import Bar from 'react-bootstrap/ProgressBar';

import styled from '@emotion/styled';

import colors from 'resources/styles/colors';

export const ProgressWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0;
`;

export const ProgressContainer = styled.div`
  flex-grow: 1;
`;

export const ProgressBar = styled(Bar)`
  height: 2rem;
  .progress-bar {
    background-color: ${colors.green};
  }
`;

export const ProgressPercentage = styled.div`
  margin-left: 0.5rem;
  color: ${colors.green};
`;
