export default {
  primaryColor: '#264b59',
  secondaryColor: 'rgba(0, 137, 149, 1)',
  secondaryColorLight: 'rgba(0, 137, 149, .6)',
  white: '#FFF',
  red: '#dc3545',
  green: 'rgb(26, 179, 148)',
  gray: '#ccc',
  orange: '#ef8633',
  lightGray: '#ebebeb',
  darkGray: '#555759',
  darkerGray: '#757575',
  tableLine1: 'rgba(38, 75, 89, .05)',
  tableLine2: 'rgba(38, 75, 89, .1)',
};
