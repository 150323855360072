import React from 'react';
import { useDispatch } from 'react-redux';
import {
  Route, Routes, Navigate, useParams,
} from 'react-router-dom';

import { getApplication } from 'store/actions/applications';
import { closeQuestionComments } from 'store/actions/comments';
import { FormRoutes } from 'helpers/routes';
import useCurrentApplication from 'hooks/useCurrentApplication';

import Loader from 'components/elements/Loader';
import InitialApplication from 'components/forms/InitialApplication';
import PRQ from 'components/forms/PRQ';
import ExecutiveSession from 'components/forms/ExecutiveSession';
import ASReport from 'components/forms/ASReport';
import FinalReport from 'components/forms/FinalReport';
import UnauthorizedPage from 'components/layout/UnauthorizedPage';

import VerificationDetail from './VerificationDetail';

const Verification = () => {
  const { appId, role } = useParams();
  const { isLoading } = useCurrentApplication();
  const dispatch = useDispatch();

  const validRole = React.useMemo(
    () => ['reviewer', 'verification'].includes(role),
    [role],
  );

  React.useEffect(() => {
    if (!validRole) return;
    dispatch(getApplication(appId));
    dispatch(closeQuestionComments());
  }, [appId, dispatch, validRole]);

  if (isLoading) return <Loader />;

  return (
    <Routes>
      {validRole && (
        <>
          <Route path="dashboard" element={<VerificationDetail />} />
          <Route index element={<Navigate replace to="dashboard" />} />
          <Route path={FormRoutes.initApp} element={<InitialApplication />} />
          <Route path={FormRoutes.prq} element={<PRQ />} />
          <Route
            path={FormRoutes.meetingRounds}
            element={<ExecutiveSession />}
          />
          <Route path={FormRoutes.keyFindings} element={<ASReport />} />
          <Route path={FormRoutes.finalReport} element={<FinalReport />} />
        </>
      )}
      <Route path="*" exact element={<UnauthorizedPage />} />
    </Routes>
  );
};

export default Verification;
