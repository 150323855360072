import { combineReducers } from 'redux';

import currentApplication from './currentApplication';
import flags from './flags';
import files from './files';

const reducers = combineReducers({
  currentApplication,
  flags,
  files,
});

export default reducers;
