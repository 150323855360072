import React from 'react';

import api from 'api';
import useCurrentApplication from 'hooks/useCurrentApplication';
import useGoBack from 'hooks/useGoBack';
import useFeedback from 'hooks/useFeedback';
import { formType, formTitle, getProgramTypeLabel } from 'helpers/forms';

import SectionWrapper from 'components/layout/SectionWrapper';
import { GoBackButton } from 'components/elements/BackButton';
import ASSubmitConfirmation from 'components/forms/ASReport/ASSubmitConfirmation';

import PartialResultForm from '../helpers/PartialResultForm';

const ASReport = () => {
  const { goBack } = useGoBack();
  const { setError } = useFeedback();
  const { appData } = useCurrentApplication();
  const [submitModal, setSubmitModal] = React.useState(false);

  const title = React.useMemo(() => {
    const { bcqpBurnCenterName, programTypeId } = appData;
    return `${bcqpBurnCenterName} - ${getProgramTypeLabel(programTypeId)} ${
      formTitle.keyFindings
    }`;
  }, [appData]);

  const handleSubmit = () => {
    api
      .submitARS(appData.id)
      .then(() => goBack())
      .catch(() => {
        setError('Error submitting Assembly Site ReportAt');
        setSubmitModal(null);
      });
  };

  return (
    <SectionWrapper title={title}>
      {submitModal && (
        <ASSubmitConfirmation
          onCancel={() => setSubmitModal(false)}
          onSubmit={handleSubmit}
        />
      )}
      <div>
        <GoBackButton onClick={() => goBack()} />
      </div>
      <PartialResultForm
        canEdit
        onSubmit={() => setSubmitModal(true)}
        surveyVersionId={appData.keyFindingsVersionId}
        formType={formType.KeyFindings}
        hasComments={false}
        submittedAt={appData.keyFindingsSubmittedAt}
      />
    </SectionWrapper>
  );
};

export default ASReport;
