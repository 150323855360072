import api from 'api';
import { setErrorFeedback, setSuccessFeedback } from 'store/feedback/actions';

export const GET_APPLICATION = 'GET_APPLICATION';
export const GET_APPLICATION_SUCCESS = 'GET_APPLICATION_SUCCESS';
export const GET_APPLICATION_ERROR = 'GET_APPLICATION_ERROR';

export const UPDATE_APPLICATION = 'UPDATE_APPLICATION';

export const getApplication = (appId) => (dispatch) => {
  dispatch({ type: GET_APPLICATION });
  api
    .getApplication(appId)
    .then((response) => dispatch({ type: GET_APPLICATION_SUCCESS, data: response.data }))
    .catch((error) => dispatch({ type: GET_APPLICATION_ERROR, error }));
};

export const setFinalDetermination = (appId, determination, cb) => (dispatch) => {
  api
    .setFinalDetermination({ appId, determination })
    .then(() => {
      dispatch({
        type: UPDATE_APPLICATION,
        data: { statusId: determination },
      });
      dispatch(setSuccessFeedback('Final Determination saved'));
      if (cb) cb();
    })
    .catch(() => dispatch(setErrorFeedback('Error saving Final Determination')));
};

export const updateApplicationData = (data) => (dispatch) => {
  dispatch({ type: UPDATE_APPLICATION, data });
};
