import React, { useState } from 'react';
import PropTypes from 'prop-types';
import showdown from 'showdown';
import HTMLEllipsis from 'react-lines-ellipsis/lib/html';
import responsiveHOC from 'react-lines-ellipsis/lib/responsiveHOC';

const ResponsiveEllipsis = responsiveHOC()(HTMLEllipsis);
const converter = new showdown.Converter();

const DEFAULT_LINE_CLAMP = 2;

export default function PageDescClamp({ text }) {
  const [isClamped, setClamped] = useState(false);
  const [lines, setLines] = useState(DEFAULT_LINE_CLAMP);
  return (
    <div>
      <ResponsiveEllipsis
        unsafeHTML={converter.makeHtml(text)}
        maxLine={lines}
        basedOn="letters"
        onReflow={({ clamped }) => setClamped(clamped)}
      />
      {isClamped && (
        <button
          className="clamp-button"
          type="button"
          onClick={() => setLines(100)}
        >
          Read More
        </button>
      )}
      {!isClamped && lines !== DEFAULT_LINE_CLAMP && (
        <button
          className="clamp-button"
          type="button"
          onClick={() => setLines(DEFAULT_LINE_CLAMP)}
        >
          Read Less
        </button>
      )}
    </div>
  );
}

PageDescClamp.propTypes = {
  text: PropTypes.string.isRequired,
};
