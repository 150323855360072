import update from 'immutability-helper';
import { findIndex } from 'lodash';
import {
  GET_FLAGS,
  GET_FLAGS_SUCCESS,
  GET_FLAGS_ERROR,
  SET_FLAG,
  UNSET_FLAG,
} from '../actions/flags';

const initialState = {
  isLoading: true,
  data: [],
};

export default function flags(state = initialState, action) {
  switch (action.type) {
    case GET_FLAGS:
      return initialState;
    case GET_FLAGS_SUCCESS:
      return { isLoading: false, data: action.data };
    case GET_FLAGS_ERROR:
      return { isLoading: false, data: {} };
    case SET_FLAG:
      return update(state, { data: { $push: [action.data] } });
    case UNSET_FLAG: {
      const deleteIndex = findIndex(state.data, { id: action.flagId });
      return update(state, { data: { $splice: [[deleteIndex, 1]] } });
    }
    default:
      return state;
  }
}
