import React from 'react';
import { Route, Routes } from 'react-router-dom';

import useUser from 'hooks/useUser';

import UnauthorizedPage from 'components/layout/UnauthorizedPage';

import UploadListPage from './UploadListPage';
import UploadAddPage from './UploadAddPage';
import UploadInfoPage from './UploadInfoPage';

const UploadRoots = () => {
  const { appUser } = useUser();
  return (
    <Routes>
      <Route path="/" exact element={<UploadListPage />} />
      <Route
        path="new"
        element={appUser?.belongsTo ? <UploadAddPage /> : <UnauthorizedPage />}
      />
      <Route path=":uploadId" element={<UploadInfoPage />} />
    </Routes>
  );
};

export default UploadRoots;
