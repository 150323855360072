import React from 'react';

import api from 'api';
import useUser from 'hooks/useUser';
import useFeedback from 'hooks/useFeedback';
import useGoBack from 'hooks/useGoBack';
import useCurrentApplication from 'hooks/useCurrentApplication';
import {
  formTitle,
  formType,
  programSubType,
  programSubTypeLabel,
  getProgramTypeLabel,
} from 'helpers/forms';
import { formState } from 'utils/enums';

import { GoBackButton } from 'components/elements/BackButton';
import SectionWrapper from 'components/layout/SectionWrapper';
import SurveyPDFExport from 'components/forms/helpers/SurveyPDFExport';
import PRQSubmitConfirmation from 'components/forms/PRQ/PRQSubmitConfirmation';
import PRQSubmitReviewConfirmation from 'components/forms/PRQ/PRQSubmitReviewConfirmation';

import PartialResultForm from '../helpers/PartialResultForm';
import { getTableOverrider } from './utils';

const PRQForm = () => {
  const { appData, isReviewer, isVerification } = useCurrentApplication();
  const { isAdmin } = useUser();
  const { goBack } = useGoBack();

  const [submitModal, setSubmitModal] = React.useState(false);
  const { setError } = useFeedback();

  const title = React.useMemo(() => {
    const { bcqpBurnCenterName, programTypeId, programSubtypeId } = appData;
    const programLabel = programSubtypeId === programSubType.FOCUS_REVIEW
      ? programSubTypeLabel(programSubtypeId)
      : getProgramTypeLabel(programTypeId);
    return `${bcqpBurnCenterName} - ${programLabel} ${formTitle.prq}`;
  }, [appData]);

  const canEdit = React.useMemo(
    () => isVerification
      && [formState.REQUESTED, formState.STARTED].includes(appData.stateId),
    [isVerification, appData],
  );

  const onSubmit = () => {
    const submitFunc = isReviewer ? api.submitPRQReview : api.submitPRQ;
    submitFunc(appData.id)
      .then(() => {
        goBack();
      })
      .catch(() => {
        setError('Error submitting PRQ');
        setSubmitModal(null);
      });
  };

  return (
    <SectionWrapper title={title}>
      <div className="d-flex justify-content-between">
        <GoBackButton onClick={() => goBack()} />
        <SurveyPDFExport
          surveyVersionId={appData.prqVersionId}
          overrider={getTableOverrider(appData)}
        />
      </div>
      {submitModal
        && (isReviewer ? (
          <PRQSubmitReviewConfirmation
            onSubmit={onSubmit}
            onCancel={() => setSubmitModal(false)}
          />
        ) : (
          <PRQSubmitConfirmation
            onSubmit={onSubmit}
            onCancel={() => setSubmitModal(false)}
            programTypeId={appData.programTypeId}
          />
        ))}
      <PartialResultForm
        canEdit={canEdit}
        onSubmit={() => setSubmitModal(true)}
        surveyVersionId={appData.prqVersionId}
        formType={formType.PRQ}
        uploadFileFunc={api.uploadPRQFile}
        showReviwerComments={isAdmin || isReviewer}
        readOnlyReviwerComments={!!appData.prqSrSubmittedAt}
        submittedAt={appData.prqSubmittedAt}
      />
    </SectionWrapper>
  );
};

export default PRQForm;
