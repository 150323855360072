import api from 'api';

export const APP_USER_REQUEST = 'APP_USER_REQUEST';
export const APP_USER_REQUEST_SUCCESS = 'APP_USER_REQUEST_SUCCESS';
export const APP_USER_REQUEST_ERROR = 'APP_USER_REQUEST_ERROR';
export const APP_USER_BURNCENTER = 'APP_USER_BURNCENTER';

let APP_TOKEN = null;

export function setAppToken(token) {
  APP_TOKEN = token;
}

export function getAppToken() {
  return APP_TOKEN;
}

export function getBdUser(authUser) {
  return (dispatch) => {
    dispatch({ type: APP_USER_REQUEST });
    api
      .getUsers({ auth0Id: authUser.sub })
      .then(({ data }) => dispatch({
        type: APP_USER_REQUEST_SUCCESS,
        user: data?.items?.[0],
      }))
      .catch(() => dispatch({ type: APP_USER_REQUEST_ERROR, error: 'User not found!' }));
  };
}

export function getUserBurnCenter(burncenterId) {
  return async (dispatch) => {
    const { data } = await api.getBurncenter(burncenterId);
    dispatch({
      type: APP_USER_BURNCENTER,
      data,
    });
  };
}
