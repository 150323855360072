import React, {
  useRef, useEffect, useMemo, useCallback,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { Alert } from 'react-bootstrap';
import styled from '@emotion/styled';

import colors from 'resources/styles/colors';
import SVButton from 'components/elements/SVButton';
import { LoaderIcon } from 'components/elements/Loader';

import {
  getReviwerComments,
  addReviewerComment,
  updateReviewerComment,
} from 'store/actions/comments';

const CommentContainer = styled.div`
  border-bottom: 1px solid ${colors.gray};
  padding: 10px 5%;
  min-height: 110px;
`;

const CommentInput = styled.textarea`
  resize: vertical;
  border-radius: 5px;
  border: 1px solid ${colors.gray};
  width: 100%;
`;

const SiteReviewerComments = ({
  applicationId,
  surveyVersionId,
  criteriaId,
  readOnly,
}) => {
  const dispatch = useDispatch();
  const textInput = useRef(null);
  const { reviewerComments } = useSelector((state) => state.comments);

  const commentTxt = useMemo(
    () => get(reviewerComments?.data, 'items[0].message'),
    [reviewerComments],
  );

  const data = useMemo(() => reviewerComments?.data, [reviewerComments]);

  useEffect(() => {
    dispatch(
      getReviwerComments({
        applicationId,
        surveyVersionId,
        criteriaId,
      }),
    );
  }, [dispatch, applicationId, criteriaId, surveyVersionId]);

  const handleCommentAdd = useCallback(
    (message) => {
      dispatch(
        addReviewerComment({
          applicationId,
          surveyVersionId,
          criteriaId,
          message,
        }),
      );
    },
    [applicationId, criteriaId, dispatch, surveyVersionId],
  );

  const handleCommentUpdate = useCallback(
    (commentId, message) => {
      dispatch(
        updateReviewerComment({
          commentId,
          message,
        }),
      );
    },
    [dispatch],
  );

  const handleCommentClick = useCallback(() => {
    if (!data.items) return null;
    const message = textInput.current && textInput.current.value;
    return data.items[0]
      ? handleCommentUpdate(data.items[0].id, message)
      : handleCommentAdd(message);
  }, [data, handleCommentAdd, handleCommentUpdate]);

  const renderContent = useMemo(() => {
    if (reviewerComments?.isLoading) return <LoaderIcon />;
    if (reviewerComments?.error) return <Alert variant="danger">{reviewerComments.error}</Alert>;
    return (
      <>
        <h6>Comment for Final Site Report</h6>
        <CommentInput
          defaultValue={commentTxt}
          ref={textInput}
          disabled={readOnly}
        />
        {!readOnly && (
          <SVButton variant="secondary" size="sm" onClick={handleCommentClick}>
            {`${commentTxt ? 'Update' : 'Add'} comment`}
          </SVButton>
        )}
      </>
    );
  }, [reviewerComments, commentTxt, handleCommentClick, readOnly]);

  return <CommentContainer>{renderContent}</CommentContainer>;
};

SiteReviewerComments.propTypes = {
  applicationId: PropTypes.number.isRequired,
  surveyVersionId: PropTypes.number.isRequired,
  criteriaId: PropTypes.string.isRequired,
  readOnly: PropTypes.bool,
};

export default SiteReviewerComments;
