import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import intersection from 'lodash/intersection';
import isEmpty from 'lodash/isEmpty';

import useUpdateEffect from 'hooks/useUpdateEffect';
import { getBdUser, getUserBurnCenter } from 'store/auth/actions';
import { ADMIN_ROLES } from 'helpers/roles';
import { appRoles } from 'utils/enums';

const useUser = () => {
  const { user, logout } = useAuth0();
  const dispatch = useDispatch();
  const {
    isLoading, appUser, error, userBurnCenter,
  } = useSelector(
    ({ appAuth }) => appAuth,
  );

  const fetchAppUser = React.useCallback(
    () => dispatch(getBdUser(user)),
    [dispatch, user],
  );

  const userRoles = React.useMemo(
    () => user?.['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'],
    [user],
  );

  const hasReports = React.useMemo(() => !!appUser?.reportingRole, [appUser]);

  const hasVerification = React.useMemo(
    () => appUser?.canAccessVerification,
    [appUser],
  );

  const isBdataAmin = React.useMemo(
    () => userRoles?.includes(appRoles.bdataADMIN),
    [userRoles],
  );

  const isAdmin = React.useMemo(
    () => !isEmpty(intersection(ADMIN_ROLES, userRoles)),
    [userRoles],
  );

  useUpdateEffect(() => {
    if (userBurnCenter || !appUser?.belongsTo) return;
    dispatch(getUserBurnCenter(appUser?.belongsTo));
  }, [appUser?.belongsTo]);

  return {
    isLoading,
    error,
    fetchAppUser,
    appUser,
    logout,
    hasReports,
    hasVerification,
    isBdataAmin,
    userRoles,
    isAdmin,
    roleName: userRoles?.[0],
    userBurnCenter,
  };
};

export default useUser;
