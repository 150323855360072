import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { Alert, Stack } from 'react-bootstrap';

import api from 'api';
import { fetchSurveys } from 'store/surveys/actions';
import useFeedback from 'hooks/useFeedback';
import useTableState from 'hooks/useTableState';
import { getFormLabel } from 'helpers/forms';
import { formatDate } from 'utils/dateTime';
import { DEFAULT_PAGE_SIZE } from 'utils/constants';
import Labels from 'utils/labels';

import SectionWrapper from 'components/layout/SectionWrapper';
import Table from 'components/elements/Table';
import AdminVerificationMenu from 'components/templates/menus/AdminVerificationMenu';

import SurveyNameEditor from './SurveyNameEditor';

const SurveyTable = () => {
  const dispatch = useDispatch();
  const { setSuccess, setError } = useFeedback();
  const [editingId, setEditingId] = React.useState(null);
  const {
    data: listData,
    isLoading,
    error,
  } = useSelector((state) => state.surveys);
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const {
    page, handlePageChange, sortBy, sortOrder, handleSortChange,
  } = useTableState('name', 'asc', {});

  const handleDataRetreive = React.useCallback(
    () => dispatch(
      fetchSurveys({
        page,
        Limit: DEFAULT_PAGE_SIZE,
        Order: sortBy,
        OrderDirection: sortOrder,
      }),
    ),
    [dispatch, page, sortBy, sortOrder],
  );

  React.useEffect(() => {
    handleDataRetreive();
  }, [handleDataRetreive]);

  const nameEdit = React.useCallback(
    (survey) => (
      <SurveyNameEditor
        survey={survey}
        editingId={editingId}
        setEditingId={setEditingId}
        onUpdateSurveyName={({ surveyId, name }) => {
          if (!name.trim()) {
            setError('Survey name must not be blank!');
            return;
          }

          api
            .updateSurveyName({ surveyId, name })
            .then(() => {
              setSuccess('Survey name successfully updated');
              setEditingId(null);
              handleDataRetreive();
            })
            .catch(() => setError('Could not update survey name.'));
        }}
      />
    ),
    [editingId, setSuccess, setError, handleDataRetreive],
  );

  const columns = React.useMemo(
    () => [
      {
        id: 'name',
        label: 'Name',
        override: nameEdit,
      },
      {
        id: 'type',
        label: 'Type',
        override: ({ typeId }) => getFormLabel(typeId),
      },
      {
        id: 'version',
        label: 'Version',
        override: ({ lastVersion }) => lastVersion,
      },
      {
        id: 'updatedAt',
        label: 'Last Edit',
        override: ({ updatedAt }) => formatDate(updatedAt),
      },
    ],
    [nameEdit],
  );

  if (error) return <Alert variant="danger">Error loading surveys</Alert>;

  return (
    <SectionWrapper title={Labels.surveyManagment}>
      <Stack direction="horizontal" gap={3} className="align-items-start">
        <AdminVerificationMenu />
        <div className="d-flex flex-grow-1 flex-column">
          <Table
            columns={columns}
            items={listData?.items || []}
            handleOrderChange={handleSortChange}
            handlePageChange={handlePageChange}
            order={sortBy}
            orderDirection={sortOrder}
            pagination={{
              page,
              total: listData?.total ?? 0,
              limit: listData?.limit ?? 0,
            }}
            isLoading={isLoading}
            onClickRow={({ id }) => navigate(`${pathname}/${id}`)}
          />
        </div>
      </Stack>
    </SectionWrapper>
  );
};

export default SurveyTable;
