import React from 'react';
import { NavLink } from 'react-router-dom';

import useUser from 'hooks/useUser';
import { MainRoutes, ReportRoutes } from 'helpers/routes';

import {
  AdminInfo,
  MenuItem,
  MenuItemTitle,
  activeStyle,
} from 'components/templates/menus/styles';

const ReportManageMenu = () => {
  const { isBdataAmin } = useUser();
  return (
    <AdminInfo className="shadow p-4">
      {isBdataAmin && (
        <MenuItem>
          <MenuItemTitle>
            <NavLink
              to={`${MainRoutes.REPORTS}/${ReportRoutes.MANAGE}`}
              style={({ isActive }) => (isActive ? activeStyle : undefined)}
            >
              Report Management
            </NavLink>
          </MenuItemTitle>
        </MenuItem>
      )}
      <MenuItem>
        <MenuItemTitle>
          <NavLink to={`${MainRoutes.REPORTS}/${ReportRoutes.VIEW}`}>
            View Reports
          </NavLink>
        </MenuItemTitle>
      </MenuItem>
    </AdminInfo>
  );
};

export default ReportManageMenu;
