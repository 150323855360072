import React from 'react';
import { NavLink, useLocation, useParams } from 'react-router-dom';

import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';

import useImpressionateUser from 'hooks/useImpressionateUser';
import { MainRoutes, ReportRoutes } from 'helpers/routes';
import Labels from 'utils/labels';

import {
  AdminInfo,
  accordionHeaderStyle,
  accordionSelectedStyle,
} from './styles';

const ReportViewMenu = () => {
  const { pathname, search } = useLocation();
  const { reportId } = useParams();
  const { reports, hasWorkSpace } = useImpressionateUser();

  const isHome = React.useMemo(
    () => pathname === `${MainRoutes.REPORTS}/${ReportRoutes.VIEW}`,
    [pathname],
  );

  return (
    <div>
      <AdminInfo className="shadow">
        <Accordion defaultActiveKey={reportId ? '0' : null} flush>
          <Card style={{ border: 'none' }}>
            <Card.Header
              as={NavLink}
              to={{
                pathname: `${MainRoutes.REPORTS}/${ReportRoutes.VIEW}`,
                search,
              }}
              replace
              style={isHome ? accordionSelectedStyle : accordionHeaderStyle}
            >
              <div style={{ padding: '12px 20px' }}>Home</div>
            </Card.Header>
          </Card>
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              <span style={accordionHeaderStyle}>Dashboards</span>
            </Accordion.Header>
            <Accordion.Body
              as={ListGroup}
              style={{ padding: 0, borderRadius: 0, border: 'none' }}
            >
              {reports
                .filter((x) => x.group === 'Dashboards')
                ?.map(({ id, name }) => (
                  <ListGroup.Item
                    key={name}
                    as={NavLink}
                    replace
                    to={{
                      pathname: `${MainRoutes.REPORTS}/${ReportRoutes.VIEW}/${id}`,
                      search,
                    }}
                    style={({ isActive }) => ({
                      color: isActive ? 'white' : 'black',
                    })}
                  >
                    {name}
                  </ListGroup.Item>
                ))}
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </AdminInfo>
      {hasWorkSpace && (
        <AdminInfo className="shadow mt-4">
          <Card style={{ border: 'none' }}>
            <Card.Header
              as={NavLink}
              replace
              to={{
                pathname: `${MainRoutes.REPORTS}/${ReportRoutes.VIEW}/workspace`,
                search,
              }}
              style={accordionHeaderStyle}
              target="_blank"
            >
              <div style={{ padding: '12px 20px' }}>{Labels.workspace}</div>
            </Card.Header>
          </Card>
        </AdminInfo>
      )}
    </div>
  );
};

export default ReportViewMenu;
