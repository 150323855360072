import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Spinner } from 'react-bootstrap';

export const LoaderIcon = () => (
  <Spinner animation="border" variant="primary" />
);

const Loader = ({ isModal = false }) => (isModal ? (
  <Modal show centered contentClassName="bg-transparent border-0">
    <Modal.Body className="text-center">
      <LoaderIcon />
    </Modal.Body>
  </Modal>
) : (
  <div className="d-flex flex-grow-1 justify-content-center align-items-center">
    <LoaderIcon />
  </div>
));

Loader.propTypes = {
  isModal: PropTypes.bool,
};

export default Loader;
