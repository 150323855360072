import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Modal, Form } from 'react-bootstrap';

import useCurrentApplication from 'hooks/useCurrentApplication';
import { MainRoutes } from 'helpers/routes';
import SVButton from 'components/elements/SVButton';

const verificationString = 'delete';

const AppDeleteButton = () => {
  const [toDelete, setToDelete] = React.useState(false);
  const [confirmValue, setConfirmValue] = React.useState(null);
  const navigate = useNavigate();
  const { appDelete } = useCurrentApplication();

  React.useEffect(() => {
    if (toDelete) setConfirmValue(null);
  }, [toDelete]);

  return (
    <>
      <Modal
        show={toDelete}
        onHide={() => setToDelete(false)}
        size="lg"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Are you sure you want to delete this Verification Process?
            <br />
            This cannot be undone.
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group
            controlId="deleteType"
            type="text"
            onChange={({ target }) => setConfirmValue(target.value)}
          >
            <Form.Label>{`To confirm, please type "${verificationString}" in the field below.`}</Form.Label>
            <Form.Control placeholder="type delete here" />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <SVButton variant="cancel" onClick={() => setToDelete(false)}>
            No
          </SVButton>
          <SVButton
            disabled={confirmValue !== verificationString}
            onClick={() => {
              setToDelete(false);
              appDelete(() => navigate(MainRoutes.VERIFICATION));
            }}
          >
            Yes
          </SVButton>
        </Modal.Footer>
      </Modal>
      <div>
        <SVButton variant="danger" onClick={() => setToDelete(true)}>
          Delete
        </SVButton>
      </div>
    </>
  );
};

export default AppDeleteButton;
