import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';

import { formTitle } from 'helpers/forms';
import SVButton from 'components/elements/SVButton';

function ESSubmitConfirmation({ onSubmit, onCancel }) {
  const [satisfied, setSatisfied] = useState(false);

  return (
    <Modal show onHide={onCancel}>
      <Modal.Header closeButton>
        <Modal.Title>
          {`Are you sure you want to submit ‘${formTitle.meetingRounds}’ form?`}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          <input
            type="checkbox"
            style={{ marginRight: '1rem' }}
            onChange={({ target }) => setSatisfied(target.checked)}
          />
          I am satisfied with the answers given in this form and wish to submit
          for review.
        </p>
        <p>This cannot be undone.</p>
      </Modal.Body>
      <Modal.Footer>
        <SVButton variant="cancel" onClick={onCancel}>
          Cancel
        </SVButton>
        <SVButton
          size="sm"
          variant="secondary"
          onClick={onSubmit}
          disabled={!satisfied}
        >
          Submit
        </SVButton>
      </Modal.Footer>
    </Modal>
  );
}

export default ESSubmitConfirmation;

ESSubmitConfirmation.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};
