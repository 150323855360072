import React from 'react';

import useCurrentApplication from 'hooks/useCurrentApplication';
import DatePickerForm from 'components/elements/DatePickerForm';

import FinalDeterminationForm from './FinalDeterminationForm';

const FinalDeterminationPage = () => {
  const { appData, updateExpirationDate } = useCurrentApplication();

  return (
    <>
      <FinalDeterminationForm />
      <DatePickerForm
        label="Expiration Date"
        defaultValue={appData.expiredAt}
        onSave={updateExpirationDate}
      />
    </>
  );
};

export default FinalDeterminationPage;
