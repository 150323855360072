import styled from '@emotion/styled';

export const FlexDiv = styled.div`
  display: flex;
`;

export const FlexDivSpaceBetween = styled(FlexDiv)`
  justify-content: space-between;
`;

export const FlexJustifyCenter = styled(FlexDiv)`
  justify-content: center;
`;

export const FlexJustifyRight = styled(FlexDiv)`
  justify-content: right;
`;

export const FlexAlignCenter = styled(FlexDiv)`
  align-items: center;
`;

export const FlexCenter = styled(FlexJustifyCenter)`
  align-items: center;
`;

export const FlexDivSpaceBetweenCenter = styled(FlexDivSpaceBetween)`
  align-items: center;
`;

export const FlexWrap = styled(FlexDiv)`
  flex-wrap: wrap;
`;

export const FlexColumn = styled(FlexDiv)`
  flex-direction: column;
`;

export const FlexColumnCenterAlign = styled(FlexColumn)`
  align-items: center;
`;

export const FlexColumnCenter = styled(FlexColumn)`
  align-items: center;
  justify-content: center;
`;
