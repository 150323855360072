import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { find } from 'lodash';

import { Button, Stack } from 'react-bootstrap';
import styled from '@emotion/styled';

import colors from 'resources/styles/colors';

import FontAwesomeIcon from 'components/elements/FontAwesomeIcon';

import { getQuestionComments } from 'store/actions/comments';
import { deleteFlag, setFlag } from 'store/actions/flags';

const StyledButton = styled(Button)`
  line-height: 0.5rem;
  font-size: 1.3rem;
  border-radius: 20%;
  padding: 0.1rem;
  color: ${colors.green};
  border: 3px solid
    ${({ _active }) => (_active === 'true' ? colors.green : 'transparent')};
  background: transparent;
`;

const SurveyQuestionButtons = ({
  questionId,
  title,
  surveyVersionId,
  userTypeId,
}) => {
  const dispatch = useDispatch();
  const { flags, currentApplication } = useSelector(
    (state) => state.applications,
  );
  const { currentComments, commentCount } = useSelector(
    (state) => state.comments,
  );

  const applicationId = React.useMemo(
    () => currentApplication?.data?.id,
    [currentApplication],
  );

  const hasComments = React.useMemo(
    () => find(commentCount.data, { questionId }),
    [commentCount.data, questionId],
  );

  const hasFlag = React.useMemo(
    () => find(flags.data, { questionId }),
    [flags.data, questionId],
  );

  const handleCommentClick = () => {
    dispatch(
      getQuestionComments({
        applicationId,
        surveyVersionId,
        userTypeId,
        questionId,
        title,
      }),
    );
  };

  const handleFlagClick = () => (hasFlag
    ? dispatch(deleteFlag(hasFlag.id))
    : dispatch(
      setFlag({
        applicationId,
        surveyVersionId,
        userTypeId,
        questionId,
      }),
    ));

  return (
    <Stack direction="horizontal" gap={2}>
      <StyledButton
        variant="outline-secondary"
        onClick={handleCommentClick}
        _active={questionId === currentComments.question.id ? 'true' : 'false'}
      >
        <FontAwesomeIcon
          className={hasComments ? 'fa-comment' : 'fa-comment-o'}
        />
      </StyledButton>
      <StyledButton variant="outline-secondary" onClick={handleFlagClick}>
        <FontAwesomeIcon className={hasFlag ? 'fa-flag' : 'fa-flag-o'} />
      </StyledButton>
    </Stack>
  );
};

SurveyQuestionButtons.propTypes = {
  surveyVersionId: PropTypes.number.isRequired,
  userTypeId: PropTypes.number.isRequired,
  questionId: PropTypes.string.isRequired,
  title: PropTypes.string,
};

export default SurveyQuestionButtons;
