import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { Alert } from 'react-bootstrap';

import { AppAdminRoutes } from 'helpers/routes';
import useCurrentApplication from 'hooks/useCurrentApplication';
import Loader from 'components/elements/Loader';

import Container from './components/Container';
import FormsPage from './forms';
import UserManagement from './users';
import SiteVisitDateForm from './site-visit';
import FileManagement from './documents';
import FinalDeterminationPage from './final/FinalDeterminationPage';

const Application = () => {
  const { appGet, appData, isLoading } = useCurrentApplication();

  React.useEffect(() => {
    appGet();
  }, [appGet]);

  if (isLoading) return <Loader />;

  if (!appData?.id) return <Alert variant="danger">Burncenter verification not found</Alert>;

  return (
    <Routes>
      <Route index element={<Navigate replace to={AppAdminRoutes.FORMS} />} />
      <Route path={`${AppAdminRoutes.FORMS}/*`} element={<FormsPage />} />
      <Route
        path={AppAdminRoutes.USERS}
        element={(
          <Container>
            <UserManagement />
          </Container>
        )}
      />
      <Route
        path={AppAdminRoutes.SITE_VISIT}
        element={(
          <Container>
            <SiteVisitDateForm />
          </Container>
        )}
      />
      <Route
        path={AppAdminRoutes.DOCS}
        element={(
          <Container>
            <FileManagement />
          </Container>
        )}
      />
      <Route
        path={AppAdminRoutes.DETERMINATION}
        element={(
          <Container>
            <FinalDeterminationPage />
          </Container>
        )}
      />
    </Routes>
  );
};

export default Application;
