import React from 'react';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import styled from '@emotion/styled';
import { Stack, Form } from 'react-bootstrap';

import SVButton from 'components/elements/SVButton';
import { FlexJustifyRight } from 'resources/styles/display';

const FormLabel = styled(Form.Label)`
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 0;
`;

const formTextStyle = `
  border: 0;
  border-bottom: 1px solid #ced4da;
  border-radius: 0;
`;

const FormText = styled(Form.Control)`
  ${({ as }) => (as ? '' : formTextStyle)}
`;

const requiredTag = (required) => (required ? (
  <span style={{ marginLeft: '5px', fontSize: '1.5rem', color: 'red' }}>
    *
  </span>
) : null);

const FormInput = ({
  name,
  label,
  required,
  form: {
    register,
    formState: { errors },
  },
  ...inputProps
}) => (
  <Form.Group className="mb-3" controlId={name}>
    {label && (
      <FormLabel>
        {label}
        {requiredTag(required)}
      </FormLabel>
    )}
    <FormText
      required={required}
      {...register(name)}
      {...inputProps}
      isInvalid={!!errors[name]}
    />
    <Form.Control.Feedback type="invalid">
      {errors[name]?.message}
    </Form.Control.Feedback>
  </Form.Group>
);

FormInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  form: PropTypes.shape({
    register: PropTypes.func,
    formState: PropTypes.shape({
      errors: PropTypes.shape({}),
    }),
  }).isRequired,
  required: PropTypes.bool,
};

const schema = yup
  .object({
    name: yup.string().required(),
    embedPath: yup.string().url('Invalid URL').required(),
  })
  .required();

const ReportForm = ({ defaultValues = {}, onSubmit }) => {
  const { handleSubmit, ...formProps } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  });

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <FlexJustifyRight>
        <Stack direction="horizontal" gap={2}>
          <SVButton variant="cancel" as={Link} to="..">
            Cancel
          </SVButton>
          <SVButton variant="secondary" type="submit">
            Save
          </SVButton>
        </Stack>
      </FlexJustifyRight>
      <FormInput label="Report Name" name="name" form={formProps} required />
      <FormInput
        label="Embed Path"
        name="embedPath"
        form={formProps}
        required
      />
      <FormInput
        label="Data Inclusion Note"
        name="dataInclusionNote"
        form={formProps}
      />
      <FormInput
        label="Description"
        name="description"
        form={formProps}
        rows={4}
        as="textarea"
      />
    </Form>
  );
};

ReportForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  defaultValues: PropTypes.shape({}),
};

export default ReportForm;
