import styled from '@emotion/styled';

import colors from 'resources/styles/colors';

export const SurveyPanel = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const SurveyContainer = styled.div`
  flex-grow: 1;
  .sv-question__header {
    position: relative;
    h5.sv-title {
      max-width: ${({ _hasbuttons }) => (_hasbuttons ? 'calc(100% - 90px)' : '100%')};
      display: block;
      white-space: pre-wrap;
    }
    .question-buttons {
      position: absolute;
      width: 80px;
      bottom: 0;
      right:0;
    }
  }
  .sv-file__clean-btn {
    display: none;
  }
  .sv-page__description {
    color: ${colors.orange} !important;
    line-height: 22px;
    font-size: 22px;
    padding: 30px 0 32px 10px;
  }
  .sv-container-modern__title {
    padding-top: 40px;
  }
  .sv-description {
    white-space: pre-wrap;
    p {
      margin-bottom: 5px;
    }
    button.clamp-button {
      background: transparent;
      border: none;
      color: ${colors.green};
      font-size: 16px;
      padding: 0;
      text-decoration: underline;
    }
  }
  .sv-file__preview {
    display: none;
  }

  .sv-root-modern .sv-container-modern {
    color: black !important;
  }

  .sv-question--disabled {
    .sv-title-actions__title,
    .sv-string-viewer  {
      color: ${colors.darkGray};
    }
  }
`;

export const SubmitInfo = styled.div`
  margin: 1rem 5% 0 5%;
  font-weight: 600;
  font-size: 1.1rem;
  color: ${colors.darkGray};
`;
